(function () {

    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = cropDone;

    function cropDone() {
        let vm = this;

        console.log("crop done ");
        let fabricCanvas = vm.currentCanvas;

        let el, object;
        fabricCanvas.forEachObject(function(obj){
            if (obj.id == "123") {
                el = obj;
                            }
            else if (obj.id == "456") {
                object = obj;
                         }
        });
        console.log('left el '+el.left);
        console.log('top el '+el.top);
        let left = el.left - object.left;
        let top = el.top - object.top;
        console.log('left object '+object.left);
        console.log('top object '+object.top);

        let cropped = new Image();
        cropped.src = fabricCanvas.toDataURL({
            left: left,
            top: top,
            width: el.width,
            height: el.height
        });
        cropped.scaleX = cropped.scaleY = 0.25;
        cropped.onload = function() {
//                fabricCanvas.clear();
            let image = new fabric.Image(cropped);
            image.left = el.left;
            image.top = el.top;
            image.setCoords();
            fabricCanvas.add(image);
            fabricCanvas.renderAll();
        };
        el.disabled = true;
        el.visible = false;
        fabricCanvas.off("mouse:move");
        fabricCanvas.off("mouse:down");
        fabricCanvas.off("mouse:up");
        fabricCanvas.remove(el);
        fabricCanvas.remove(object);
        fabricCanvas.renderAll();
    }


})();