(function(){

    "use strict";

    const fabric = require("fabric-browseronly").fabric;

    module.exports = applyImage;


    function applyImage() {
        let vm = this;
        let resetCanvas = _.bind(require('./reset-canvas'), vm);
        resetCanvas();

        let canvas = vm.currentCanvas;
        let canvasHeight = vm.currentCanvas.height;
        let canvasWidth = vm.currentCanvas.width;
        let canvasImage;
        let originalImage;

        let originalImageWidth;
        let originalImageHeight;

        let isDrawing = false;
        let isCropping = false;
        let newRect;
        let drawingSquareStartX;
        let drawingSquareStartY;
        let cropImageBuffer;

        let addBtn;
        let cancelBtn;
        let curtain;


        function getRatioWidth (width) {
            return width / canvasWidth;
        }

        function getRatioHeight (height) {
            return height / canvasHeight;
        }

        function updateRect(rect, options) {

            let left = options.left;
            let top = options.top;
            let width = options.width;
            let height = options.height;

            rect.set({
                left: left,
                top: top,
                width: width,
                height: height
            });

            canvas.setActiveObject(rect);
            updateScopeCrop(rect, {
                left: left,
                top: top,
                width: width,
                height: height
            });
        }

        function getDataUrl (options) {
            let dataUrl = originalImage.toDataURL({
                format: 'jpg',
                quality: 1,
                left: options.left,
                top: options.top,
                width: options.width,
                height: options.height,
                multiplier: 1
            });
            return dataUrl;
        }

        function updateScopeCrop(rect, options) {

            if (options.left !== undefined) {
                cropImageBuffer.left = getRatioWidth(originalImageWidth) * options.left;
                // crop.left = options.left;
            }
            if (options.top !== undefined) {
                cropImageBuffer.top = getRatioHeight(originalImageHeight) * options.top;
                // crop.top = options.top;
            }
            if (options.width !== undefined) {
                cropImageBuffer.width = getRatioWidth(options.width) * originalImageWidth;
                // crop.width = options.width;
            }
            if (options.height !== undefined) {
                cropImageBuffer.height = getRatioHeight(options.height) * originalImageHeight;
                // crop.height = options.height;
            }
            cropImageBuffer.imageDataUrl = getDataUrl({
                left: cropImageBuffer.left,
                top: cropImageBuffer.top,
                width: cropImageBuffer.width,
                height: cropImageBuffer.height
            });
            canvasImage.set({
                left: vm.currentCanvas.getCenter().left,
                top: vm.currentCanvas.getCenter().top,
                width: canvasWidth,
                height: canvasHeight,
                clipTo: function(ctx) {
                    ctx.rect(-canvasWidth/2 + options.left, -canvasHeight/2 + options.top, options.width, options.height);
                }
            });
        }

        function addScopeCrop(options) {
            let left = getRatioWidth(originalImageWidth) * options.left;
            let top = getRatioHeight(originalImageHeight) * options.top;
            let width = getRatioWidth(options.width) * originalImageWidth;
            let height = getRatioHeight(options.height) * originalImageHeight;
            let imageDataUrl = getDataUrl({
                left: left,
                top: top,
                width: width,
                height: height
            });
            canvasImage.set({
                left: vm.currentCanvas.getCenter().left,
                top: vm.currentCanvas.getCenter().top,
                width: canvasWidth,
                height: canvasHeight,
                lockRotation: true,
                hasRotatingPoint: false,
                name: 'canvas-image',
                clipTo: function(ctx) {
                    ctx.rect(-canvasWidth/2 + options.left, -canvasHeight/2 + options.top, options.width, options.height);
                }
            });
            canvas.add(canvasImage);
            canvas.bringToFront(canvasImage);

            // store cropped area into buffer
            cropImageBuffer = {
                id: options.id,
                left: left,
                top: top,
                width: width,
                height: height,
                imageDataUrl: imageDataUrl
            };
        }

        function addRect(options, oldId) {
            let id = oldId || Math.random().toString(36).substring(7);

            let left = options.left;
            let top = options.top;
            let width = options.width;
            let height = options.height;

            let rect = new fabric.Rect({
                left: left,
                top: top,
                width: width,
                height: height,
                lockRotation: true,
                hasRotatingPoint: false,
                name: 'crop-area'
            });
            rect.id = id;
            if (isDrawing) {
                canvas.add(rect).setActiveObject(rect);
                canvas.bringToFront(rect);
                addScopeCrop({
                    id: id,
                    left: left,
                    top: top,
                    width: width,
                    height: height
                });
            } else {
                addControlBtn({
                    left: left,
                    top: top,
                    width: width,
                    height: height
                });

            }

            return rect;
        }

        function addControlBtn(rect){
            isCropping = true;
            canvas.setActiveObject(canvasImage);
            let btnGroupLeft = rect.left+rect.width-60;
            let btnGroupTop = rect.top+rect.height;
            if (canvasHeight - rect.top - rect.height < 30) {
                if (rect.top < 30) {
                    btnGroupTop = rect.top+rect.height-30;
                } else {
                    btnGroupTop = rect.top - 30;
                }
            }
            let addBtnContainer = new fabric.Circle({
                left: 0,
                top: 0,
                radius: 15,
                height: 30,
                width: 30,
                fill: 'rgb(255, 92, 123)',
                originX: 'center',
                originY: 'center'
            });
            let addBtnText = new fabric.Text('\u2713\uFE0E', {
                fontSize: 20,
                fill: '#f7f7f7',
                left: 0,
                top: 0,
                originX: 'center',
                originY: 'center'
            });
            addBtn = new fabric.Group([addBtnContainer, addBtnText], {
                left: btnGroupLeft-3,
                top: btnGroupTop+3,
                hasRotatingPoint: false,
                hasBorders: false,
                hasControls: false,
                selectable: false,
                hoverCursor: 'pointer'
            });
            let cancelBtnContainer = new fabric.Circle({
                left: 0,
                top: 0,
                radius: 15,
                height: 30,
                width: 30,
                fill: 'rgb(38,50,56)',
                originX: 'center',
                originY: 'center'
            });
            let cancelBtnText = new fabric.Text('\u002B', {
                fontSize: 30,
                fontWeight: 800,
                left: 0,
                top: 0,
                fill: '#f7f7f7',
                originX: 'center',
                originY: 'center',
                angle: 45
            });
            cancelBtn = new fabric.Group([cancelBtnContainer, cancelBtnText], {
                left: btnGroupLeft+25,
                top: btnGroupTop,
                hasRotatingPoint: false,
                hasBorders: false,
                hasControls: false,
                selectable: false,
                hoverCursor: 'pointer'
            });
            canvas.add(addBtn).add(cancelBtn);
            canvas.setActiveObject(addBtn).setActiveObject(cancelBtn);
            addBtn.on('mousedown', function(evt){
                addCropImage();
            });
            cancelBtn.on('mousedown', function(evt){
                cancelCropImage();
            });
        }

        function addCropImage() {


            canvas.remove(newRect);
            canvas.remove(curtain);
            canvas.remove(canvasImage);
            canvas.remove(addBtn);
            canvas.remove(cancelBtn);
            isCropping = false;
            newRect.remove();
            newRect = undefined;
            canvas.clear();

            fabric.Image.fromURL(cropImageBuffer.imageDataUrl, function (img) {
                img.set({
                    hasControls: false,
                    selectable: false,
                    hasBorders: false,
                    lockMovementX: true,
                    lockMovementY: true
                });
                canvas.add(img);
                // // canvas.centerObject(img);
                canvas.setDimensions({width: img.width, height: img.height});
                canvas.renderAll();
            });

            vm.currentCanvas.off('mouse:down', mouseDownHandler);
            vm.currentCanvas.off('mouse:move', mouseMoveHandler);
            vm.currentCanvas.off('mouse:up', mouseUpHandler);

        }

        function cancelCropImage() {
            canvas.remove(newRect);
            canvas.remove(curtain);
            canvas.remove(canvasImage);
            canvas.remove(addBtn);
            canvas.remove(cancelBtn);
            isCropping = false;
            newRect = undefined;
            canvasImage = fabric.util.object.clone(originalImage);
            canvasImage.setWidth(canvasWidth);
            canvasImage.setHeight(canvasHeight);

            vm.currentCanvas.off('mouse:down', mouseDownHandler);
            vm.currentCanvas.off('mouse:move', mouseMoveHandler);
            vm.currentCanvas.off('mouse:up', mouseUpHandler);

        }

        function rectScaleHandler(rect) {
            let left = Math.round(rect.left);
            let top = Math.round(rect.top);
            let width = Math.round(rect.width);
            let height = Math.round(rect.height);
            updateRect(rect, {
                left: left,
                top: top,
                width: width,
                height: height
            });
        }

        function rectMoveHandler(rect) {
            let left = Math.round(rect.left);
            let top = Math.round(rect.top);
            updateRect(rect, {
                left: left,
                top: top,
                width: rect.width,
                height: rect.height
            });
        }

        function mouseDownHandler(evt) {
            if ((canvas.getActiveObject()!==undefined && canvas.getActiveObject( )!==null) || isCropping) {
                return;
            }
            isDrawing = true;
            let pointer = canvas.getPointer(evt.e);
            drawingSquareStartX = pointer.x;
            drawingSquareStartY = pointer.y;
            curtain = new fabric.Rect({
                left: 0,
                top: 0,
                width: canvasWidth,
                height: canvasHeight,
                hasControls: false,
                hasBorders: false,
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
                hasRotatingPoint: false,
                fill: 'rgba(100,100,100,0.7)'
            });
            canvas.add(curtain);
        }

        function mouseMoveHandler(evt) {
            if (isDrawing && !isCropping) {
                let pointer = canvas.getPointer(evt.e);
                let width = Math.abs(drawingSquareStartX - pointer.x);
                let height = Math.abs(drawingSquareStartY - pointer.y);

                // canvas.setOverlayColor('rgba(50,50,50,0.7)');
                if (!newRect) {
                    if (width > 1 && height > 1) {
                        newRect = addRect({
                            left: Math.min(drawingSquareStartX, pointer.x),
                            top: Math.min(drawingSquareStartY, pointer.y),
                            width: width,
                            height: height,
                            fill: 'rgba(255,255,255,0)'
                        });
                    }
                } else {
                    updateRect(newRect, {
                        left: Math.min(drawingSquareStartX, pointer.x),
                        top: Math.min(drawingSquareStartY, pointer.y),
                        width: width,
                        height: height
                    });
                }
            }
        }



        function mouseUpHandler(evt) {
            if (isDrawing && !isCropping) {
                isDrawing = false;
                if (newRect) {
                    if (canvasWidth < newRect.left + newRect.width)
                        newRect.width = canvasWidth - newRect.left;
                    if (canvasHeight < newRect.top + newRect.height)
                        newRect.height = canvasHeight - newRect.top;
                    let left = Math.round(newRect.left);
                    let top = Math.round(newRect.top);
                    let width = Math.round(newRect.width);
                    let height = Math.round(newRect.height);

                    if (width > 1 && height > 1) {
                        addRect({
                            left: left,
                            top: top,
                            width: width,
                            height: height,
                            fill: 'rgba(255,255,255,0)'
                        }, newRect.id);
                    }
                }
            }
        }

            // Scale and set the image to be center of canvas
            originalImage = fabric.util.object.clone(vm.currentCanvas.item(0));
       /*  if(vm.help){

         }*/
            originalImageHeight = Math.round(vm.currentCanvas.item(0).getHeight());
            originalImageWidth = Math.round(vm.currentCanvas.item(0).getWidth());
            canvasImage = fabric.util.object.clone(vm.currentCanvas.item(0));



        vm.currentCanvas.on('mouse:down', mouseDownHandler);
        vm.currentCanvas.on('mouse:move', mouseMoveHandler);
        vm.currentCanvas.on('mouse:up', mouseUpHandler);
        // vm.currentCanvas.on('object:moving', rectMoveHandler);

    }

})();
