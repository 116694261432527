(function () {

    'use strict';

    class HurExamCtrl {
        constructor($scope, moment, system, $mdDialog, $translate, hurService) {
            this.moment = moment;
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.hurService = hurService;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
        }

        static get $inject() {
            return ["$scope", "moment", "system", "$mdDialog", "$translate", "hurService"];
        }


        $onInit() {
            this.patient_id = this.patient || null;
            this.promise = this.hurService.getTrainingPrograms(this.patient_id).then((data)=>{
               if(data) this.trainingPrograms=data;
            })
            this.selectedVisit=-1;
            this.query = {
                limit: 5,
                page: 1,
                order: "-duration"
            };
            this.options = [1, 2, 4, 10];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.exams = [];
            this.visits = [];
            this.currentExam = null;
            this.examFilter = {
                program: null,
                from: this.moment().format(this.dateFormat),
                to: this.moment().format(this.dateFormat)
            }
            this.trainingPrograms = [];
            this.visits =[]
            this.classified_activities =[];
        }

        previous() {

        }

        importExam() {

        }

        saveExam() {

        }

        deleteExam() {

        }

        loadExams() {
            this.promise=this.hurService.get_training_visits(this.patient_id,this.examFilter).then((data)=>{
                if(data){
                    this.visits=data;
                    this.classified_activities = _.chain(this.visits)
                        .map('activities')
                        .flatten()
                        .groupBy('activity')
                        .map((e) => {
                            return {
                                'activity_name': _.chain(e).first().get('activity_name').value(),
                                'min': _.chain(e).first().get('results.sets.0.load').value(),
                                'max': _.chain(e).last().get('results.sets.0.load').value(),
                                'unit': _.chain(e).first().get('settings.units').value()
                            }
                        }).value();
                }
            });
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            if (this.visits.length < ((this.query.page - 1) * this.query.limit)) {
                this.query.page = 1;
            }
        }

        getDuration(start, end) {
            // need iso string or datetime
            if (_.isString(start) && _.isString(end)) {
                return this.moment(this.moment(end).diff(this.moment(start))).format("HH:mm:ss");
            }
        }

        getLoginDate(date_str) {
            // need iso string or datetime
            if (_.isString(date_str)) {
                return this.moment(date_str).format(this.dateFormat);
            }
        }

        getActivityAttr(activity, _atr) {
            let value = _.get(activity, 'results.sets.0.' + _atr);
            let unit = _.get(activity, 'settings.units');
            if (unit === 'kg' && value) return `${value / 1000}`
            else return ''

        }

        getClassifiedActivities(activity_type) {
            switch (activity_type) {
                case 'chest_activities' :
                    return _.filter(this.classified_activities, (e) => {
                        return _.chain(e.activity_name).toLower().startsWith('chest').value() || _.includes(["optimal rhomb", "push up"], _.toLower(e.activity_name))
                    });
                    break;
                case 'abdomen_activities' :
                    return _.filter(this.classified_activities, (e) => {
                        return _.chain(e.activity_name).toLower().startsWith('twist').value() || _.includes(["abdomen", "back"], _.toLower(e.activity_name))
                    });
                    break;
                case 'leg_activities' :
                    return _.filter(this.classified_activities, (e) => {
                        return _.chain(e.activity_name).toLower().startsWith('leg').value() || _.includes(["abduction"], _.toLower(e.activity_name))
                    });
                    break;
            }
        }

    }

    module.exports = {
        bindings: {
            patient: "<mnPatient",
        },
        template: require("specifics/views/hur-exam.html"),
        controllerAs: "vm",
        controller: HurExamCtrl,
    };

})();