/**
 * Created by Hp on 25/09/2017.
 */
/**
 * Created by BETALOS on 14/06/2016.
 */
(function () {

    'use strict';

    module.exports = conventionService;

    conventionService.$inject = ["$q", "$http", "mnWebSocket"];

    function conventionService($q, $http, mnWebSocket) {
        var self = this;

        self.saveConvention = saveConvention;
        self.getConventions = getConventions;

        function saveConvention(convention) {
            var deferred = $q.defer();
            var url = "/api/convention/";
            if(!_.isNil(convention.id)) url+= convention.id + "/"  ;

            $http.post(url, convention).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function getConventions(query) {
            return mnWebSocket.call("shared.insurance.Convention.search_convention", query);
        }
    }

})();
