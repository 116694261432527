/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    module.exports = {
        mnAutocomplete: require('./components/autocomplete'),
        mnDictionary: require('./components/dictionary'),
        mnFreeDictionary: require('./components/free-dictionary'),
        mnChoiceList: require('./components/choice-list'),
        mnFileBlock: require('./components/file-block'),
        mnHtmlBlock: require('./components/html-block/html-block'),
        mnFormHtmlBlock: require('./components/form-html-block/form-html-block'),

        mnCustomFieldsTable: require('./components/custom-fields-table/custom-fields-table'),
        mnCustomFields: require('./components/custom-fields-block/custom-fields-block'),

        surveyResume: require('./components/survey-resume/survey-resume'),
        surveyQuestion: require('./components/survey-question/survey-question'),

        mnDictTree: require('./components/dictionary-tree'),
        mnTree: require('./components/dictionary-tree-view'),

        blockService: require('./services/blockService'),

        dictionaryService: require('./services/dictionaryService'),
        autoCompleteService: require('./services/autoCompleteService'),
    }

})();
