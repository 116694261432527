(function () {
    'use strict'

    const fabric = require("fabric-browseronly").fabric;

    module.exports = drawCircle;

    function drawCircle(circleColor) {
        let vm = this;



        vm.resetCanvas();

        vm.currentCanvas.defaultCursor = 'crosshair';
        vm.currentCanvas.hoverCursor = 'crosshair';

        let origX, origY;

        vm.currentCanvas.on('mouse:down', function (o) {
            let pointer = vm.currentCanvas.getPointer(o.e);

            origX = pointer.x;
            origY = pointer.y;

            let c = new fabric.Circle({
                left: pointer.x,
                top: pointer.y,
                fill: undefined,
                radius: 1,
                strokeWidth: 2,
                stroke: circleColor.circleColor,
                hasControls: false,
                hasBorders: false,
                originX: 'center',
                originY: 'center',
            });

            vm.currentCanvas.add(c);

            vm.currentCanvas.off('mouse:down');

            vm.currentCanvas.on('mouse:move', function (o) {

                let pointer = vm.currentCanvas.getPointer(o.e);
                let radius = Math.sqrt(Math.pow(origX - pointer.x, 2) + Math.pow(origY - pointer.y, 2));
                c.set({radius: radius});
                c.setCoords();
                vm.currentCanvas.renderAll();

                vm.currentCanvas.on('mouse:down', function (o) {
                    vm.active = 'none';
                    vm.currentCanvas.off('mouse:move');
                    vm.resetCanvas();
                });
            });
        });

    }

})();