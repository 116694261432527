/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const {Observable} = require('rxjs');
    const {DELETE, UPDATE, WAITING_LIST, BACKGROUND, APPOINTMENT, EVENT_SOURCE_ID} = require('../utils/consts');

    class CalendarService {
        constructor($q, mnWebSocket, moment) {
            this.$q = $q;
            this.moment = moment;
            this.ws = mnWebSocket;
        }

        static get $inject() {
            return ['$q', 'mnWebSocket', 'moment'];
        }

        getEvents(query, calendar, closingDays) {
            const deferred = this.$q.defer();
            let events = _.castArray(closingDays);

            this.ws.call('frontdesk.Calendar.events', query)
                .then(data => {
                    const isMonthView = _.get(calendar, 'view.type') === "dayGridMonth";

                    if (!isMonthView) closingDays.display = BACKGROUND;
                    events = _.concat(events, data[0], this.handleBackEvents(data[1]));

                    if (isMonthView) deferred.resolve(this.handleMonthView(events));
                    else deferred.resolve(events);
                }, deferred.reject);

            return deferred.promise;
        }

        // waiting list retrieve
        getWaitingList(filter) {
            const deferred = this.$q.defer();

            this.ws.call('frontdesk.Calendar.waiting_list', _.isUndefined(filter) ? {} : filter)
                .then(events => {
                    events = _.map(events, event => this.handleWaitingItem(event));
                    deferred.resolve(events);
                }, deferred.reject);

            return deferred.promise;
        }

        // events changes related
        eventsChanges(calendarComponent) {
            const namespace = calendarComponent.element.attr('namespace') || "calendar";
            return new Observable(observer => {
                this.ws.sub("frontdesk.Calendar.notify", namespace, data => {
                    if (!calendarComponent.calendar) return;

                    switch (data.cmd) {
                        case UPDATE:
                            return this.handleEventUpdate(calendarComponent, observer, data);
                        case DELETE:
                            return this.handleEventDelete(calendarComponent, data);
                        case WAITING_LIST:
                            return this.handleWaitingList(calendarComponent, observer, data);
                    }
                });

                return {
                    unsubscribe: () => {
                        this.ws.unsub('frontdesk.Calendar.notify', namespace);
                        observer.complete();
                    }
                }
            });
        }

        handleEventUpdate(calendarComponent, observer, data) {
            this.handleEventDelete(calendarComponent, data);

            this.retrieveEvent(calendarComponent, data)
                .then(data => {
                    if (!data) return;

                    _.remove(calendarComponent.waitingListItems, {id: data.id});
                    calendarComponent.handleWaitingList(calendarComponent.waitingListItems);

                    calendarComponent.calendar.addEvent(calendarComponent.toggleEventIdValue(data), EVENT_SOURCE_ID);
                });
        }

        handleEventDelete(calendarComponent, data) {
            const id = `${data.type === APPOINTMENT ? data.event_type : BACKGROUND}-${data.event}`;
            const event = calendarComponent.calendar.getEventById(id);

            if (event) event.remove();

            if (data.type === APPOINTMENT) {
                _.remove(calendarComponent.waitingListItems, {track_id: id});
                calendarComponent.handleWaitingList(calendarComponent.waitingListItems);
            }
        }

        handleWaitingList(calendarComponent, observer, data) {
            this.handleEventDelete(calendarComponent, data)

            this.retrieveEvent(calendarComponent, data)
                .then(data => {
                    if (!data) return;

                    const item = this.handleWaitingItem(data);
                    observer.next(item);
                });
        }

        // internal use
        retrieveEvent(calendarComponent, data) {
            const query = {
                id: parseInt(data.event),
                event_type: data.event_type,
                query: {
                    filter: calendarComponent.filter,
                    start: this.moment(calendarComponent.calendar.view.currentStart).format('YYYY-MM-DD'),
                    end: this.moment(calendarComponent.calendar.view.currentEnd).add(-1, 'days').format('YYYY-MM-DD')
                }
            };

            if (data.type === BACKGROUND) return this.backgroundPromise(calendarComponent, query);
            else if (data.type === APPOINTMENT && data.cmd === WAITING_LIST) return this.waitingListPromise(query)
            else return this.appointmentPromise(query);
        }

        appointmentPromise(query) {
            return this.ws.call('frontdesk.Calendar.get_appointment', query);
        }

        waitingListPromise(query) {
            return this.ws.call('frontdesk.Calendar.get_waiting_list', {
                id: query.id,
                event_type: query.event_type,
                query: query.query.filter
            });
        }

        backgroundPromise(calendarComponent, query) {
            const deferred = this.$q.defer();

            this.ws.call('frontdesk.Calendar.get_background', query)
                .then(data => {
                    if (data) {
                        data.id = `${BACKGROUND}-${data.id}`;

                        if (_.isEmpty(data.daysOfWeek)) data = _.omit(data, ['daysOfWeek', 'startTime', 'endTime']);
                        else data = _.omit(data, ['start', 'end']);

                        if (_.get(calendarComponent.calendar, 'view.type') === "dayGridMonth") {
                            data.display = 'auto';
                            data.isBackGround = true;
                        }
                    }

                    deferred.resolve(data);
                }, deferred.reject);

            return deferred.promise;
        }

        // events handling
        handleMonthView(events) {
            return _.map(events, event => {
                if (event.display === BACKGROUND) event = _.assign(event, {
                    display: 'auto',
                    isBackGround: true
                });

                return event;
            });
        }

        handleBackEvents(events) {
            return _.map(events, (event) => {
                event['id'] = `${BACKGROUND}-${event.id}`;

                if (_.isEmpty(event.daysOfWeek)) event = _.omit(event, ['daysOfWeek', 'startTime', 'endTime']);
                else event = _.omit(event, ['start', 'end']);

                return event;
            });
        }

        handleWaitingItem(event) {
            return _.chain(event)
                .omit('resourceIds')
                .value();
        }

        toggleEventIdValue(item) {
            if (!_.isNil(item.real_id)) {
                item.id = item.real_id;
                delete item.real_id;
            } else if (_.has(item, "event_type")) {
                item.real_id = item.id;
                item.id = `${item.event_type}-${item.id}`;
            }
            return item;
        }
    }

    module.exports = CalendarService;

})();
