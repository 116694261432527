/**
 * Created by amine on 03/09/2018.
 */

(function () {
    'use strict';

    const PATHOLOGIES_FORM_DIALOG = require('../../dialogs/pathologies-dialog');

    class PathologyCtrl {
        constructor(configService, pathologyService, $translate, $element, $q, $mdDialog) {
            this.configService = configService;
            this.pathologyService = pathologyService;
            this.$translate = $translate;
            this.$q = $q;
            this.$element = $element;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["configService", "pathologyService", "$translate", "$element", "$q", "$mdDialog"];
        }

        $onInit() {
            this.medicalFile = this.medicalFile || false;
            this.save = _.isFunction(this.save) ? this.save : _.noop;
            this.useIcd = _.isNil(this.useIcd) ? false : this.useIcd;
            this.model = _.isNil(this.model) ? {} : this.model;
            this.fields = [];
            this.pathologyCustomButton = [
                {
                    tooltip: 'diagnostics.new_pathology',
                    icon: 'mdi-tag-plus',
                    action: event => this.addPathology(event)
                }
            ];
            this.configService.getByHttp(this.medicalFile ? "medical_file_config" : "diagnostics_config")
                .then(data => {
                    this.fields = _.get(data, this.medicalFile ? "diagnostics_fields" : "fields", this.pathologyService.DEFAULT_FIELDS);
                })

            this.ngModelController = this.ngModelController ? this.ngModelController : null;

            if (!_.has(this.model, "pathologies")) this.model.pathologies = [];
            if (!_.has(this.model, "icd")) this.model.icd = [];
        }

        addPathology(event) {
            this.dialog.show(_.assign({}, PATHOLOGIES_FORM_DIALOG, {
                targetEvent: event
            })).then(item => {
                this.model.push(item);
                _.mnDelay(this.save(), 300);
            }, _.noop);
        }

        w

        change() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }
    }

    module.exports = {
        bindings: {
            model: "=ngModel",
            useIcd: '<?',
            readOnly: '<?',
            medicalFile: '<?'
        },
        controllerAs: "vm",
        controller: PathologyCtrl,
        require: {ngModelController: "ngModel"},
        template: require('./diagnostics-block.tpl.html'),
    };

})();