/**
 * Created by Amine on 19/04/2018.
 */
(function () {
    'use strict';


    module.exports = {
        controller: MnAgeIntervalCtrl,
        controllerAs: "vm",
        require: {ngModelController: "ngModel"},
        bindings: {
            model: "=ngModel",
        },
        template: tpl,
    };

    MnAgeIntervalCtrl.$inject = [];

    function MnAgeIntervalCtrl() {
        let vm = this;

        vm.$onInit = init;
        vm.toggleInterval = toggleInterval;

        function init() {
            if (_.isNaN(vm.model)) vm.model = {};
        }

        function toggleInterval() {
            vm.model.age.is_infinite = false;
        }
    }

    tpl.$inject = [];

    function tpl() {
        return `
            <md-switch class="md-primary" flex="nogrow" ng-model="vm.model.is_obligatory" aria-label="is_obligatory">
                <span translate-once="vaccination_is_obligatory"></span>
            </md-switch>
            <md-switch class="md-primary" flex="nogrow" ng-if="!vm.model.age.is_interval" 
                    ng-model="vm.model.age.is_infinite" aria-label="is_infinite">
                <span translate-once="vaccination_age_infinite"></span>
            </md-switch>
            <md-switch class="md-primary" flex="nogrow" ng-change="vm.toggleInterval()" 
                    ng-model="vm.model.age.is_interval" aria-label="is_interval">
                <span translate-once="vaccination_age_interval"></span>
            </md-switch>
            <div class="flex-nogrow layout-row">
                <mn-number-container flex="nogrow" required label="vaccination_injections_age_min" type="integer"
                        ng-model="vm.model.age.min_value" mn-select-on-focus="true"></mn-number-container>
                <mn-number-container flex="nogrow" required label="vaccination_injections_age_max" type="integer" 
                        ng-model="vm.model.age.max_value" min="vm.model.age.min_value" ng-if="vm.model.age.is_interval" 
                        mn-select-on-focus="true"></mn-number-container>
            </div>
            <md-input-container>
                <label translate-once="vaccination_time_unit"></label>
                <md-select ng-model="vm.model.age.time_unit" required>
                    <md-option value="day" translate-once="vaccination_tu_day"></md-option>
                    <md-option value="week" translate-once="vaccination_tu_week"></md-option>
                    <md-option value="month" translate-once="vaccination_tu_month"></md-option>
                    <md-option value="year" translate-once="vaccination_tu_year"></md-option>
                </md-select>
            </md-input-container>`;
    }


})();