(function () {

    'use strict';
    module.exports = brightness;

    function brightness(){
        let vm = this;

        vm.resetCanvas();

        applyFilterValue(5, 'brightness', vm.luminosite*10, vm.currentCanvas.item(0), vm.currentCanvas);

    }


    function applyFilterValue(index, prop, value, obj, canvas) {

        if (obj.filters[index]) {
            obj.filters[index][prop] = value;
            obj.applyFilters();
            canvas.renderAll();
        }
    }

})();

