(function () {
    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = text;

    function text(){
        let vm = this;

        let resetCanvas = _.bind(require('./reset-canvas'), vm);
        resetCanvas();

        let text = new fabric.IText('Double-click to edit', {
            left: vm.currentCanvas.getCenter().left,
            top: vm.currentCanvas.getCenter().top,
            fontFamily: vm.textParams.font,
            textAlign: vm.textParams.textAlign,
            fill: vm.textParams.textColor,
            cursorColor: vm.textParams.textColor,
            borderColor: vm.textParams.BorderColor,
            fontSize: vm.textParams.fontSize,
            hasControls: true
            
        });

        vm.updateText = function() {
            if(vm.currentCanvas.getActiveObject() && vm.currentCanvas.getActiveObject().type === "i-text"){
                vm.currentCanvas.getActiveObject().setFill(vm.textParams.textColor);
                vm.currentCanvas.renderAll();
                vm.currentCanvas.getActiveObject().fontFamily = vm.textParams.font;
                vm.currentCanvas.getActiveObject().textAlign = vm.textParams.textAlign;
                vm.currentCanvas.getActiveObject().fontSize = vm.textParams.fontSize;
                vm.currentCanvas.renderAll();
            }
        };


        vm.currentCanvas.add(text);
        vm.currentCanvas.renderAll();


    }
})();