(function () {
    'use strict';


    module.exports = handleCustomStyle;

    function handleCustomStyle(type, e, c, rules, draftRule, dateFormat) {
        let styleRules = draftRule ? _.concat(rules, draftRule) : rules;
        return type == "COLUMN" ? handleColumnStyle(type, e, c, styleRules, draftRule, dateFormat) : handleRowStyle(styleRules, e, dateFormat);
    }

    function handleColumnStyle(type, e, c, rules, draftRule, dateFormat) {
        if (!e) return;
        let groupedRules = _.filter(rules, (rule) => {
            return rule.target_column === c.order_by && rule.type == 'COLUMN';
        });
        return _.reduce(groupedRules, (result, rule) => {
            let value = handleCustomValue(e, c, rule.target_column);
            if (!_.isUndefined(value)) {
                return _.assignIn(result, _.get(getConditionalFormattingRule(rule, value, dateFormat), 'style', {}));
            }
        }, {});
    }

    function handleRowStyle(rules, row, dateFormat) {
        if (rules) {
            let groupedRules = _.filter(rules, {'type': 'ROW'});

            return _.reduce(groupedRules, (result, rule) => {
                let value = _.get(row, rule.target_column);

                if (!_.isUndefined(value)) {
                    return _.assignIn(result, _.get(getConditionalFormattingRule(rule, value, dateFormat), 'style', {}));
                }
            }, {});
        }
    }

    function handleCustomValue(e, c, target_column) {
        return _.get(e, target_column)
    }

    function getConditionalFormattingRule(rule, value, dateFormat) {
        const prepareCondition = handleFormattingStyle(rule.condition, value, rule.target_value, dateFormat);
        if (prepareCondition) return rule;
    }

    function handleFormattingStyle(conditionKey, value, targetValue, dateFormat) {
        if (_.isUndefined(value)) return;
        if (_.isNil(value) && !_.includes(['is_empty', 'is_not_empty'], conditionKey)) return;
        switch (conditionKey) {
            case 'is_empty':
                return _.isEmpty(value);

            case 'is_not_empty':
                return !_.isEmpty(value);
            // ----------- text -----------
            case 'is_contains':
                return value.includes(targetValue);

            case 'not_contains':
                return !value.includes(targetValue);

            case 'start_with':
                return value.startsWith(targetValue);

            case 'end_with':
                return value.endsWith(targetValue);

            case 'exactly':
                return value === targetValue;

            case 'is_true':
                return value === true;

            case 'is_false':
                return value === false;
            // ----------- number -----------
            case 'greater':
                return value > targetValue;

            case 'greaterE':
                return value >= targetValue;

            case 'little':
                return value < targetValue;

            case 'littleE':
                return value <= targetValue;

            case 'equal':
                return value === targetValue;

            case 'n_equal':
                return value !== targetValue;

            case 'is_between':
                return value <= targetValue[1] && value >= targetValue[0];

            case 'not_between':
                return !(value <= targetValue[1] && value >= targetValue[0]);
            // ----------- Date -----------
            case 'date_is':
                return value == targetValue;

            case 'is_before':
                return new Date(moment(value, dateFormat, true)) < new Date(moment(targetValue, dateFormat, true));

            case 'is_after':
                return new Date(moment(value, dateFormat, true)) > new Date(moment(targetValue, dateFormat, true));
            // ----------- Time -----------
            case 'time_is':
                return minutesOfDay(moment(value)) === minutesOfDay(moment(targetValue, "HH:mm"));

            case 'time_is_before':
                return minutesOfDay(moment(value)) < minutesOfDay(moment(targetValue, "HH:mm"));

            case 'time_is_after':
                return minutesOfDay(moment(value)) > minutesOfDay(moment(targetValue, "HH:mm"));

            default:
                return null;
        }
    }

    function minutesOfDay(v1) {
        return v1.minutes() + v1.hours() * 60;
    }
})();