/**
 * Created by amine on 16/04/2018.
 */
(function () {

    'use strict';

    module.exports = {
        controller: VaccinationConfigDialogController,
        controllerAs: "vm",
        template: require("../views/vaccination-config-form.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true,
        multiple: true
    };

    VaccinationConfigDialogController.$inject = ["$mdDialog", "vaccinationService"];

    function VaccinationConfigDialogController($mdDialog, vaccinationService) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.removeVaccine = removeVaccine;
        vm.addVaccine = addVaccine;
        vm.changeInjections = detailsChange("injections");
        vm.changeBoosters = detailsChange("boosters");
        vm.cancel = $mdDialog.cancel;

        function init() {
            if (_.isNil(vm.config))
                vm.config = {
                    vaccines: [],
                    injections: [{}],
                    boosters: [],
                    injections_count: 1,
                    boosters_count: 0,
                    gender: "UNDEFINED"
                };

            vm.promise = vaccinationService.getVaccines()
                .then(data=> vm.vaccines = data);
        }

        function addVaccine() {
            vm.config.vaccines = _.chain(vm.config.vaccines)
                .concat(vm.selectedVaccines)
                .uniqBy('id')
                .filter(item => !_.isNil(item))
                .value();
            vm.selectedVaccines = null;
        }

        function removeVaccine(item) {
            _.remove(vm.config.vaccines, _.pick(item, "id"));
        }

        function detailsChange(name) {
            return function () {
                vm.config[name] = [];
                _.range(0, vm.config[name + '_count'])
                    .forEach(function () {
                        vm.config[name].push({})
                    })
            }
        }

        function submit() {
            return vaccinationService
                .saveVaccinationConfig(vm.config)
                .then($mdDialog.hide)
        }
    }

})();
