(function () {

    'use strict';

    const {DELETE, BACKGROUND} = require('stand-alone/calendar/utils/consts');

    class PauseCalendarElementCtrl {
        constructor(frontDeskService, mnWebSocket, system) {
            this.mnWebSocket = mnWebSocket;
            this.frontDeskService = frontDeskService;

            this.event = this.event || {};
            this.timeFormat = system['time_format'].js;
        }

        static get $inject() {
            return ['frontDeskService', 'mnWebSocket', 'system'];
        }

        $onInit() {
            this.eventTime = `${this.event.startMoment.format(this.timeFormat)} - ${this.event.endMoment.format(this.timeFormat)}`;
        }

        editEvent(ev) {
            this.frontDeskService.editAppointment(_.cloneDeep(this.event), ev);
        }

        deleteEvent(ev) {
            ev.stopPropagation();

            this.frontDeskService.removeAppointment(this.event.id)
                .then(() => {
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                        cmd: DELETE,
                        type: BACKGROUND,
                        event: this.event.id.split('-')[1]
                    }, false);
                });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const agendaType = $attrs.type;

        const layoutClass = agendaType === 'dayGridMonth' ? '' : 'pause-layout';
        const dataClass = agendaType === 'dayGridMonth' ? 'pause-month-data' : 'pause-data';
        const physician = agendaType === 'dayGridMonth' ? '' : '<span class="physician-block" ng-bind="vm.event.physician.full_name"></span>';

        return `
            <div class="${layoutClass} layout-row">
                <div class="${dataClass} flex">
                    <span class="title-block" ng-bind="vm.event.title"></span>
                    <span class="time-block" ng-bind="vm.eventTime"></span>
                    ${physician}
                </div>
                
                <div class="action-bar">
                    <md-button class="md-icon-button" ng-click="vm.deleteEvent($event)" aria-label="delete">
                        <md-icon md-font-icon="mdi-delete" md-font-set="mdi" class="mn-danger"></md-icon>
                    </md-button>
                </div>
            </div>
        `
    }

    module.exports = {
        bindings: {
            event: '<', type: '@'
        },
        template: tpl, // or template
        controllerAs: "vm",
        controller: PauseCalendarElementCtrl,
    };

})();
