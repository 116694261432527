(function () {

    'use strict';

    module.exports = ophthalmicService;

    ophthalmicService.$inject = ["$q", "$http", "mnWebSocket"];

    function ophthalmicService($q, $http, mnWebSocket) {
        var self = this;

        self.savePrescription = savePrescription;
        self.last_prescription = last_prescription;
        self.getPatientPrescriptions = getPatientPrescriptions;

        function savePrescription(prescription) {
            var deferred = $q.defer();
            var url = '/api/ophthalmic-prescription/';

            if (prescription.id) url += prescription.id + '/';

            $http.post(url, prescription)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getPatientPrescriptions(patient) {
            var deferred = $q.defer();
            var url = '/api/ophthalmic-prescription/?patient=' + patient;

            $http.get(url)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function last_prescription() {
            return mnWebSocket.call("interfacing.ReceivedData.last_received_data", {
                driver_name: "APH550"
            });
        }

    }

})();