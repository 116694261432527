/**
 * Created by BETALOS on 23/06/2016.
 */
(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    module.exports = blockService;

    blockService.$inject = ["mnWebSocket", "$http", "$q"];

    function blockService(mnWebSocket, $http, $q) {
        let self = this;

        self.get = getBlock;

        self.getBlock = getBlockById;
        self.handleBlock = handleBlock;
        self.getBlockList = getBlockList;

        self.getHtmlFields = getHtmlFields;

        self.choicesSubject = new BehaviorSubject([]);
        self.getChoiceListFields = getChoiceListFields;
        self.handleChoiceListFields = handleChoiceListFields;


        function getBlock(key, version) {
            let query = {key: key, version: version};
            return mnWebSocket.call('shared.blocks.HtmlBlock.retrieve', query);
        }

        function handleBlock(block, type) {
            let deferred = $q.defer();
            let url = `/api/${type}-block/`;

            $http.post(url, block)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getBlockList(blockType) {
            let deferred = $q.defer();

            if (!!blockType) {
                let url = `/api/${blockType}-block-list/`;
                $http.get(url)
                    .then(success, deferred.reject);
            } else {
                $q.all([
                    getBlockList('html'),
                    getBlockList('survey'),
                    getBlockList('form')
                ]).then(success, deferred.reject);
            }


            function success(response) {
                let data = []

                if (_.isArray(response)) data = _.flatten(response);
                else data = response.data;

                deferred.resolve(data)
            }

            return deferred.promise;
        }

        function getBlockById(block, type) {
            let deferred = $q.defer();
            let url = `/api/${type}-block/${block}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getHtmlFields() {
            let deferred = $q.defer();
            let url = "/api/html-field/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getChoiceListFields() {
            let deferred = $q.defer();
            let url = "/api/choice-list-field/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                let items = _.sortBy(response.data, 'label');
                self.choicesSubject.next(items);

                deferred.resolve(items);
            }

            return deferred.promise;
        }

        function handleChoiceListFields(field) {
            let deferred = $q.defer();
            let url = "/api/choice-list-field/";

            $http.post(url, field)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

    }

})();
