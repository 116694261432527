/**
 * Created by BETALOS on 28/06/2016.
 */
(function () {

    'use strict';

    const CHIP_EDITION_DIALOG = require('../dialogs/dictionary-chip-edition-dialog');

    class AutoCompleteCtrl {
        constructor(autoCompleteService, $element, $mdDialog) {
            this.dialog = $mdDialog;
            this.element = $element;
            this.service = autoCompleteService;

            this.model = [];
            this.field = this.field || null;
            this.event = this.event || false;
            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["autoCompleteService", "$element", "$mdDialog"];
        }

        $onInit() {
            this.emptyChip = this.emptyChip ? this.emptyChip : {};
            this.customButtons = this.customButtons ? this.customButtons : [];

            this.element.on('click', '.md-chips', ev => this.triggerFocus(ev));
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        triggerFocus(ev) {
            const $node = $(ev.currentTarget);
            if (!$node.is('.md-focused')) $('.md-input', this.element).trigger('focus');
        }

        querySearch(query) {
            return this.service.search(this.event, query, this.field);
        }

        emptyField() {
            this.model = [];
            this.change();
        }

        transformChip(chip) {
            if (_.isObject(chip)) {
                return _.pick(chip, ['id', this.field]);
            } else {
                return _.assign(this.emptyChip, {[this.field]: chip});
            }
        }

        editChip(chip, ev) {
            this.dialog.show(_.assign({}, CHIP_EDITION_DIALOG, {
                targetEvent: ev,
                locals: {
                    field: this.field,
                    chip: _.cloneDeep(chip)
                }
            })).then(node => {
                if (node[this.field] !== chip[this.field]) {
                    node.edited = true;
                }

                const index = _.indexOf(this.model, chip);
                this.model.splice(index, 1, _.omit(node, '$$hashKey'));
                this.change();
            });
        }

        change() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model) {
                this.model = this.ngModelController.$viewValue || [];
            }
        }
    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            mnLabel: '<',
            emptyChip: '<',
            event: '@mnEvent',
            field: '@mnFieldName',
            requireMatch: '<mnMatch',
            readOnly: "<?mnReadOnly",
            labelColor: "@?",
            customButtons: "<?"
        },
        controller: AutoCompleteCtrl,
        require: {ngModelController: "ngModel"},
        template: require('stand-alone/blocks/views/autocomplete.tpl.html')
    };

})();