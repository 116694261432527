(function () {

    'use strict';

    class ViewerDialogCtrl {
        constructor($mdDialog, dcmService) {
            this.dialog = $mdDialog;
            this.dcmService = dcmService;

            this.viewer = this.viewer || {};
        }

        static get $inject() {
            return ["$mdDialog", "dcmService"];
        }

        $onInit() {

        }

        submit() {
            this.promise = this.dcmService.handleViewer(this.viewer)
                .then(data => this.dialog.hide(data));
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ViewerDialogCtrl,
        template: require("./dcm-viewer-dialog.tpl.html"),
    };

})();
