(function () {
    'use strict';

    module.exports = removeLabel;

    function removeLabel(){
        let vm = this;


        if(vm.currentCanvas.getActiveObject() && vm.currentCanvas.getActiveObject().type !== "image"){
            vm.currentCanvas.remove(vm.currentCanvas.getActiveObject());
        }
    }

})();