(function () {

    'use strict';


    module.exports = {
        bindings: {
            patient: '<',
            postSave: '&',
            currentDate: '<?',
        },
        controllerAs: "vm",
        controller: OphthalmicPrescriptionCtrl,
        template: require('specifics/views/ophthalmic-prescription.tpl.html')
    };

    const IMPORTED_EXAMS_DIALOG = require('stand-alone/interfacing/dialogs/import-exam-dialog');

    OphthalmicPrescriptionCtrl.$inject = ["ophthalmicService", "system", "$scope", "$mdDialog", "interfacingService", '$translate', '$q', 'configService', 'mnWebSocket'];

    function OphthalmicPrescriptionCtrl(ophthalmicService, system, $scope, $mdDialog, interfacingService, $translate, $q, configService, mnWebSocket) {
        var vm = this;

        var lastPrescription = null;
        var dateFormat = system['date_format'].js;
        var namespace = 'ophtalmicPrescriptionModule';
        var dateTimeFormat = system['datetime_format'].js;

        vm.$onInit = init;
        vm.$onDestroy = destroy;

        vm.newPrescription = newPrescription;

        vm.preSave = preSavePrescription;
        vm.savePrescription = savePrescription;
        vm.importPrescription = importPrescription;
        vm.setLastPrescription = setLastPrescription;

        vm.next = next;
        vm.choose = choose;
        vm.previous = previous;

        function init() {
            mnWebSocket.sub("interfacing.InterfacingDevice.data_notify", namespace, updateLastPrescription);

            vm.isSubjective = null;
            var unwatch = $scope.$watch('vm.currentDate', handleDate);

            function handleDate(date) {
                if (_.isNil(date)) return;

                unwatch();
                vm.promise = promises().then(success);
            }

            function success(data) {
                lastPrescription = data[0];
                vm.isSubjective = _.get(data, '0.exam_data.IS_SUBJECTIVE', null);

                vm.fields = handleFields(data[1]);
                vm.versions = _.orderBy(data[2], 'sort_date', 'desc');

                handleCurrentVersion();
            }

            function promises() {
                return $q.all([
                    ophthalmicService.last_prescription(),
                    configService.get("ophtalmic_fields_config", true),
                    ophthalmicService.getPatientPrescriptions(vm.patient),
                ])
            }
        }

        function destroy() {
            mnWebSocket.unsub("interfacing.InterfacingDevice.data_notify", namespace);
        }

        function updateLastPrescription(data) {
            if (data['default_config_title'] == 'APH550') {
                lastPrescription = data;
                vm.isSubjective = data['exam_data']['IS_SUBJECTIVE'];
            }
        }

        function handleCurrentVersion() {
            if (_.isEmpty(vm.versions)) newPrescription();
            else {
                let version = _.chain(vm.versions).sortBy('sort_date').findIndex(function (item) {
                    return moment(item['prescription_date'], dateTimeFormat).valueOf() >= moment(vm.currentDate, dateFormat).valueOf()
                }).value();

                vm.currentVersion = version == -1 ? 0 : version;
                choose();
            }
        }

        function handleFields(config) {
            return _.map(['observation', 'comment'], function (item) {
                return {
                    key: item,
                    label: $translate["instant"]('ophthalmic_' + item),
                    meta_data: {
                        dict_uid: _.get(config, item)
                    }
                }
            });
        }

        function newPrescription() {
            vm.currentVersion = -1;

            vm.currentPrescription = {
                objective: {},
                subjective: {},
                old_correction: {},
                patient: {id: vm.patient},
                prescription_date: moment().format(dateTimeFormat)
            };
        }

        function savePrescription() {
            vm.tablePromise = preSavePrescription();
        }

        function preSavePrescription() {
            return ophthalmicService.savePrescription(vm.currentPrescription).then(saveSuccess);
        }

        function saveSuccess(data) {
            vm.postSave.call();

            vm.currentPrescription = _.assign(data, {patient: {id: vm.patient}});
            vm.versions = _.chain(vm.versions).pushOrUpdate(data).orderBy('sort_date', 'desc').value();
            vm.currentVersion = _.findIndex(vm.versions, ['id', vm.currentPrescription.id]);
        }

        function setLastPrescription(type) {
            vm.currentPrescription[type] = lastPrescription['exam_data'];
        }

        function importPrescription(ev) {
            $mdDialog.show(_.assign({}, IMPORTED_EXAMS_DIALOG, {
                targetEvent: ev,
                locals: {
                    singleActions: [],
                    itemClick: selectExam,
                    query: {
                        "default_config.title": "APH550"
                    }
                }
            }));
        }

        function selectExam(exam, ev, promise) {
            promise = interfacingService.getExamInstance(exam.id).then(success);

            function success(data) {
                if (data['exam_data']['IS_SUBJECTIVE']) {
                    $mdDialog.cancel();
                    vm.currentPrescription.subjective = data['exam_data'];
                }
                else {
                    var confirm = $mdDialog.confirm()
                        .multiple(true)
                        .targetEvent(ev)
                        .escapeToClose(false)
                        .ariaLabel('ophthalmic_imported_data_type')
                        .title($translate["instant"]('ophthalmic_imported_data_type'))
                        .ok($translate["instant"]('ophthalmic_imported_data_old_correction'))
                        .cancel($translate["instant"]('ophthalmic_imported_data_objective'));

                    $mdDialog.show(confirm).then(oldCorrection, objective);
                }

                function oldCorrection() {
                    $mdDialog.cancel();
                    vm.currentPrescription.old_correction = data['exam_data'];
                }

                function objective() {
                    $mdDialog.cancel();
                    vm.currentPrescription.objective = data['exam_data'];
                }
            }
        }

        function next() {
            vm.currentVersion -= 1;
            choose();
        }

        function choose() {
            vm.currentPrescription = _.assign(
                vm.versions[vm.currentVersion], {
                    patient: {id: vm.patient},
                }
            );
        }

        function previous() {
            vm.currentVersion += 1;
            choose();
        }
    }

})();