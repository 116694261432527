(function () {
    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = invert;

    function invert(){
        let vm = this;
        
        vm.resetCanvas();

        let filter = new fabric.Image.filters.Invert();
        vm.currentCanvas.item(0).filters.push(filter);
        vm.currentCanvas.item(0).applyFilters(vm.currentCanvas.renderAll.bind(vm.currentCanvas));
    }

})();