/**
 * Created by BETALOS on 04/04/2017.
 */
(function () {
    'use strict';

    module.exports = {
        controller: DictionaryMultiNodeDialogCtrl,
        controllerAs: "vm",
        parent: $(document.body),
        locals: {},
        bindToController: true,
        clickOutsideToClose: true,
        template: require('stand-alone/blocks/views/dictionary-multi-node-dialog.tpl.html'),
    };

    DictionaryMultiNodeDialogCtrl.$inject = ["$mdDialog", "dictionaryService"];

    function DictionaryMultiNodeDialogCtrl($mdDialog, dictionaryService) {
        let vm = this;

        vm.$onInit = init;
        vm.validate = validate;
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.dict = _.cloneDeep(dictionaryService.getGroup(vm.uid));
        }

        function validate() {
            $mdDialog.hide(getSelectedValues(vm.dict));
        }

        function getSelectedValues(nodes) {
            return _.reduce(nodes, function (result, item) {
                if (item.isChecked && _.isNull(item.nodes)) result.push(item)
                else if (!_.isNull(item.nodes)) result = _.concat(result, getSelectedValues(item.nodes));
                return result
            }, []);
        }

    }

})()
