/**
 * Created by amine on 12/07/2021.
 */

(function () {
    'use strict';

    class ICDFieldCtrl {
        constructor(classificationsService, $translate, $element, $q, $mdDialog) {
            this.classificationsService = classificationsService;
            this.$translate = $translate;
            this.$q = $q;
            this.$element = $element;
            this.dialog = $mdDialog;

            this.requireMatch = true;
        }

        static get $inject() {
            return ['classificationsService', '$translate', "$element", "$q", "$mdDialog"];
        }

        $onInit() {
            this.search = ""

            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.internalValue) {
                this.internalValue = this.ngModelController.$viewValue;
            }
        }

        emptyField() {
            this.internalValue = [];
            this.change();
        }

        change() {
            let viewValue = _.cloneDeep(this.internalValue);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        getData(value) {
            return this.classificationsService.icdSearch(value);
        }

        transformChip(chip) {
            if (_.isObject(chip)) {
                return _.pick(chip, ['id', 'code', 'value', 'usage']);
            } else {
                return false
            }
        }

    }

    module.exports = {
        bindings: {
            readonly: '<?readonly'
        },
        controllerAs: "vm",
        controller: ICDFieldCtrl,
        require: {ngModelController: "ngModel"},
        template: require('./icd-field.tpl.html'),
    };

})();