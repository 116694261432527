/**
 * Created by amine on 13/08/2018.
 */
(function () {

    const downloadJs = require('downloadjs');
    const {extension} = require('mime-types');
    const FIELDS = require("parameters/json/external-patient-api-fields.json");

    class ExternalConnectionService {
        constructor(configService, $q, mnWebSocket, $http, $mdDialog, Upload) {
            this.configService = configService;
            this.ws = mnWebSocket;
            this.q = $q;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.Upload = Upload;
        }

        static get $inject() {
            return ["configService", "$q", "mnWebSocket", "$http", "$mdDialog", "Upload"];
        }

        get(key) {
            return this.configService.get(key);
        }

        set(key, value) {
            const promises = []
            const mine = true;
            const speciality = true;
            if (!!value.password) {
                const passwordEvent = "shared.PasswordStore.set_config";
                const password = _.get(value, "password")

                promises.push(this.configService.set({[key]: password}, mine, speciality, passwordEvent));
            }

            value = _.omit(value, ["password", "password_confirmation"]);
            promises.push(this.configService.set({[key]: value}, mine, speciality));

            return this.q.all(promises);
        }

        shareDocument(group, model, uid, extraQuery, message, title, viewSet) {
            if (_.isNil(viewSet)) viewSet = group;
            let deferred = this.q.defer();

            let extra = '';
            const query = _.isEmpty(extraQuery) ? "" : `&${extraQuery}`;

            if (!_.isNil(uid)) extra = `&uid=${uid}`;
            else if (!_.isNil(self.staffDefaults)) {
                let defaultTmp = _.chain(self.staffDefaults.defaults).values().find({
                    group: {name: group}
                }).get("template_uid").value();
                extra += _.isUndefined(defaultTmp) ? "" : `&uid=${defaultTmp}`;
            }

            this.$http.post(`/api/${viewSet}/${model}/share/?group=${group}${extra}${query}`, {message, title}).then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        sendConsoleMessage(message) {
            return this.ws.call("shared.External.send_console_message", message)
        }

        getSMSModels() {
            const deferred = this.q.defer();

            this.$http.get("/api/sms-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveSMSModel(model) {
            const deferred = this.q.defer();

            this.$http.post(`/api/sms-model/${_.isNil(model.id) ? '' : model.id + '/'}`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        deleteSMSModel(model) {
            const deferred = this.q.defer();

            this.$http.delete(`/api/sms-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        showSMSDialog($event, wsEvent, query, customFields = null) {
            const dialog = require('shared/dialogs/sms-sending-dialog');

            return this.$mdDialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {
                    event: wsEvent,
                    query: query,
                    customFields
                }
            }))
        }

        sendSms(event, query) {
            return this.ws.call(!event.includes(".") ? `shared.External.${event}` : event, query)
        }

        getHistory(query) {
            return this.ws.call("shared.External.history", query)
        }

        getEmailModels() {
            const deferred = this.q.defer();

            this.$http.get("/api/email-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveEmailModel(model) {
            const deferred = this.q.defer();

            this.$http.post(`/api/email-model/${_.isNil(model.id) ? '' : model.id + '/'}`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        deleteEmailModel(model) {
            const deferred = this.q.defer();

            this.$http.delete(`/api/email-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getConsentModels() {
            const deferred = this.q.defer();

            this.$http.get("/api/consent-model/").then(
                result => deferred.resolve(result.data),
                result => deferred.reject(result)
            );

            return deferred.promise;
        }

        saveConsentModel(data) {
            const deferred = this.q.defer();
            const url = `/api/consent-model/${_.isNil(data.id) ? '' : data.id + '/'}`;

            this.Upload.upload({url, data})
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        downloadConsentModel(data) {
            const deferred = this.q.defer();
            const url = `/api/consent-model/${data.id}/download/`;

            this.$http.get(url, {responseType: 'arraybuffer'})
                .then(success, deferred.reject);

            function success(res) {
                const ext = extension(res.headers('content-type'));
                const fileName = `${data.title}.${ext}`;

                downloadJs(res.data, fileName);
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        deleteConsentModel(model) {
            const deferred = this.q.defer();

            this.$http.delete(`/api/consent-model/${model.id}/`, model)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getPatientAPIFields(patient_custom_fields, technical_file_fields) {
            const order = ["custom_fields", "technical_file", "sport_file"];
            return _.chain(FIELDS)
                .concat(
                    _.map(patient_custom_fields, customField => this.customField(customField)),
                    _.map(technical_file_fields || [], customField => this.customField(customField, "technical_file"))
                )
                .orderBy(item => {
                    return _.indexOf(order, item.group);
                }, ['asc'])
                .value();
        }

        customField(field, group = "custom_fields") {
            switch (field.type) {
                case "date":
                    return {
                        field: field.slug,
                        group: group,
                        label: field.label,
                        type: "date",
                        format: "%Y-%m-%d",
                        noTranslate: true
                    }
                case "custom-select":
                    return {
                        field: field.slug,
                        group: group,
                        label: field.label,
                        type: "list",
                        model: "CustomList",
                        noTranslate: true,
                    }
                default:
                    return {
                        field: field.slug,
                        group: group,
                        label: field.label,
                        noTranslate: true
                    }
            }
        }
    }

    module.exports = ExternalConnectionService;
})();
