(function () {

    'use strict';


    module.exports = {
        controller: humanSkeletonCtrl,
        controllerAs: "vm",
        bindings: {
            subject: "<"
        },
        template: require('specifics/views/human-skeleton.tpl.html'), // or template
    };

    humanSkeletonCtrl.$inject = [];

    function humanSkeletonCtrl() {
        var vm = this;

        vm.selected = "front";

        var frontSVG = window.front = SVG.get('skeleton-front');
        var backSVG = SVG.get('skeleton-back');

        vm.$onInit = init;

        function init() {
            vm.$onDestroy = onDestroy;
            var subscription = vm.subject.subscribe(handleProcedure);

            function onDestroy() {
                subscription.unsubscribe();
            }
        }

        function handleProcedure(procedure) {
            var draw = getActiveSVG();

            draw[procedure.shape.type]
                .apply(draw, procedure.shape.params)
                .attr(procedure.shape.attrs)
                .draggable();
        }

        function getActiveSVG() {
            if(vm.selected == "front") return frontSVG;
            else return backSVG;
        }

    }

})();