(function () {

    'use strict';

    class DictionaryChipEditionDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        hide() {
            this.dialog.hide(this.chip);
        }

        abort() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DictionaryChipEditionDialogCtrl,
        template: require("../views/dictionary-chip-edition-dialog.tpl.html"),
    };


})();