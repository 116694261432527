(function () {

    'use strict';

    class FilesFilterCtrl {
        constructor() {

        }

        static get $inject() {
            return [];
        }

        toggle(item) {
            const index = _.indexOf(this.filter, item.id);

            if (index == -1) this.filter = _.chain(this.filter).concat(item.id).compact().value();
            else this.filter.splice(index, 1);
        }

        isShown(item) {
            return _.indexOf(this.filter, item.id) != -1;
        }

    }

    tpl.$inject = [];

    function tpl() {
        return [
            '<md-menu md-position-mode="target-right target">',
            '   <md-button ng-hide="vm.item.items.length == 0" ng-class="{active: vm.filter.length > 0}" class="md-icon-button" aria-label="Open demo menu" ng-click="$mdMenu.open($event)">',
            '       <md-icon md-font-icon="{{ vm.item.icon }}" md-font-set="mdi"></md-icon>',
            '       <md-tooltip md-direction="bottom">',
            '           <span ng-bind="vm.item.title | translate"></span>',
            '       </md-tooltip>',
            '   </md-button>',
            '   <md-menu-content width="3" class="practice-menu" style="max-height: 304px">',
            '       <md-menu-item ng-repeat="item in vm.item.items track by $index">',
            '           <md-button ng-click="vm.toggle(item)" md-prevent-menu-close="md-prevent-menu-close">',
            '               <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="vm.isShown(item)"></md-icon>',
            '               <span ng-bind="item.title"></span>',
            '           </md-button>',
            '       </md-menu-item>',
            '   </md-menu-content>',
            '</md-menu>'
        ].join('');
    }


    module.exports = {
        bindings: {
            item: '<',
            filter: '='
        },
        template: tpl,
        controllerAs: "vm",
        controller: FilesFilterCtrl
    };

})();