/**
 * Created by amine on 21/01/2020.
 */
(function () {

    'use strict';

    class PregnancyMilestoneFormDialogController {
        constructor($mdDialog, pregnancyService) {
            this.dialog = $mdDialog;
            this.pregnancyService = pregnancyService;
        }

        static get $inject() {
            return ["$mdDialog", "pregnancyService"];
        }

        $onInit() {
            this.config = this.config || {
                is_milestone: true,
                time_interval: {
                    is_amenorrhea_based: false,
                    start: 0,
                    end: null,
                    week_count: 0,
                    is_infinite: false
                }
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.pregnancyService
                .saveConfigurationMilestone(this.config)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: PregnancyMilestoneFormDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/pregnancy-milestone-form.tpl.html"),
    };


})();
