/**
 * Created by amine on 08/09/2020.
 */
(function () {

    'use strict';

    const DIALOG = require('../dialogs/growth-charts-dialog');

    class GrowthCharts {
        constructor($mdDialog) {
            this.restrict = "A";
            this.dialog = $mdDialog;
        }

        static create() {
            return new GrowthCharts(...arguments);
        }

        link(scope, element, attrs) {
            const patientId = parseInt(attrs['growthCharts']);
            const container = document.getElementById(attrs['parentContainer']);

            element.on('click', (event) => {
                this.openDialog(patientId, container, event);
            });
        }

        openDialog(patient, container, event) {
            this.dialog.show(_.assign({}, DIALOG, {
                parent: container,
                targetEvent: event,
                locals: {
                    patient
                }
            }));
        }
    }

    GrowthCharts.create.$inject = ['$mdDialog'];

    module.exports = GrowthCharts.create;

})();