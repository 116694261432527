
(function () {
    'use strict';
    module.exports = dimCalculate;


    function dimCalculate(imgInstance){
        let vm = this;
        let $canvas = $('.canvas');
        let imgDimensions = {};
        let imageAspectRatio = imgInstance.width / imgInstance.height;
        let canvasAspectRatio = $canvas.width() / $canvas.height();
        vm.oldImgSize.width = imgInstance.width;
        vm.oldImgSize.height = imgInstance.height;

        // If image's aspect ratio is less than canvas's we fit on height
        if(imageAspectRatio < canvasAspectRatio) {
             imgInstance.width *= $canvas.height() / imgInstance.height;
             imgInstance.height = $canvas.height();

            // If image's aspect ratio is greater than canvas's we fit on width
        }else if(imageAspectRatio > canvasAspectRatio) {
             imgInstance.height *= $canvas.width() / imgInstance.width;
             imgInstance.width = $canvas.width();

        }else {
            imgInstance.height = $canvas.height();
            imgInstance.width = $canvas.width();
        }

            imgDimensions['height'] = Math.round(imgInstance.height);
            imgDimensions['width'] = Math.round(imgInstance.width);

        return imgDimensions;

    }

})();