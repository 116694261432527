/**
 * Created by amine on 22/03/2017. // edited 28/01/2020
 */

(function () {
    'use strict';


    class GynecologyInfoCtrl {
        constructor($q, configService, pregnancyService, patientService, $scope, $element, $attrs) {
            this.$q = $q;
            this.configService = configService;
            this.patientService = patientService;
            this.pregnancyService = pregnancyService;
            this.scope = $scope;
            this.element = $element;
            this.attrs = $attrs;

            this.ngModelController = this.ngModelController || null;
            this.model = {};

            this.patient = this.patient || null;
            this.pregnancy = this.pregnancy || null;
            this.calculated = this.calculated || false;
            this.readonly = this.readonly || false;
            this.noTerm = this.noTerm || false;
        }

        static get $inject() {
            return ["$q", "configService", "pregnancyService", "patientService", "$scope", "$element", "$attrs"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();

            this.change = _.mnDelay(() => this._change(), 500);

        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model)
                this.model = this.ngModelController.$viewValue;
        }

        $onChanges(changes) {
            if (_.get(changes, "patient.currentValue")) {
                this.patient = _.get(changes, "patient.currentValue");
                this.$q.all([
                    this.configService.getByHttp("pregnancy_block"),
                    this.pregnancyService.getCalendar(),
                    this.patientService.getMinimalPatient(this.patient)
                ]).then(data => {
                    this.blockConfig = data[0];
                    this.blockCalendar = data[1];
                    this.patientInstance = data[2];
                    this.fieldsConfig = _.get(this.blockConfig, "fields", {});

                    if (this.patientInstance) this.hideElement();
                }, _.noop);
            }
        }

        hideElement() {
            if (!this.fieldsConfig.show_fields || (this.patientInstance.gender === 'MALE' && !this.fieldsConfig.ignore_gender))
                this.element.hide();
        }

        _change() {
            setTimeout(() => {
                let model = _.cloneDeep(this.model);

                this.ngModelController.$setViewValue(model, 'change');
                this.ngModelController.$commitViewValue();
            }, 500)
        }

        generateOrEditPregnancy() {
            if (_.isNil(this.pregnancy.amenorrhea_start)) return false;
            this.pregnancy = this.pregnancyService
                .calculateFields(_.assign(this.pregnancy, {patient: this.patientInstance}), this.blockConfig, this.blockCalendar);

            return this.pregnancyService.save(this.pregnancy)
                .then((data) => {
                    this.pregnancy = this.pregnancyService.calculateFields(data, this.blockConfig, this.blockCalendar);
                }, _.noop);
        }
    }

    module.exports = {
        bindings: {
            patient: '<',
            pregnancy: '<',
            readonly: '<'
        },
        require: {ngModelController: "ngModel"},
        template: require("specifics/views/gynecology-info.tpl.html"),
        controllerAs: "vm",
        controller: GynecologyInfoCtrl,
    };
})();
