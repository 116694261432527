/**
 * Created by amine on 29/07/2021.
 */
(function () {

    'use strict';

    const {WAITING_LIST, APPOINTMENT, DELETE} = require('../utils/consts');
    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');

    const RDV_MENU_ELEMENT = `
        <md-button class="md-icon-button" aria-label="rdv menu" ng-click="vm.openMenu($event)" type="button">
            <md-icon md-font-icon="mdi-dots-vertical" md-font-set="mdi"></md-icon>
        </md-button>
    `;

    const RDV_MENU_CONTAINER = `
        <md-menu md-position-mode="target-right target">
            <md-button aria-label="rdv menu" class="md-icon-button" ng-click="null"></md-button>
            <md-menu-content class="mn-calendar-menu-v2" role="menu">
                <md-menu-item ng-repeat="room in vm.rooms track by room.id" ng-if="vm.isToday" mn-acl='{"action": "create", "resource": "entry"}'>
                    <md-button ng-click="vm.createEntries('wr', $event,room)" aria-label="edit"
                               class="mn-room-menu-button">
                        <md-icon md-font-icon="mdi-seat-recline-extra" md-font-set="mdi"></md-icon>
                        <span ng-bind="::room.name" flex></span>
                        <span class="mn-room-badge" ng-bind="room['occupy'] + '/' + room.capacity"></span>
                    </md-button>
                </md-menu-item>
                
                <md-menu-divider ng-if="vm.rooms.length > 0 && vm.isToday" mn-acl='{"action": "create", "resource": "entry"}'></md-menu-divider>
                
                 <md-menu-item ng-if="vm.isToday && !vm.event.patient_is_draft" mn-acl='{"action": "create", "resource": "entry"}'>         
                    <md-button ng-click="vm.createEntries('sv', $event)" aria-label="start_cons">
                        <md-icon md-font-icon="{{ vm.visitLinkConfig.icon }}" md-font-set="mdi"></md-icon>
                        <span translate-once="consultation_access"></span>
                    </md-button>
                </md-menu-item> 
                
                <md-menu-divider ng-if="vm.isToday && !vm.event.patient_is_draft" mn-acl='{"action": "get", "resource": "appointment"}'></md-menu-divider>
                
                <md-menu-item ng-if="!vm.event.all_patients_entered" mn-acl='{"action": "update", "resource": "appointment"}'> 
                    <md-button ng-click="vm.editEvent($event)" aria-label="edit">
                        <md-icon md-font-icon="mdi-pencil-box-outline" md-font-set="mdi"></md-icon>
                        <span translate-once="edit_rdv"></span>
                    </md-button>
                </md-menu-item> 
                
                <md-menu-item ng-if="!vm.event.all_patients_entered && !vm.event.is_waiting_list" mn-acl='{"action": "update", "update": "appointment"}'> 
                    <md-button ng-click="vm.toWaitingList()" aria-label="edit">
                        <md-icon md-font-icon="mdi-calendar-clock" md-font-set="mdi"></md-icon>
                        <span translate-once="waiting_list"></span>
                    </md-button>
                </md-menu-item>
                
                <md-menu-divider ng-if="!vm.event.is_entered" mn-acl='{"action": "delete", "resource": "appointment"}'></md-menu-divider>  
                
                <md-menu-item ng-if="!vm.event.is_entered" mn-acl='{"action": "delete", "resource": "appointment"}'>  
                    <md-button ng-click="vm.deleteEvent()" aria-label="delete">
                        <md-icon md-font-icon="mdi-cancel" md-font-set="mdi" class="mn-danger"></md-icon>
                        <span translate-once="appointment_cancel"></span>
                    </md-button>
                </md-menu-item>                 
            </md-menu-content>  
        </md-menu>
    `;

    class GroupRdvCalendarElementCtrl {
        constructor(visitService, frontDeskService, practiceService, mnWebSocket, $mdDialog, system, $scope, $element,
                    $mdMenu, $compile, groupAppointmentService, appointmentFormService) {
            this.element = $element;
            this.scope = $scope;
            this.mdMenu = $mdMenu;
            this.compile = $compile;

            this.dialog = $mdDialog;
            this.mnWebSocket = mnWebSocket;
            this.visitService = visitService;
            this.frontDeskService = frontDeskService;
            this.groupAppointmentService = groupAppointmentService;
            this.appointmentFormService = appointmentFormService;
            this.practiceService = practiceService;

            this.type = this.type || "";
            this.event = this.event || {};
            this.rooms = _.filter(frontDeskService.roomSubject.getValue(), {type: 'WR'});

            this.timeFormat = system['time_format'].js;
            this.dateFormat = system['date_format'].js;
            this.dateTimeFormat = system['datetime_format'].js;
        }

        static get $inject() {
            return [
                "visitService", "frontDeskService", "practiceService", "mnWebSocket", "$mdDialog", "system", "$scope",
                "$element", "$mdMenu", "$compile", "groupAppointmentService", "appointmentFormService"
            ];
        }

        get patientsLength() {
            return this.event.patients.length;
        }

        get enteredPatientsLength() {
            return this.event.patients_entered.length;
        }

        $onInit() {
            this.createdAt = moment(this.event.created_at).format(this.dateFormat);

            if (this.event.startMoment) {
                this.eventTime = `${this.event.startMoment.format(this.timeFormat)} - ${this.event.endMoment.format(this.timeFormat)}`;
                this.isToday = this.appointmentFormService.checkToday(this.event);
            }

            this.visitSubLinksSubscription = this.visitService.visitSubLinks.subscribe((data) => {
                this.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);
            });
        }

        $onChanges() {
            const handledComment = _.escape(`${this.event.other_comment || ''}${this.event.other_comment ? ' - ' : ''}${moment(this.event.created_at).format(this.dateTimeFormat)}`);
            this.element.attr('title', handledComment);

            if (this.type === 'waiting-list') {
                this.element.removeClass((index, className) => (className.match(/\b(physician|reason)-\S+/g) || []).join(' '));

                this.element.addClass(this.event['reason_class']);
                this.element.addClass(this.event['physician_class']);

                this.element.attr("data-event", this.event.id);
                this.element.data('event', this.event);
            }
        }

        $onDestroy() {
            if (this.visitSubLinksSubscription) this.visitSubLinksSubscription.unsubscribe();
        }

        // menu related
        openMenu(ev) {
            ev.stopPropagation();

            if (this.element.find('.menu-container md-menu.md-menu').length === 0) {
                const compiledDirective = this.compile(RDV_MENU_CONTAINER)
                const menuContainer = compiledDirective(this.scope);
                this.element.find('.menu-container').prepend(menuContainer);

                setTimeout(() => {
                    const scope = menuContainer.scope();
                    scope.$mdMenu.open(ev);
                }, 25);
            } else {
                const menu = this.element.find('.menu-container md-menu.md-menu');
                const scope = angular.element(menu).scope();

                scope.$mdMenu.open(ev);
            }
        }

        // menu actions
        createEntries(stat, ev, room) {
            this.groupAppointmentService.createEntries(this.event.id, ev, stat, room);
        }

        editEvent(ev) {
            this.groupAppointmentService.updateAppointment(ev, null, this.event.id);
        }

        toWaitingList() {
            this.groupAppointmentService.partialUpdateAppointment(this.event, {
                date: null,
                start_time: null,
                end_time: null,
                is_waiting_list: true,
                ignore: true
            }).then(() => {
                this.appointmentFormService.notifyCalendar(WAITING_LIST, APPOINTMENT, this.event);
            }, _.noop);
        }

        deleteEvent() {
            this.groupAppointmentService.removeAppointment(this.event)
                .then(() => {
                    this.appointmentFormService.notifyCalendar(DELETE, APPOINTMENT, this.event);
                }, _.noop);
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const agendaType = $attrs.type;

        const mainTpl = `
            <div class="layout-row">
                <div class="rdv-data flex">
                    <span class="title-block" ng-bind="vm.event.title" title="{{vm.event.title}}"></span>
                    <span class="date-block" ng-bind="vm.event.date"></span>
                    <span class="time-block" ng-bind="vm.eventTime"></span>
                    <span class="physician-block" ng-bind="vm.event.physician_name"></span>
                    <span class="reason-block" ng-bind="vm.event.reason_value"></span>
                    <span class="patient-nbr-block" translate-once="group_rdv.nbr_patients" translate-values="{length: vm.patientsLength}"></span>
                    <span class="patient-nbr-block" ng-if="vm.event.is_entered" translate-once="group_rdv.nbr_entered_patients" 
                    translate-values="{length: vm.enteredPatientsLength}"></span>
                </div>
                
                <div class="layout-column menu-container" style="max-height: 40px;">          
                    ${RDV_MENU_ELEMENT}
                    <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                    <md-icon md-font-icon="mdi-clipboard-pulse" md-font-set="mdi" ng-if="vm.event['from_medical_care']"></md-icon>
                </div>
            </div>
            
            <div class="agenda-corner" ng-style="{'background-color': vm.event.agenda_color}"></div>
            <div class="fc-event-resizer fc-event-resizer-end" ng-if="!vm.event.is_entered"></div>
        `;

        const waitingListTpl = `
            <div class="layout-row">
                <div class="waiting-item-data flex layout-column">
                    <span class="patient-block" ng-bind="vm.event.title" ng-click="vm.patientInfo($event)"></span>
                    <span class="physician-block" ng-bind="vm.event.physician_name"></span>
                    <span class="reason-block" ng-bind="vm.event.reason_value"></span>
                    <span class="date-block" ng-bind="vm.createdAt"></span>
                    <span class="patient-nbr-block" translate-once="group_rdv.nbr_patients" translate-values="{length: vm.patientsLength}"></span>
                </div>
                
                <div class="layout-column menu-container" style="max-height: 40px;">          
                    ${RDV_MENU_ELEMENT}
                    <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                    <md-icon md-font-icon="mdi-clipboard-pulse" md-font-set="mdi" ng-if="vm.event['from_medical_care']"></md-icon>
                </div>
            </div>
            <div class="agenda-corner" ng-style="{'background-color': vm.event.agenda_color}"></div>
        `;

        const rowTpl = `
            <td class="mn-list-item-picture">
                
            </td>
            <td class="fc-list-item-time fc-widget-content" ng-bind="vm.eventTime"></td>
            <td class="fc-list-item-marker fc-widget-content">
                <div class="mn-event-dot-container">
                    <span class="fc-event-dot" ng-style="{'background-color': vm.event.backgroundColor}"></span>
                </div>
            </td>
            <td class="fc-list-item-title fc-widget-content">
                <div class="mn-list-title-container">
                    <p class="mn-list-comment">
                        <a class="mn-list-title" ng-bind="vm.event.title" ng-click="vm.patientInfo($event)"></a>
                        <span ng-bind="vm.event.other_comment"></span>
                    </p>
                    
                    <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                    <mn-patient-alert patient="vm.event.patient_id" class="calendar-patient-alert"></mn-patient-alert>
                    
                    <div class="menu-container">
                        ${RDV_MENU_ELEMENT}
                    </div>
                </div>
            </td>
        `

        if (agendaType === 'listWeek' || agendaType === 'listDay') {
            return rowTpl;
        } else if (agendaType === 'waiting-list') {
            $element.addClass('fc-event mn-event mn-waiting-event');

            return waitingListTpl;
        } else {
            return mainTpl;
        }
    }

    module.exports = {
        bindings: {
            event: '<',
            type: '@'
        },
        controllerAs: "vm",
        template: tpl, // or template
        controller: GroupRdvCalendarElementCtrl,
    };

})();
