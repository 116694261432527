/**
 * Created by Amine on 26/05/2022.
 */
(function () {
    'use strict';

    class FormHtmlBlockController {
        constructor(formsService) {
            this.formsService = formsService;

            this.key = null;
            this.patient = null;
            this.formTemplate = null;

            // to emit the change event once and only once to be sure to save the consultation if it not saved yet by any other means
            this.saved = false;
        }

        static get $inject() {
            return ["formsService"];
        }

        get blockName() {
            if (_.isString(this.options)) return this.options;
            else return _.get(this.options, "section_name", "");
        }

        get isMedicalFile() {
            if (_.isString(this.options)) return false;
            else return _.get(this.options, "is_medical_file", false);
        }

        $onInit() {
            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.patient = this.patient || null;
            this.template = this.template || null;
            this.readonly = !!this.readonly ? true : !this.patient || !this.template;
            this.options = this.options || {};
            this.value = null;

            this.ngModelController.$render = () => this.renderCurrentValue();

            this.change = _.debounce(() => this.saveForm(), 500);
        }

        renderCurrentValue() {
            this.value = _.get(this.formTemplate, "id", null);

            if (!!this.ngModelController.$viewValue && this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue;
            }

            if (!this.value) {
                this.#lastOrNew();
            } else {
                this.formsService.getForm(this.value)
                    .then(data => this.#loadForm(data, true), err => {
                        if (err.status === 404) this.#lastOrNew();
                    });
            }
        }

        saveForm() {
            if (this.isMedicalFile && this.formTemplate.is_validated) {
                this.formsService.templateByPatient(this.patient, this.template.id)
                    .then(data => {
                        const oldBlock = _.clone(this.block);

                        this.formTemplate = data;
                        this.block = _.find(this.formTemplate.template.blocks, ['name', this.blockName]);

                        _.forEach(this.block.fields, field => {
                            const oldField = _.find(oldBlock.fields, ["name", field.name]);
                            if (oldField) {
                                field.value = oldField.value;
                            }
                        });

                        this.#submit()
                            .then(() => {
                                this.saved = true;
                                this.internalValueChanged();
                            });
                    });
            } else {
                this.#submit();
            }
        }

        #submit() {
            return this.formsService.saveForm(this.formTemplate)
                .then(data => {
                    this.value = data.id;
                    this.#loadForm(data);
                });
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(this.value);
            this.ngModelController.$commitViewValue();

            this.saved = true;
        }

        #lastOrNew() {
            this.formsService.templateByPatient(this.patient, this.template.id)
                .then(data => this.#loadForm(data, true));
        }

        #loadForm(data, skip = false) {
            this.formTemplate = data;
            this.block = _.find(this.formTemplate.template.blocks, ['name', this.blockName]);

            if (!skip && !this.saved) this.internalValueChanged();
        }
    }

    module.exports = {
        template: require('./form-html-block.tpl.html'),
        bindings: {
            patient: "<",
            template: "<",
            readonly: "<?",
            options: "<?"
        },
        controllerAs: "vm",
        controller: FormHtmlBlockController,
        require: {ngModelController: "ngModel"},
    };

})();