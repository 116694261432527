(function(){

    "use strict";
    module.exports = undo;


    function undo(){
        let vm = this;
        let State = require('./state');
        State.prototype.undo();
        vm.redoing = true;

    }
})();
