/**
 * Created by amine on 29/03/2017.
 */
(function () {
    'use strict';

    const {Subject} = require("rxjs");

    class PregnancyService {
        constructor($q, $http, mnWebSocket, $translate, $mdDialog, moment, system) {
            this.dateFormat = system.date_format.js;

            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
            this.moment = moment;

            this.medicalFileChanged = new Subject();
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$translate", "$mdDialog", "moment", "system"];
        }

        save(item) {
            const url = `/api/pregnancy/block/${!_.isNil(item.id) ? `${item.id}/` : ""}`;
            let deferred = this.$q.defer();

            item.ignore = true
            if (!_.has(item, 'real_delivery_date')) item.real_delivery_date = null;

            this.$http.post(url, {...item, ignore: true})
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.resolve(result.data);
                });

            return deferred.promise;
        }

        get(patient) {
            return this.ws.call("specifics.obstetric.Pregnancy.get", _.isObject(patient) ? _.pick(patient, "id") : {id: patient});
        }

        getLast(patient) {
            return this.ws.call("specifics.obstetric.Pregnancy.get_last", _.isObject(patient) ? _.pick(patient, "id") : {id: patient});
        }

        deleteItem(item, $ev) {
            const url = `/api/pregnancy/block/${item.id}/`;
            let deferred = this.$q.defer();

            const confirm = this.$mdDialog.confirm()
                .title(this.$translate.instant("pregnancy_remove_confirm"))
                .ariaLabel('remove plan confirm')
                .targetEvent($ev)
                .ok(this.$translate.instant('confirm_ok'))
                .cancel(this.$translate.instant('confirm_cancel'));

            this.$mdDialog.show(confirm)
                .then(() => {
                    this.$http.delete(url)
                        .then(() => {
                            deferred.resolve(item.id);
                        }, deferred.reject);
                }, deferred.reject);

            return deferred.promise;
        }

        getCalendar() {
            return this.ws.call("specifics.obstetric.PregnancyConfiguration.get_calendar");
        }

        configurationsList(query) {
            return this.ws.call("specifics.obstetric.PregnancyConfiguration.list", query);
        }

        saveConfiguration(config, view = "event") {
            const url = `/api/pregnancy-calendar-${view}/${!_.isNil(config.id) ? `${config.id}/` : ""}`;
            let deferred = this.$q.defer();

            if (config.time_interval.is_amenorrhea_based) config.time_interval.week_count = config.time_interval.start;
            else config.time_interval.week_count = config.time_interval.start * 4;

            this.$http.post(url, config)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.resolve(result.data);
                });

            return deferred.promise;
        }

        saveConfigurationEvent(config) {
            return this.saveConfiguration(config);
        }

        saveConfigurationMilestone(config) {
            return this.saveConfiguration(config, "milestone");
        }

        removeConfiguration(config) {
            const view = config.is_event ? 'event' : (config.is_milestone ? 'milestone' : null);
            const url = `/api/pregnancy-calendar-${view}/${!_.isNil(config.id) ? `${config.id}/` : ""}`;
            let deferred = this.$q.defer();

            this.$http.delete(url).then(result => {
                deferred.resolve(result.data);
            }, result => {
                deferred.resolve(result.data);
            });

            return deferred.promise;
        }

        getExpectedDate(detail, momentAS) {
            if (!detail || !detail.time_interval) return {start: "", end: "", expectedDate: ""};

            const start = this.moment(momentAS).add(detail.time_interval.start, 'w').format(this.dateFormat);
            const end = this.moment(momentAS).add(detail.time_interval.end, 'w').format(this.dateFormat);
            const expectedDate = this.moment(momentAS).add((detail.time_interval.start + detail.time_interval.end) / 2, 'w');

            return {start, end, expectedDate}
        }

        calculateFields(pregnancy, blockConfig = {}, blockCalendar = null) {
            if (_.isNil(pregnancy) || !pregnancy) return false;

            let momentAS, momentEPD, momentOAS;

            if (pregnancy.estimated_pregnancy_date) {
                momentEPD = this.moment(pregnancy.estimated_pregnancy_date, this.dateFormat);
                momentOAS = pregnancy.amenorrhea_start ? this.moment(pregnancy.amenorrhea_start, this.dateFormat) : momentEPD;
                momentAS = this.moment(momentEPD).subtract(_.get(blockConfig, "pregnancy_date", 2), "w");
            } else {
                momentOAS = momentAS = this.moment(pregnancy.amenorrhea_start, this.dateFormat);
            }

            pregnancy.calculated_pregnancy_date = this.moment(momentOAS).add(_.get(blockConfig, "pregnancy_date", 2), "w");

            const momentPD = pregnancy.estimated_pregnancy_date ? momentEPD : this.moment(pregnancy.calculated_pregnancy_date);
            const diff = this.moment.duration(this.moment(momentAS).diff(this.moment()));

            let months = 0;
            let allDays = 0;

            if (_.get(blockConfig, "pregnancy_term", false)) {
                const pDiff = this.moment.duration(this.moment(momentPD).diff(this.moment()));

                months = pDiff.asMonths() >= 0 ? -1 : Math.abs(_.ceil(pDiff.asMonths()));
                allDays = pDiff.asDays() >= 0 ? 0 : Math.abs(_.ceil(pDiff.asDays()));
            } else {
                months = Math.abs(_.ceil(diff.asMonths()));
                allDays = Math.abs(_.ceil(diff.asDays()));
            }

            const weeks = Math.abs(_.ceil(diff.asWeeks()));
            const days = Math.abs(_.ceil(diff.asDays())) % 7;

            pregnancy.pregnancy_year = this.moment(momentPD).format("YYYY");
            pregnancy.delivery_date = this.moment(momentAS).add(_.get(blockConfig, "delivery_date", 40), "w");
            pregnancy.post_term = pregnancy.delivery_date.isBefore(this.moment(), 'day')

            if (!_.isNaN(months)) {
                switch (months) {
                    case -1:
                        pregnancy.actual_month = this.$translate["instant"]("pregnancy_title_dates_zero");
                        break;
                    case 0 :
                        pregnancy.actual_month = this.$translate["instant"]("pregnancy_title_dates_first", {nb: months + 1});
                        break;
                    case 1 :
                        pregnancy.actual_month = this.$translate["instant"]("pregnancy_title_dates_second", {nb: months + 1});
                        break;
                    case 2 :
                        pregnancy.actual_month = this.$translate["instant"]("pregnancy_title_dates_third", {nb: months + 1});
                        break;
                    default :
                        pregnancy.actual_month = this.$translate["instant"]("pregnancy_title_dates_others", {nb: months + 1});
                        break;
                }
            }

            if (!_.isNaN(allDays)) {
                switch (allDays % 10) {
                    case 0:
                        pregnancy.actual_day = this.$translate["instant"]("pregnancy_title_dates_zero");
                        break;
                    case 1 :
                        pregnancy.actual_day = this.$translate["instant"]("pregnancy_title_dates_first", {nb: allDays});
                        break;
                    case 2 :
                        pregnancy.actual_day = this.$translate["instant"]("pregnancy_title_dates_second", {nb: allDays});
                        break;
                    case 3 :
                        pregnancy.actual_day = this.$translate["instant"]("pregnancy_title_dates_third", {nb: allDays});
                        break;
                    default :
                        pregnancy.actual_day = this.$translate["instant"]("pregnancy_title_dates_others", {nb: allDays});
                        break;
                }
            }

            if (!_.isNaN(weeks) && !_.isNaN(days)) {
                pregnancy.actual_term = this.$translate["instant"]("pregnancy_current_term_value", {
                    weeks: weeks,
                    days: days,
                });
            }

            if (blockCalendar) {
                const dirtyDetails = _.chain(pregnancy.details)
                    .uniqBy('uid')
                    .filter(detail => {
                        return !!(detail.planned_date || detail.provisional_date || !_.isNil(detail.appointment) || detail.is_done || !_.isEmpty(detail.comment));
                    })
                    .map(detail => {
                        if (detail.is_event) {
                            const {start, end, expectedDate} = this.getExpectedDate(detail, momentAS);

                            return _.assign(
                                {},
                                detail,
                                {
                                    start,
                                    end,
                                    minDate: this.minDate(start),
                                    maxDate: this.maxDate(end),
                                    expected_date: this.moment(expectedDate).format(this.dateFormat)
                                }
                            );
                        }

                        return detail;
                    })
                    .value();

                pregnancy.details = _.chain(blockCalendar)
                    .uniqBy('uid')
                    .reduce((details, config) => {
                        let currentDetail = _.find(pregnancy.details, ["uid", config.uid]);
                        let isDirty = currentDetail && !!(
                            currentDetail.planned_date ||
                            currentDetail.provisional_date ||
                            !_.isNil(currentDetail.appointment) ||
                            currentDetail.is_done ||
                            !_.isEmpty(currentDetail.comment)
                        );

                        if (config.is_event && !isDirty) {
                            const {start, end, expectedDate} = this.getExpectedDate(config, momentAS);

                            return _.concat(
                                details,
                                _.assign(
                                    {},
                                    config,
                                    {
                                        start,
                                        end,
                                        minDate: this.minDate(start),
                                        maxDate: this.maxDate(end),
                                        expected_date: this.moment(expectedDate).format(this.dateFormat)
                                    }
                                )
                            );
                        } else if (isDirty) {
                            return details;
                        } else {
                            const milestoneDate = this.moment(momentPD).add(config.time_interval.start - 1, 'M');

                            return _.concat(details, _.assign({}, currentDetail ? currentDetail : config, {
                                start: milestoneDate.format(this.dateFormat)
                            }));
                        }
                    }, [])
                    .concat(dirtyDetails)
                    .sortBy(detail => {
                        return detail.time_interval.week_count
                    })
                    .value();
            }

            return pregnancy;
        }

        minDate(date) {
            let date_;
            if (this.moment.isMoment(date)) {
                date_ = this.moment(date).subtract(1, "w").toDate();
            } else {
                date_ = this.moment(date, this.dateFormat).subtract(1, "w").toDate();
            }
            return date_;
        }

        maxDate(date) {
            let date_;
            if (this.moment.isMoment(date)) {
                date_ = this.moment(date).add(1, "w").toDate();
            } else {
                date_ = this.moment(date, this.dateFormat).add(1, "w").toDate();
            }
            return date_;
        }
    }

    module.exports = PregnancyService;
})();