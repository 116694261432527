(function(){

    "use strict";
    module.exports = redo;


    function redo(){
         let vm = this;
         let State = require('./state');
         State.prototype.redo();
         vm.redoing = true;

    }
})();
