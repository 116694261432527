(function () {
    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = loadDirective;

    function loadDirective(element) {
        let vm = this;

        let $canvas = $('.canvas', element);
        $canvas.append("<canvas></canvas>");

        let currentCanvas = new fabric.Canvas($canvas.find('canvas').get(0));

        setTimeout(function () {
            currentCanvas.setDimensions({width: $canvas.width(), height: $canvas.height()});
            vm.reset();
        }, 800);

        return currentCanvas;

    }

})();