(function () {

    'use strict';

    /*const TuiColorPicker = require('tui-color-picker');
    const TuiImageEditor = require('tui-image-editor');
    const blackTheme = require('../utils/image-editor-theme');*/


    // to replace image edition in the future

    class ImageEditorCtrl {
        constructor($element, $attrs) {
            /*this.instance = new TuiImageEditor($element.get(0), {
                includeUI: {
                    loadImage: {
                        path: $attrs.src,
                        name: 'SampleImage'
                    },
                    theme: blackTheme,
                    initMenu: 'filter',
                    menuBarPosition: 'right'
                },
                usageStatistics: false,
                selectionStyle: {
                    cornerSize: 20,
                    rotatingPointOffset: 70
                }
            });*/
        }

        static get $inject() {
            return ["$element", "$attrs"];
        }

        $onInit() {
        }

    }

    module.exports = {
        template: '',
        bindings: {
            file: "<",
        },
        controllerAs: "vm",
        controller: ImageEditorCtrl,
    };

})();
