(function () {

    'use strict';

    const MIMES = require('../json/mimes.json');
    const WEB_CAM_DIALOG = require('shared/dialogs/web-cam-dialog');
    const AUDIO_RECORD_DIALOG = require('../dialogs/audio-record-dialog');

    class DentalFileUploadDialogCtrl {
        constructor($mdDialog, uploadService, dcmService, interfacingService, $q) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.dcmService = dcmService;
            this.uploadService = uploadService;
            this.interfacingService = interfacingService;

            // fire pacs integration related
            this.firePacsIsActive = dcmService.integrationConfig.is_active;
        }

        static get $inject() {
            return ["$mdDialog", "uploadService", "dcmService", "interfacingService", "$q"];
        }

        $onInit() {
            this.files = [];
            this.context = this.context ? this.context : {};
        }

        cancel() {
            this.dialog.cancel();
        }

        unsetContext(contextName) {
            _.unset(this.context, contextName);
        }

        removePicture(index, ev) {
            ev.stopPropagation();
            this.files.splice(index, 1);
        }

        handleFiles(files) {
            this.files = _.concat(this.files, files);
        }

        getPreview(file) {
            if (file.is_folder) return MIMES.ICONS['folder'];
            else if (_.isNil(file.type)) return MIMES.ICONS['other'];
            else if (file.type === MIMES.PDF_MIME) return MIMES.ICONS['pdf'];
            else if (_.includes(file.type, 'video')) return MIMES.ICONS['video'];
            else if (_.includes(file.type, 'image')) return MIMES.ICONS['image'];
            else if (_.includes(file.type, 'audio')) return MIMES.ICONS['audio'];
            else if (_.includes(MIMES.WORD_MIMES, file.type)) return MIMES.ICONS['word'];
            else if (_.includes(MIMES.EXCEL_MIMES, file.type)) return MIMES.ICONS['excel'];
            else if (_.includes(MIMES.POWER_POINT_MIMES, file.type)) return MIMES.ICONS['power-point'];
            else if (_.includes(MIMES.ARCHIVE_MIMES, this.fileExtension(file))) return MIMES.ICONS['archive'];
            else return MIMES.ICONS['other'];
        }

        fileExtension(file) {
            return file.name.split('.').pop();
        }

        submit() {
            this.context.teeth = _.compact(this.context.teeth);
            const files = _.filter(this.files, i => !i.is_folder);

            const dcmPromises = _.chain(this.files)
                .filter('is_dcm')
                .map(i => () => this.dcmService.handleDcmInstance(this.patient, i, this.context))
                .value();

            const interfacingPromises = _.chain(this.files)
                .filter('is_interfacing')
                .map(i => () => this.interfacingService.handleInterfacingInstance(this.patient, i, this.context))
                .value();

            const firePacsPromises = _.chain(this.files)
                .filter('is_fire_study')
                .map(i => () => this.dcmService.handleFirePacsStudy(this.patient, this.context, i.study))
                .value();

            const promises = _.concat(
                dcmPromises, interfacingPromises, firePacsPromises,
                () => this.uploadService.upload(files, null, this.patient, this.context, {patient: this.patient})
            );

            this.promise = promises
                .reduce((prev, next) => prev.then(() => next()), this.$q.resolve(0))
                .then(data => this.dialog.hide(data));
        }

        recordAudio(ev) {
            this.dialog.show(_.assign({}, AUDIO_RECORD_DIALOG, {
                targetEvent: ev,
                multiple: true,
            })).then(audio => this.handleFiles(audio));
        }

        takePhoto(multi, ev) {
            this.dialog.show(_.assign({}, WEB_CAM_DIALOG, {
                targetEvent: ev,
                multiple: true,
                locals: {
                    multi, noAspect: !multi
                }
            })).then(files => this.handleFiles(multi ? files : [files]));
        }

        importInterfacing(ev) {
            this.interfacingService.importInterfacingInstance(this.patient, null, {}, ev, true)
                .then(exam => this.handleImportedInterfacingInstance(exam));
        }

        importDcm(ev) {
            this.dcmService.importDcmInstance(this.patient, null, {}, ev, true)
                .then(instance => this.handleImportedDcmInstance(instance));
        }

        importLastDcm() {
            this.promise = this.dcmService.importLastDcmInstance(this.patient, null, {}, true)
                .then(instance => this.handleImportedDcmInstance(instance));
        }

        importLastInterfacing() {
            this.promise = this.interfacingService.importLastInterfacingInstance(this.patient, null, {}, true)
                .then(exam => this.handleImportedInterfacingInstance(exam));
        }

        handleImportedDcmInstance(instance) {
            const dcmFolder = {
                is_dcm: true,
                is_folder: true,
                id: instance.id,
                name: `${instance.modality_short_description} ${instance.study_datetime}`,
            };

            this.files = _.concat(this.files, dcmFolder);
        }

        handleImportedInterfacingInstance(exam) {
            const interfacingFolder = {
                id: exam.id,
                is_folder: true,
                is_interfacing: true,
                name: `${exam.default_config_title} ${exam.acquisition_date_time}`,
            };

            this.files = _.concat(this.files, interfacingFolder);
        }

        // fire pacs related
        importFirePacsStudy(ev) {
            this.dcmService.importFirePacsStudy(this.patient, null, {}, ev, true)
                .then(study => this.handleImportedFirePacsStudy(study));
        }

        importLastFirePacsStudy() {
            this.promise = this.dcmService.importLastFirePacsStudy(this.patient, null, {}, true)
                .then(study => this.handleImportedFirePacsStudy(study));
        }

        handleImportedFirePacsStudy(study) {
            const dcmFolder = {
                id: study.ID,
                is_folder: true,
                is_fire_study: true, study,
                name: `${_.get(study, 'MainDicomTags.StudyDescription')} - ${_.get(study, 'MainDicomTags.StudyDate')}`,
            };

            this.files = _.concat(this.files, dcmFolder);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        escapeToClose: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: DentalFileUploadDialogCtrl,
        template: require("../views/dental-file-upload-dialog.tpl.html"),
    };

})();
