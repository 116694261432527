/**
 * Created by BETALOS on 18/12/2015.
 */

(function () {

    'use strict';

    const FILE_UPDATE_DIALOG = require('../dialogs/file-update-dialog');

    MnFileItemCtrl.$inject = ["$scope", "$element", "$compile", "uploadService", "$timeout", "$mdConstant", "configService", "$mdDialog", "$auth"];

    function MnFileItemCtrl($scope, $element, $compile, uploadService, $timeout, $mdConstant, configService, $mdDialog, $auth) {
        let vm = this;

        let $inputElem = null;
        let childScope = null;
        let initialFileName = null;

        vm.$onInit = init;
        vm.$postLink = postLink;
        vm.$onChanges = changes;

        vm.starEdit = starEdit;
        vm.cancelEdit = cancelEdit;


        function init() {
            vm.isDental = configService.isDental();
            vm.isImage = _.includes(vm.file.mime, 'image');

            vm.parent = _.isUndefined(vm.isParent()) ? false : vm.isParent();
            $element.attr('data-file', vm.file.id);
        }

        function changes(attrs) {
            const file = _.get(attrs, "file.currentValue", false);
            const showType = _.get(attrs, "showType.currentValue", false);

            if (file) {
                vm.imgUrl = file['has_thumbnail'] ? getThumbnailUrl(file) : file.icon;
            }

            else if (showType) postLink();
        }

        function postLink() {
            let cls = "";
            let tpl = "";

            switch (vm.showType) {
                case "column":
                    cls = 'list-item';
                    tpl = require(`stand-alone/file-manager/views/list-item.html`);
                    break;
                case "tag":
                    cls = 'tag-item';
                    tpl = require(`stand-alone/file-manager/views/tag-item.html`);
                    break;
                default:
                    cls = 'grid-item';
                    tpl = require(`stand-alone/file-manager/views/grid-item.html`);
            }

            $element.removeClass('grid-item list-item');
            if (!_.isNull(childScope)) childScope.$destroy();

            childScope = $scope.$new();
            let compiledDirective = $compile(tpl);
            let directiveElement = compiledDirective(childScope);

            $element.addClass(cls);
            $element.html(directiveElement);

            if (_.eq(vm.file.mime, 'folder')) $('md-card, md-list-item', $element).attr('data-file', vm.file.id);
            if (vm.parent) $('md-card, md-list-item', $element).addClass('parent-folder');

            $inputElem = $('input', $element);

            $inputElem
                .on("keydown", keyDown)
                .on("keypress", stopPropagation)
                .on("focusout", handleUpdate)
                .on('click', inputFocus);

            if (vm.file.isNew) starEdit();
        }

        function keyDown(event) {
            stopPropagation(event);
            if (event.keyCode === $mdConstant.KEY_CODE.ENTER) $inputElem.trigger("focusout");
        }

        function stopPropagation(event) {
            event.stopPropagation();
        }

        function handleUpdate(event) {
            if ($(event.relatedTarget).is('.mdi-window-close')) return;
            else update();
        }

        function inputFocus(event) {
            stopPropagation(event);

            let $elem = $(this);
            if ($elem.is(":focus")) return;

            $timeout(() => {
                $elem.select();
            });
        }

        function starEdit(ev) {
            if (vm.isDental || vm.useEditDialog) {
                const dialog = _.assign({}, FILE_UPDATE_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        file: _.cloneDeep(vm.file),
                        filters: _.cloneDeep(vm.filters)
                    }
                });

                $mdDialog.show(dialog).then(file => {
                    if ($element.is('[afterEdit]')) vm.file = vm.afterEdit({file: file});
                    else vm.file = file;
                }, _.noop);
            }
            else {
                vm.editMode = true;
                initialFileName = vm.file.name;
                $inputElem.trigger('click');
            }
        }


        function update() {
            _.unset(vm.file, 'isNew');

            uploadService.updateFile(vm.file)
                .then(success);

            function success(file) {
                vm.editMode = false;
                initialFileName = null;
                vm.file = file;
            }
        }

        function cancelEdit() {
            vm.file.name = initialFileName;
            initialFileName = null;
            vm.editMode = false;
        }

        function getThumbnailUrl(file) {
            return `/api/upload/${file.id}/thumbnail/?auth=${$auth.getToken()}`;
        }
    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            file: '<',
            filters: '<',
            showType: '<',
            remove: '&removeFile',
            openFile: '&openFile',
            download: '&downloadFile',
            afterEdit: '&afterEdit',
            isParent: '&',
            useEditDialog: '<editDialog',
        },
        template: '',
        controller: MnFileItemCtrl,
    };

})();
