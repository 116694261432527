/**
 * Created by BETALOS on 22/08/2016.
 */
(function () {

    "use strict";

    module.exports = resetCanvas;

    function resetCanvas(){
        let vm = this;

        vm.currentCanvas.off('mouse:down');
        vm.currentCanvas.defaultCursor = 'default';
        vm.currentCanvas.hoverCursor = 'pointer';

        // disable the drawing
        if(vm.active === 'drawing'){
            vm.active = 'nono';
            vm.currentCanvas.isDrawingMode = !vm.currentCanvas.isDrawingMode;
        }
    }


})();