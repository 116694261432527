/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    module.exports = customContactService;

    customContactService.$inject = ["$http", "$q"];

    function customContactService($http, $q) {
        var self = this;

        self.getContact = getContact;
        self.addContact = addContact;
        self.removeContact = removeContact;

        function getContact(id) {
            var deferred = $q.defer();

            $http.get(`/api/custom-contact/${id}/`)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function addContact(contact) {
            var deferred = $q.defer();
            var url = '/api/custom-contact/';

            if(_.has(contact, 'id')) url += contact.id + '/'

            $http.post(url, contact)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function removeContact(id) {
            return $http.delete(`/api/custom-contact/${id}/`);
        }
    }

})();