/**
 * Created by BilDev on 15/07/2017.
 */

(function () {

    'use strict';
    module.exports = contrast;

    function contrast(){
        let vm = this;

        vm.resetCanvas();


        applyFilterValue(6, 'contrast', vm.contraste, vm.currentCanvas.item(0), vm.currentCanvas);

    }

    function applyFilterValue(index, prop, value, obj, canvas) {

        if (obj.filters[index]) {
            obj.filters[index][prop] = value;
            obj.applyFilters();
            canvas.renderAll();
        }
    }
})();


