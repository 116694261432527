(function () {
    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = cropImage;

    function cropImage() {
        let vm = this;
       // vm.resetCanvas();
        console.log("crop image");
        let fabricCanvas = vm.currentCanvas;
        console.log(fabricCanvas);

        let object = vm.currentCanvas.item(0);
        if (object) {

            let mousex = 0;
            let mousey = 0;
            let crop = false;
            let disabled = false;
            let el = new fabric.Rect({
                fill: 'transparent',
                originX: 'left',
                id: "123",
                originY: 'top',
                stroke: '#000',
                strokeDashArray: [2, 2],
                opacity: 1,
                width: 1,
                height: 1
            });
            fabricCanvas.add(el).renderAll();

            object.id = '456';
            object.set('selectable', false);
            fabricCanvas.on("mouse:down", function (event) {
                if (disabled) return;
                el.left = event.e.pageX;
                el.top = event.e.pageY ;
                //el.selectable = false;
                el.visible = true;
                mousex = event.e.pageX;
                mousey = event.e.pageY;
                crop = true;
                fabricCanvas.bringToFront(el);
            });
            fabricCanvas.on("mouse:move", function (event) {
                if (crop && !disabled) {
                    if (event.e.pageX - mousex > 0) {
                        el.width = event.e.pageX - mousex;
                    }
                    if (event.e.pageY - mousey > 0) {
                        el.height = event.e.pageY - mousey;
                    }
                }
            });
            fabricCanvas.on("mouse:up", function (event) {
                crop = false;
            });
        }
        else {
            alert("Please Select an image");
        }



    }

})();