/**
 * Created by Betalos on 27/06/2017.
 */
(function () {
    'use strict';

    const CalendarService = require('./services/calendarService');

    const calendar = require('./components/calendar').default;
    const rdvCalendarElement = require('./components/rdv-calendar-element');
    const groupRdvCalendarElement = require('./components/group-rdv-calendar-element');
    const pauseCalendarElement = require('./components/pause-calendar-element');
    const unavailableCalendarElement = require('./components/unavailable-calendar-element');


    module.exports = {
        calendar,
        rdvCalendarElement,
        pauseCalendarElement,
        unavailableCalendarElement,
        groupRdvCalendarElement,

        CalendarService
    };

})();
