/**
 * Created by BETALOS on 22/08/2016.
 */
(function () {
    'use strict';

    const fabric = require("fabric-browseronly").fabric;

    module.exports = reset;

    function reset() {
        let vm = this;

        console.log("reset fonction caled");

        vm.currentCanvas.clear();
        vm.currentCanvas.setZoom(1);

        fabric.Image.fromURL(vm.url, loadImg);

        function loadImg(oImg) {
            let dimensions = vm.dimCalculate(oImg);

            let imgConfig = _.assign(dimensions, {
                originX: 'center',
                originY: 'center',
                hasControls: false,
                selectable: false,
                hasBorders: false,
                lockUniScaling: true,
                lockMovementX: true,
                lockMovementY: true

            });

            vm.currentCanvas.setDimensions(dimensions);
            oImg.set(imgConfig);

            // adding image filter :
          /*  applyFilter(6, new fabric.Image.filters.Contrast({contrast: vm.contraste}),oImg,vm.currentCanvas);

            // luminosite
            applyFilter(5, new fabric.Image.filters.Brightness({brightness: vm.luminosite}),oImg,vm.currentCanvas);*/


            vm.currentCanvas.add(oImg);
            vm.currentCanvas.centerObject(oImg);
            vm.currentCanvas.renderAll();
        }

       /* function  applyFilter(index, filter, obj, canvas) {
            obj.filters[index] = filter;
            obj.applyFilters();
            canvas.renderAll();
        }*/





    }


})();