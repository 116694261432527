(function () {

    'use strict';

    const VISUALIZE_FILE_DIALOG = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    class DcmInstancesDialogCtrl {
        constructor($mdDialog, $timeout, dcmService) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.dcmService = dcmService;

            this.selectExam = (instance) => this.selectExamFunc(instance);
        }

        static get $inject() {
            return ["$mdDialog", "$timeout", "dcmService"];
        }

        $onInit() {
            this.preSave = this.preSave ? this.preSave : null;
            this.resolve = this.resolve ? this.resolve : false;

            this.actions = {
                multiple: [],
                single: [
                    {
                        action: 'get',
                        resource: 'dcm',
                        icon: 'mdi-file',
                        behavior: 'disable',
                        label: 'visualize_joined_file',
                        method: (item, $event) => this.visualize(item, $event)
                    }
                ]
            };
        }

        cancel() {
            this.dialog.cancel();
        }

        visualize(item, $event) {
            $event.stopPropagation();

            if (item.files.length === 0) return;

            const dialog = _.assign({}, VISUALIZE_FILE_DIALOG, {
                targetEvent: $event,
                locals: {files: _.filter(item.files, 'is_converted'), fileIndex: 0, showPreview: false}
            });

            this.dialog.show(dialog);
        }

        selectExamFunc(instance) {
            if (this.resolve) this.dialog.hide(instance);
            else if (!_.isNil(this.preSave)) this.promise = this.preSave().then(() => this.startSave(instance));
            else this.startSave(instance);
        }

        startSave(instance) {
            this.$timeout(() => {
                this.promise = this.dcmService.handleDcmInstance(this.patient, instance, this.context)
                    .then(this.dialog.hide);
            }, 200);
        }

        clearQuery() {
            this.query = {};
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: DcmInstancesDialogCtrl,
        template: require("./dcm-instances-dialog.tpl.html"),
    }

})();
