/**
 * Created by amine on 12/04/2022.
 */

(function () {
    'use strict';

    //const CLASSIFICATION_DIALOG = require('../../dialogs/osiics-classification-dialog/osiics-classification-dialog');

    class CustomClassificationFieldCtrl {
        constructor(classificationsService, $translate, $element, $q, $mdDialog) {
            this.classificationsService = classificationsService;
            this.$translate = $translate;
            this.$q = $q;
            this.$element = $element;
            // this.dialog = $mdDialog;

            this.requireMatch = true;
        }

        static get $inject() {
            return ['classificationsService', '$translate', "$element", "$q", "$mdDialog"];
        }

        $onInit() {
            this.search = "";

            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.ngModelController.$render = () => this.renderCurrentValue();

            this.classification = this.classification || {};
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.internalValue) {
                this.internalValue = this.ngModelController.$viewValue;
            }

            this.internalValue = this.internalValue || [];
        }

        emptyField() {
            this.internalValue = [];
            this.change();
        }

        change() {
            let viewValue = _.cloneDeep(this.internalValue);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        getData(value) {
            return this.classificationsService.customClassificationSearch(value, this.classification.name);
        }

        transformChip(chip) {
            if (_.isObject(chip)) {
                return _.pick(chip, ['id', 'code', 'value']);
            } else {
                return false
            }
        }

        // classificationDialog(ev) {
        //     this.dialog.show(_.assign({}, CLASSIFICATION_DIALOG, {
        //         targetEvent: ev,
        //         locals: {},
        //     })).then(data => {
        //         data.forEach(item => {
        //             _.pushOrUpdate(this.internalValue, this.transformChip(item));
        //         });
        //         this.change();
        //     }, _.noop);
        // }

    }

    module.exports = {
        bindings: {
            classification: '<',
            readonly: '<?'
        },
        controllerAs: "vm",
        controller: CustomClassificationFieldCtrl,
        require: {ngModelController: "ngModel"},
        template: require('./custom-classification-field.tpl.html'),
    };

})();