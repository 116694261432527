(function () {

    'use strict';

    const ImageComparison = require('image-comparison');

    class ImageComparisonCtrl {
        constructor($element, $auth) {
            this.$auth = $auth;

            this.urlPath = null;
            this.container = $('.js-comparison-container', $element);
        }

        static get $inject() {
            return ['$element', '$auth'];
        }

        $onInit() {
        }

        $onChanges(changeObj) {
            let files = _.get(changeObj, 'files.currentValue', []);

            if (files.length < 2) return;
            else {
                this.container.empty();

                let data = _.map(files, i => {
                    return {image: this.handleImage(i), label: i.name}
                });

                this.instance = new ImageComparison({
                    container: this.container.get(0),
                    startPosition: 50, data
                });
            }
        }

        handleImage(file) {
            let url = null;
            let $image = null;

            if (_.has(file, 'url')) url = `${file.url}${_.includes(file.url, "?") ? "&auth=" : "?auth="}${this.$auth.getToken()}`;
            else url = `${this.urlPath}${file.id}/data/?auth=${this.$auth.getToken()}`;

            if (file.isBase64) $image = $('<img />', {src: file.content});
            else $image = $('<img />', {src: url});

            this.container.append($image);

            return $image.get(0);
        }
    }

    module.exports = {
        bindings: {
            files: "<",
            urlPath: "=",
        },
        controllerAs: "vm",
        controller: ImageComparisonCtrl,
        template: `
            <div class="js-comparison-container" ng-hide="vm.files.length < 2"></div>
            <div class="comparison-label" ng-if="vm.files.length < 2">
                <h2 translate-once="file_manager_image_comparison_please_select_image"></h2>
            </div>
        `
    };

})();
