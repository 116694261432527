(function () {

    'use strict';

    class ChoiceListCtrl {
        constructor(blockService) {
            this.service = blockService;

            this.list = this.list || [];
            this.field = this.field || {};
        }

        static get $inject() {
            return ["blockService"];
        }

        $onInit() {
            const listKey = _.get(this.field, 'meta_data.list_key', false);

            if (_.get(this.field, 'meta_data.list_key', false)) {
                this.service.choicesSubject.subscribe(data => {
                    let field = _.find(data, ["key", listKey]);

                    this.choices = _.get(field, 'meta_data.choices', []);
                    this.isMultiple = _.get(field, 'meta_data.is_multiple', false);
                });
            } else {
                this.choices = _.get(this.field, 'meta_data.choices', []);
                this.isMultiple = _.get(this.field, 'meta_data.is_multiple', false);
            }

            this.readOnly = _.isFunction(this.readOnly) ? this.readOnly() : false;
            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        selectItem(choice) {
            if (this.isSelected(choice)) _.remove(this.list, choice);
            else this.isMultiple ? this.list = _.concat(this.list, choice) : _.castArray(choice);

            this.triggerChange();
        }

        isSelected(choice) {
            return _.findIndex(this.list, choice) !== -1;
        }

        emptyField() {
            this.list = [];
            this.triggerChange();
        }

        triggerChange() {
            const clonedList = _.cloneDeep(this.list);

            this.ngModelController.$setViewValue(clonedList, 'change');
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.list) {
                this.list = this.ngModelController.$viewValue || [];
            }
        }

    }


    module.exports = {
        bindings: {
            field: "<",
            labelColor: "@?",
            readOnly: "&?readonly",
        },
        require: {
            ngModelController: 'ngModel'
        },
        controllerAs: "vm",
        controller: ChoiceListCtrl,
        template: require('../views/choice-list.tpl.html'), // or template
    };

})();
