/**
 * Created by Amine on 12/11/2021.
 */
(function () {

    'use strict';

    const DRAG_BAG = "field-bag";
    const FIELDS_PARAMS = require('parameters/json/patient-fields-params.json');

    class CustomFieldsSetupTableCtrl {
        constructor($mdDialog, $translate, dragulaService, $scope) {
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.dragulaService = dragulaService;
            this.scope = $scope;
            this.ngModelController = this.ngModelController || null;

            this.change = _.mnDelay(() => this._change(), 400);

            this.fields = [];
            this.types = FIELDS_PARAMS.types;
            this.widths = FIELDS_PARAMS.widths;
            this.dateFieldTypes = FIELDS_PARAMS.dateFieldTypes;
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "dragulaService", "$scope"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();

            this.dragulaService.options(this.scope, DRAG_BAG, {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.fields)
                this.fields = this.ngModelController.$viewValue;
        }

        removeField(index, ev) {
            let confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.translate['instant']('confirm_ok'))
                .cancel(this.translate['instant']('confirm_cancel'))
                .title(this.translate['instant']('patient_file_setup.custom_field_remove_confirm', this.fields[index]));


            this.dialog.show(confirm).then(() => this.fields.splice(index, 1));
        }

        handleSlug(field) {
            if (_.isEmpty(field.slug)) field.slug = _.snakeCase(field.label);
        }

        customSelects(field) {
            return _.filter(this.fields, item => item.slug !== field.slug && item.type === "custom-select");
        }
    }

    module.exports = {
        bindings: {},
        require: {ngModelController: "ngModel"},
        template: require("./custom-fields-table.tpl.html"),
        controllerAs: "vm",
        controller: CustomFieldsSetupTableCtrl,
    };

})();
