
(function () {
    'use strict';

    class hurService {
        constructor($q, $http, mnWebSocket ) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket" ];
        }

        createHurOrder(patient_id) {
            return this.ws.call("specifics.HurExam.handle_hur_entry", {patient_id});
        }
        getTrainingPrograms(patient_id) {
            return this.ws.call("specifics.HurExam.get_trainings", {patient_id});
        }
        get_training_visits(patient_id,training_filter){
            return  this.ws.call("specifics.HurExam.get_training_visits", {patient_id, training_filter});
        }
    }

    module.exports = hurService;
})();