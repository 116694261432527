/**
 * Created by BETALOS on 16/03/2016.
 */
(function () {

    'use strict';

    module.exports = listService;

    listService.$inject = ["mnWebSocket", "configService", "$mdDialog", "$translate", "$http", "$q"];

    function listService(mnWebSocket, configService, $mdDialog, $translate, $http, $q) {
        let self = this;

        self.get = get;
        self.list = list;
        self.handleList = handleList;
        self.reorderList = reorderList;
        self.autocompleteListSearch = autocompleteListSearch;

        self.getListsModels = getListsModels;

        self.removeItem = removeItem;

        self.postOnInit = postOnInit;

        function get(item) {
            let id = _.get(item, "id", item);
            return mnWebSocket.call("shared.List.get", {id: id})
        }

        function list(model_name, is_hidden, extra_query) {
            return mnWebSocket.call('shared.List.list', {model_name, is_hidden, extra_query})
        }

        function handleList(item) {
            return mnWebSocket.call('shared.List.handle_list', item);
        }

        function reorderList(items, model) {
            return mnWebSocket.call('shared.List.reorder', {items: items, model_name: model});
        }

        function autocompleteListSearch(model_name, query, fill, fill_by) {
            fill = _.isNil(fill) ? false : fill
            return mnWebSocket.call('shared.List.autocomplete_list', {
                model_name: model_name,
                query: query,
                fill: fill,
                fill_by: fill_by
            });
        }

        function removeItem(list, event) {
            let deferred = $q.defer();

            let confirm = $mdDialog.confirm()
                .title($translate['instant']('list_remove_confirm', list))
                .ariaLabel('remove plan confirm')
                .targetEvent(event)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                let url = '/api/list/' + list.id + '/';

                $http.delete(url)
                    .then(deferred.resolve);
            }

            return deferred.promise;
        }

        function postOnInit(model, options) {
            switch (model) {
                case "CustomList":
                    if (options.parent_slug) {
                        return {
                            has_parent: true,
                            parent_field: _.find(options.fields, ['slug', options.parent_slug])
                        }
                    } else {
                        return {has_parent: false};
                    }
                default:
                    return {};
            }

        }

        function getListsModels(tpl = false) {
            const result = _.reduce(require('parameters/json/list-models.json'), (acc, item) => {
                if (_.isString(item)) {
                    acc.models = _.concat(acc.models, item);
                } else {
                    if (_.has(item, 'active_modules')) {
                        if (configService.checkActiveModule(item.active_modules)) {
                            acc.models = _.concat(acc.models, item.value);
                        }
                    } else {
                        acc.models = _.concat(acc.models, item.value);
                    }

                    if (item.has_custom_template) acc.templates.push(item.value);
                }

                return acc;
            }, {models: [], templates: []});

            return tpl ? result : result.lists;
        }
    }

})();