(function () {

    const ROUTES = (ctx => {
        let keys = ctx.keys();
        let values = keys.map(ctx);
        return keys.reduce((o, k, i) => _.concat(o, values[i]), []);
    })(require.context('../../', true, /^.*route.json$/));

    const HTMLS = (ctx => {
        let keys = ctx.keys();
        let values = keys.map(ctx);
        return keys.reduce((o, k, i) => _.assign(o, {[k.replace('./', '')]: values[i]}), {});
    })(require.context('../../', true, /^.*\.(html|svg)$/));


    exports.HTMLS = HTMLS;
    exports.ROUTES = ROUTES;

})();