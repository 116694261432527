(function () {
    'use strict';
    module.exports = drawing;

    function drawing(){
        let vm = this;

        vm.active = (vm.active === 'drawing' ? 'none' : 'drawing');
        vm.draw = !vm.draw;
        vm.currentCanvas.off('mouse:down');

        vm.currentCanvas.isDrawingMode = !vm.currentCanvas.isDrawingMode;
        vm.currentCanvas.freeDrawingBrush.color = vm.drawingParams.col;
        vm.currentCanvas.freeDrawingBrush.width = vm.drawingParams.size;

    }

})();