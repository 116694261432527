/**
 * Created by amine on 20/08/2021.
 */

(function () {

    'use strict';

    class ClassificationTreeCtrl {
        constructor($scope, $element) {
            this.scope = $scope;
            this.element = $element;
            this.ngModelController = this.ngModelController || null;

            this.internalValue = [];
        }

        static get $inject() {
            return ["$scope", "$element"];
        }

        $onInit() {
            this.key = this.key || "items";
            this.tree = this.tree || [];
            this.tags = this.tags || [];

            if (this.ngModelController) this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.internalValue && _.isArray(this.ngModelController.$viewValue)) {
                this.internalValue = this.ngModelController.$viewValue;
            }
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            // const item = _.find(this.initItems, ['id', _.get(this.internalValue, 'id', null)]);
            // this.items = _.chain(items).pushOrUpdate(item).compact().uniqBy('id').sortBy('order').value();
        }

        checkToggled(item) {
            this.scope.$applyAsync(() => {
                if (item.isChecked) {
                    this.internalValue.push(_.omit(item, ['isChecked']));
                } else {
                    this.internalValue = _.remove(this.internalValue, ['id', item.id]);
                }

                this.internalValueChanged();
            })
        }

        toggleGroup(event) {
            $(event.currentTarget).toggleClass("opened").toggleClass("closed");
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, attrs) {
        // const ngRequired = $elem.is("[ng-required]") ? 'ng-required="vm.required"' : "";
        const rootsOpened = $element.is("[roots-opened]");
        const hasNgModel = $element.is("[ng-model]");
        const isInner = $element.is("[inner]");
        const valueItem = `<span class="classification-item__code flex-none" ng-if="item.code">
                                    {{ item.code }}
                                </span>
                                <span class="classification-item__value flex">
                                        {{ item.value }}
                                </span>
                                <md-tooltip md-direction="bottom" md-delay="150">
                                    <span ng-bind="item.value"></span>
                                </md-tooltip>
                                <span class="mn-badge flex-nogrow" ng-repeat="tag in vm.tags track by $index" 
                                        ng-if="!!item[tag]" ng-bind="item[tag]">
                                </span>`;
        const valueSpan = `<span class="layout-row classification-item">${valueItem}</span>`;
        const innerHtml = `
            <ul class="layout-column flex" md-virtual-repeat-container > 
                <li md-virtual-repeat="item in vm.tree" ng-class="{leaf: !item[vm.key]}" class="flex-noshrink layout-column classification-item-container">
                    <div ng-if="item[vm.key]" class="layout-column layout-fill flex-noshrink">
                        <span class="flex-nogrow classification-group ${rootsOpened ? 'opened' : 'closed'}" ng-if="item[vm.key]"
                            ng-click="vm.toggleGroup($event)">
                            <md-icon class="classification-group__icon" md-font-icon="mdi-play" md-font-set="mdi"></md-icon>
                            ${valueSpan}
                        </span>
                        <mn-classification-tree class="layout-column flex-noshrink"
                                ${hasNgModel ? 'ng-model="vm.internalValue"' : ''} tree="item[vm.key]" inner
                                highlight-text="vm.highlightText" tags="vm.tags">
                        </mn-classification-tree>
                    </div>
                    ${hasNgModel ? `<md-checkbox class="flex" aria-label="check" ng-if="!item[vm.key]" ng-model="item.isChecked"
                    ng-change="vm.checkToggled(item)" ng-model-options="{ debounce: 400 }">${valueSpan}</md-checkbox>` :
            `<span class="layout-row classification-item" ng-if="!item[vm.key]">${valueItem}</span>`}
                </li>
            </ul>
        `;

        ``;
        if (isInner) {
            return innerHtml;
        } else {
            return `<div class="classification-tree layout-column">
                    ${innerHtml}      
                </div>`;
        }

    }

    module.exports = {
        template: tpl,
        bindings: {
            tree: "<",
            key: "@",
            tags: "<?"
        },
        controllerAs: "vm",
        controller: ClassificationTreeCtrl,
        require: {ngModelController: "?ngModel"}
    };

})();

/*

 */