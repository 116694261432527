/**
 * Created by amine on 08/09/2020.
 */
(function () {

    'use strict';


    class GrowthChartsDialog {
        constructor(gxpService, $mdDialog, $element, $http) {
            this.gxpService = gxpService;
            this.element = $element;
            this.dialog = $mdDialog;
            this.http = $http;

            this.fullscreen = false;
            this.promise = null;
        }

        static get $inject() {
            return ["gxpService", "$mdDialog", "$element", "$http"];
        }

        $onInit() {
            this.element.addClass('growth-charts-container');
            this.patient = this.patient || null;

            this.promise = this.gxpService.loadData("growthChartFrame", this.patient, this.element);
        }

        $onDestroy() {
            this.gxpService.unloadData();
        }

        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
            this.element.toggleClass('fullscreen-dialog', this.fullscreen);
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: GrowthChartsDialog,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        template: require("../views/growth-charts.tpl.html"),
    };

})();

