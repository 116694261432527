(function () {
    'use strict';

    module.exports = flip;

    function flip(val, currentCanvas){
        let vm = this;

        vm.resetCanvas(currentCanvas);

        vm.currentCanvas.forEachObject(function(obj){
            if(obj.type === 'image' ){
                if(val === "X"){
                    obj.flipX = !obj.flipX;
                }
                if(val === "Y"){
                    obj.flipY = !obj.flipY;
                }
            }
        });

        vm.currentCanvas.renderAll();
    }

})();