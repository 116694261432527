/**
 * Created by amine on 09/07/2021.
 */


(function () {
    'use strict';

    const OSIICS_LS_KEY = "osiics#13.5"

    class ClassificationsService {
        #osiicsClassification;

        constructor($q, $http, mnWebSocket) {
            this.q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;

            // this.#osiicsClassification = JSON.parse(localStorage.getItem(OSIICS_LS_KEY)) || [];
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket"];
        }

        load() {
            // if (this.#osiicsClassification.length > 0) {
            //     return this.q.when(true);
            // } else {
            //     return this.#all("OrchardClassification")
            //         .then(data => {
            //             this.#osiicsClassification = _.chain(data)
            //                 .groupBy("body_part_name")
            //                 .reduce((acc, items, bodyPart) => {
            //                     return _.concat(acc, {
            //                         id: bodyPart.toUpperCase(),
            //                         value: bodyPart,
            //                         items
            //                     });
            //                 }, [])
            //                 .value();
            //
            //             localStorage.setItem(OSIICS_LS_KEY, JSON.stringify(this.#osiicsClassification));
            //
            //             return true;
            //         }, _.noop);
            // }
        }

        icdSearch(value) {
            return this.#search(value, "ICD");
        }

        osiicsSearch(value) {
            return this.#search(value, "OrchardClassification");
        }

        osiicsClassification(query) {
            return this.#all("OrchardClassification", query)
        }

        customClassificationSearch(value, classification) {
            return this.#search(value, "CustomClassification", {classification});
        }

        customClassification(query) {
            return this.#all(query, "CustomClassification");
        }

        #search(value, event, extra = null) {
            return this.ws.call(`specifics.diagnostic.${event}.search`, {value, extra});
        }

        #all(event, query = {}) {
            return this.ws.call(`specifics.diagnostic.${event}.all`, query);
        }
    }

    module.exports = ClassificationsService;
})();