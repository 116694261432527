/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    module.exports = contactService;

    contactService.$inject = ["$http", "$q"];

    function contactService($http, $q) {
        var self = this;

        self.getContacts = getContacts;
        self.addContact = addContact;
        self.getFullContact = getFullContact;
        self.removeContact = removeContact;

        function getContacts() {
            var deferred = $q.defer();

            $http.get('/api/referring-contact/')
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function addContact(contact) {
            var deferred = $q.defer();
            var url = '/api/referring-contact/';

            if(_.has(contact, 'id')) url += contact.id + '/'

            $http.post(url, contact)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getFullContact(contact) {
            var deferred = $q.defer();
            var url = _.format('/api/referring-contact/{0}/', contact.id)

            $http.get(url)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function removeContact(contact) {
            var url = _.format('/api/referring-contact/{0}/', contact.id)
            return $http.delete(url);
        }
    }

})();