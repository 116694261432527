/**
 * Created by Amine on 05/04/2020.
 */
(function () {

    'use strict';

    module.exports = getObjectId;

    function getObjectId() {
        const now = new Date();
        const timestamp = (now.getTime() / 1000 | 0).toString(16);
        const baseStr = 'xxxxxxxxxxxxxxxx';
        const rndSTr = baseStr.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase();

        return `${timestamp}${rndSTr}`;
    }

})();