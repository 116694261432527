/**
 * Created by amine on 13/04/2018.
 */
(function () {

    'use strict';

    module.exports = {
        controller: VaccineFormDialogController,
        controllerAs: "vm",
        template: require("../views/vaccine-form.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true,
        multiple: true
    };

    VaccineFormDialogController.$inject = ["$mdDialog", "vaccinationService"];

    function VaccineFormDialogController($mdDialog, vaccinationService) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function init() {
        }

        function submit() {
            vaccinationService.saveVaccine(vm.vaccine)
                .then($mdDialog.hide)
        }
    }

})();
