(function () {

    'use strict';

    class DcmOrderDialogCtrl {
        constructor($mdDialog, patientService, dcmService, system) {
            this.dialog = $mdDialog;
            this.dcmService = dcmService;
            this.patientService = patientService;

            let timeFormat = system['time_format'].js;
            let dateFormat = system['date_format'].js;
            this.order = {
                scheduled_date: moment().format(dateFormat),
                scheduled_time: moment().format(timeFormat),
            }
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "dcmService", "system"];
        }

        $onInit() {
            this.patient_id = this.patient_id ? this.patient_id : null;

            this.promise = this.patientService.getMinimalPatient(this.patient_id)
                .then(patient => {
                    this.order.patient = patient;
                    this.patientName = patient['full_name'];
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.dcmService.saveDcmOrder(this.order)
                .then(data => this.dialog.hide(data));

            return this.promise;
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DcmOrderDialogCtrl,
        template: require("./dcm-order-dialog.tpl.html"),
    };

})();
