/**
 * Created by amine on 21/01/2020.
 */
(function () {

    'use strict';

    class ClassificationDialogController {
        #preLoading

        constructor($mdDialog, classificationsService, listService, $q) {
            this.dialog = $mdDialog;
            this.q = $q;
            this.classificationsService = classificationsService;
            this.listService = listService;

            this.search = {
                key: "",
                bodyTissue: null,
                orchardPathology: null
            }
            this.groups = [];
            this.initGroups = [];
            this.list = [];
            this.filteredList = [];
            this.selected = [];
            this.selectedGroup = null;

            this.#preLoading = {}
        }

        static get $inject() {
            return ["$mdDialog", "classificationsService", "listService", "$q"];
        }

        $onInit() {
            this.promise = this.listService.list("BodyPart")
                .then(data => {
                    this.selected = [];
                    this.initGroups = data;
                    this.selectGroup(data[0]);
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(_.clone(this.selected));
        }

        selectGroup(group) {
            this.selectedGroup = group;
            this.promise = this.getClassificationItems(group)
                .then(data => {
                    this.list = data;
                    this.filteredList = _.cloneDeep(this.list);

                    this.filterItems();
                }, _.noop);
        }

        getClassificationItems(group) {
            const deferred = this.q.defer();

            if (_.has(this.#preLoading, group.id)) {
                console.log("preloaded");
                deferred.resolve(this.#preLoading[group.id]);
            } else {
                this.classificationsService.osiicsClassification({"body_part": {"$eq": group.id}})
                    .then(data => {
                        this.#preLoading[group.id] = data;
                        deferred.resolve(data);
                    });
            }

            return deferred.promise;
        }

        filterItems() {
            if (this.search.key && this.search.key.length > 2 || this.search.bodyTissue || this.search.orchardPathology) {
                const keys = _.chain(this.search.key.replace(/\s+/, " "))
                    .toLower()
                    .deburr()
                    .value()
                    .split(" ");

                const re = new RegExp(`.*(${keys.join('|')}).*`, "gi");

                this.filteredList = _.chain(this.list)
                    .cloneDeep()
                    .filter(item => {
                        const value = _.chain(item).get("value").toLower().deburr().value();
                        const code = _.chain(item).get("code").toLower().deburr().value();

                        return !!value.match(re) || !!code.match(re);
                    })
                    .filter(item => {
                        if (this.search.bodyTissue) {
                            const bodyTissueKey = _.chain(this.search.bodyTissue).get("value").toLower().deburr().value();
                            const bodyTissue = _.chain(item).get("tissue_name").toLower().deburr().value();

                            return bodyTissue === bodyTissueKey
                        } else {
                            return true;
                        }
                    })
                    .filter(item => {
                        if (this.search.orchardPathology) {
                            const orchardPathology = _.chain(item).get("pathology_name").toLower().deburr().value();
                            const orchardPathologyKey = _.chain(this.search.orchardPathology).get("value").toLower().deburr().value();

                            return orchardPathology === orchardPathologyKey
                        } else {
                            return true;
                        }
                    })
                    .value();
            } else {
                this.filteredList = _.cloneDeep(this.list);
            }
        }

        clearKey() {
            this.filteredList = _.cloneDeep(this.list);
        }

        selectItem(item) {
            if (this.itemSelected(item)) {
                _.remove(this.selected, ['id', item.id]);
            } else {
                this.selected.push(item);
            }
        }

        itemSelected(item) {
            return !!_.find(this.selected, ["id", item.id]);
        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: ClassificationDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./osiics-classification.tpl.html"),
    };


})();
