/**
 * Created by amine on 09/09/2020.
 */
(function () {
    'use strict';

    const FILE_TEMPLATE = require('../json/growth-xp-template.json')

    class GXPService {
        constructor(measureService, configService, $q, mnWebSocket) {
            this.measureService = measureService;
            this.configService = configService;
            this.q = $q;
            this.mnWebSocket = mnWebSocket;

            this.config = {};
            this.graphClass = "fr";
            this.frame = null;

            this.baseURL = null;
            this.entryPage = null;
        }

        static get $inject() {
            return ["measureService", "configService", "$q", "mnWebSocket"];
        }

        byteArrayFromText(value) {
            let UA = (/[^\u0000-\u00ff]/.test(value)) ? new Uint16Array(value.length) : new Uint8Array(value.length);
            for (let i = 0; i < value.length; i++) UA[i] = value.charCodeAt(i);

            return UA;
        }

        byteArrayFromData(data) {
            data = this.byteArrayFromText(data);

            if (data.buffer)
                data = data.buffer;
            else {
                const arrayBuffer = new ArrayBuffer(data.BYTES_PER_ELEMENT * data.length);
                const newData = new data.constructor(arrayBuffer);
                newData.set(data);
                data = arrayBuffer;
            }

            data = new Uint8Array(data)
            return data
        }

        _loadData(frameId, patient) {
            const deferred = this.q.defer();

            this.measureService.getGXPData(patient)
                .then(data => {
                    const patientData = _.cloneDeep(FILE_TEMPLATE);
                    patientData.PATIENT.ROWS = [data[0]];
                    patientData.VISIT.ROWS = data[1];

                    let boundary = '---------------------------PRATISOFT';
                    boundary += Math.floor(Math.random() * 32768);
                    boundary += Math.floor(Math.random() * 32768);
                    boundary += Math.floor(Math.random() * 32768);

                    const body = this.generateBody(JSON.stringify(patientData), boundary);
                    const url = `${this.baseURL}WAXServer.dll?MSS=1&Init=1&graphclass=${this.graphClass}&ViewSize=Page,null,null&ParcelSize=Page,300&`;

                    this.postData(url, body, boundary)
                        .then(() => {
                            this.refreshFrame(frameId)
                                .then(deferred.resolve)
                        }, deferred.reject);
                });

            return deferred.promise;
        }

        getConfig() {
            const deferred = this.q.defer();

            if (this.baseURL) {
                deferred.resolve();
            } else {
                const config = JSON.parse(localStorage.getItem("gxp"));

                if (config) {
                    this.baseURL = this._fixUrl(`${config.server_url}/${config.wax_folder}/`);
                    this.entryPage = config.page;
                    deferred.resolve();
                } else {
                    this.configService.getByHttp('gxp_server_config')
                        .then(data => {
                            if (_.get(data, "server_url")) {
                                localStorage.setItem("gxp", JSON.stringify(data));
                                this.baseURL = this._fixUrl(`${data.server_url}/${data.wax_folder}/`);
                                this.entryPage = data.page;
                                deferred.resolve();
                            } else {
                                deferred.reject();
                            }
                        });
                }
            }

            return deferred.promise;
        }

        fixMeasures() {
            return this.mnWebSocket.call('patient.Measurement.fix_growth_xp', {})
        }

        loadData(frameId, patient) {
            const deferred = this.q.defer();

            this.getConfig()
                .then(() => {
                    this._loadData(frameId, patient)
                        .then(deferred.resolve, deferred.reject)
                })

            return deferred.promise;
        }

        unloadData() {
            if (this.frame && this.frame.parentNode) {
                this.frame.parentNode.removeChild(this.frame);
                this.frame = null;
            }
        }

        postData(url, body, boundary) {
            return fetch(url, {
                method: 'POST',
                body: body,
                headers: {'Content-Type': `multipart/form-data; boundary=${boundary}`},
                credentials: 'include'
            })
        }

        refreshFrame(frameId = null) {
            const deferred = this.q.defer();
            if (!this.frame && frameId)
                this.frame = document.getElementById(frameId);

            this.frame.src = `${this.baseURL}${this.entryPage}?pratisoftVersion=2.0.4&MSS=1&graphclass=${this.graphClass}#DataPreInitialized=1&InvalidateParcels=${(new Date).toISOString()}`;
            this.frame.onload = () => setTimeout(() => deferred.resolve(true), 500);

            return deferred.promise;
        }

        generateBody(rawData, boundary) {
            let bodyStart = '';
            bodyStart += '--' + boundary + '\r\n' + 'Content-Disposition: form-data; name="';
            bodyStart += `data"; filename="${this.patient}-${Math.floor(Math.random() * 32768)}.xjson"\r\n\r\n`;
            let bodyEnd = '\r\n';
            bodyEnd += '--' + boundary + '--';

            let bsSize = this.byteArrayFromData(bodyStart);
            let beSize = this.byteArrayFromData(bodyEnd);

            let body = new Uint8Array(bodyStart.length + rawData.length + bodyEnd.length);

            body.set(bsSize, 0);
            body.set(this.byteArrayFromData(rawData), bodyStart.length);
            body.set(beSize, bodyStart.length + rawData.length);
            return body.buffer;
        }

        _fixUrl(url) {
            return url.replace(new RegExp('//', 'g'), '/')
                .replace(new RegExp('(http|https):/', 'g'), '$1://');
        }
    }

    module.exports = GXPService;
})();
