(function () {

    'use strict';

    class TreeViewCtrl {
        constructor(dictionaryService, $attrs, $scope) {
            this.service = dictionaryService;

            if ($attrs.checkStatus) $scope.$watch("vm.checkStatus", value => this.setCheckStatus(value));
        }

        static get $inject() {
            return ["dictionaryService", "$attrs", "$scope"];
        }

        $onInit() {
            this.node = _.get(this.service, 'dictionaryConfig.use_alphabet') ? _.sortBy(this.node, i => _.lowerCase(i.value)) : this.node;
        }

        setCheckStatus(value) {
            if (!_.isUndefined(value) && _.isBoolean(value)) _.forEach(this.node, item => {
                item.isChecked = value
            });
        }

        change(node) {
            node.isChecked = !node.isChecked;

            const checked = _.chain(this.node).filter(i => i.isChecked).size().value();

            if (this.node.length === checked) this.checkStatus = true;
            else if (checked === 0) this.checkStatus = false;
            else if (checked > 0) this.checkStatus = null;
        }

        selectNodeHead(selectedNode) {
            selectedNode.collapsed = !selectedNode.collapsed;
        }

        selectNodeLabel(selectedNode) {
            if (!_.isEmpty(selectedNode.nodes)) selectedNode.collapsed = !selectedNode.collapsed;
            else if (this.service.currentFieldSubject) this.service.currentFieldSubject.next(selectedNode);
        }

        chooseNode(node) {
            this.chosenNode = node;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        let html = $(require('stand-alone/blocks/views/dictionary-tree-view.tpl.html'));

        let temp = '';
        let treeType = $attrs['treeType'] ? $attrs['treeType'] : "default";

        if (treeType === "default") temp = '<span ng-click="vm.selectNodeLabel(node)" ng-bind="node.value" title="{{node.value}}"></span>';
        if (treeType === "checkbox") {
            temp = `
                <md-checkbox aria-label="check" ng-checked="node.isChecked" md-indeterminate="node.isChecked === null" ng-click="vm.change(node)">
                    <span ng-bind="node.value" title="{{node.value}}"></span>
                </md-checkbox>
            `;

            $('mn-tree', html).attr('check-status', "node.isChecked");
        }
        if (treeType === 'choice') {
            temp = `
                <span ng-click="vm.chooseNode(node)" ng-bind="node.value" title="{{node.value}}"></span>
                <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="node.uid == vm.chosenNode.uid"></md-icon>
            `;

            if (!$element.is('.parent-node')) $('mn-tree', html).attr('chosen-node', "vm.chosenNode");
        }

        $('element-type', html).replaceWith(temp);
        $('mn-tree', html).attr('tree-type', treeType);

        return html;
    }

    module.exports = {
        bindings: {
            node: "=mnNode",
            chosenNode: "=?",
            checkStatus: "=?",
        },
        template: tpl, // or template
        controllerAs: "vm",
        controller: TreeViewCtrl,
    };

})();
