(function () {

    'use strict';

    class UnavailableCalendarElementCtrl {
        constructor(system) {
            this.event = this.event || {};
            this.timeFormat = system['time_format'].js;
        }

        static get $inject() {
            return ['system'];
        }

        $onInit() {
            this.physicians = _.chain(this.event).get('physicians').map('name_initials').join(', ').value();
            this.physicians_full_names = _.chain(this.event).get('physicians').map('full_name').join('\n').value();
            this.eventTime = `${this.event.startMoment.format(this.timeFormat)} - ${this.event.endMoment.format(this.timeFormat)}`;
        }

    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const agendaType = $attrs.type;

        const layoutClass = agendaType === 'dayGridMonth' ? 'unavailable-month-data' :  'unavailable-data';
        const timeBlock = agendaType === 'dayGridMonth' ? '<span class="time-block" ng-bind="vm.eventTime"></span>' : '';
        const physician = agendaType === 'dayGridMonth' ? '' : '<span class="physician-block" ng-bind="vm.physicians"></span>';

        return `
            <div class="${layoutClass}" title="{{vm.physicians_full_names}}">
                <span class="title-block" ng-bind="vm.event.title"></span>
               ${physician}
               ${timeBlock}
            </div>
        `;
    }

    module.exports = {
        bindings: {
            event: '<',
            type: '@'
        },
        template: tpl,
        controllerAs: "vm",
        controller: UnavailableCalendarElementCtrl,
    };

})();
