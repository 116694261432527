/**
 * Created by BETALOS on 05/05/2016.
 */
(function () {
    'use strict';

    module.exports = uploadService;

    const {Observable} = require('rxjs');
    const {extension} = require('mime-types');
    const downloadJs = require('downloadjs');
    const VISUALIZE_FILES_DIALOG = require('../dialogs/visualize-file-dialog');

    const ROOT_DEFINITION = {
        'patient': 'patient.models.Patient',
        'article': 'stock.models.Article',
        'StockMovement': 'stock.models.StockMovement',
        'Transformation': 'stock.models.Transformation',
        'WarehouseExchange': 'stock.models.WarehouseExchange',
        'PriceRequest': 'stock.models.PriceRequest',
        'PurchaseForm': 'stock.models.PurchaseForm',
        'PurchaseReceipt': 'stock.models.PurchaseReceipt',
        'GoodDeposition': 'stock.models.GoodDeposition',
        'GoodReturn': 'stock.models.GoodReturn',
        'DeliveryForm': 'stock.models.DeliveryForm',
        'OpeningBalance': 'stock.models.OpeningBalance',
        'CreditNote': 'stock.models.CreditNote',
        'PurchaseInvoice': 'stock.models.PurchaseInvoice',
        'expense': 'billing.models.Expense',
        'pec': 'billing.models.Pec',
        'PurchaseRequest': 'stock.models.PurchaseRequest',
        'Consignment': 'stock.models.Consignment',
        'ConsignmentReturn': 'stock.models.ConsignmentReturn',
        'Intervention': 'cam.models.Intervention',
        'equipment': 'cam.models.Equipment',

    };

    uploadService.$inject = ["Upload", "$http", "$q", "mnWebSocket", "$mdDialog"];

    function uploadService(Upload, $http, $q, mnWebSocket, $mdDialog) {
        let self = this;

        self.addFolder = addFolder;
        self.loadFolder = loadFolder;
        self.updateFolder = updateFolder;
        self.getRootFolder = getRootFolder;

        self.upload = upload;
        self.removeFile = removeFile;
        self.updateFile = updateFile;

        self.addFileToFolder = addFileToFolder;

        // context files
        self.rootContext = rootContext;
        self.rootContextFiles = rootContextFiles;

        self.downloadFile = downloadFile;
        self.visualizeFiles = visualizeFiles;

        self.getFilesByContext = getFilesByContext;
        self.getRootFolderId = getRootFolderId;

        self.downloadFileAsBlob = downloadFileAsBlob;

        function upload(files, folder, patient, context, extraContext) {
            const deferred = $q.defer();
            const query = {context: _.chain(context).cloneDeep().assign(extraContext).keys().value()};
            const url = `/api/upload-files/?${_.qs(query)}`;

            Upload.upload({
                url, data: _.assign({},
                    folder ? {folder} : {},
                    patient ? {patient} : {},
                    {files, context: Upload.json(context)}
                )
            }).then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function updateFolder(folder, context, extraContext) {
            const deferred = $q.defer();
            const query = {context: _.chain(context).cloneDeep().assign(extraContext).keys().value()};
            const url = `/api/folder/${folder.id}/?${_.qs(query)}`;

            const files = handleFileIds(folder.files);

            $http.put(url, {files})
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function loadFolder(folder, context, extraContext) {
            const deferred = $q.defer();
            const query = {context: _.chain(context).cloneDeep().assign(extraContext).keys().value()};
            const url = `/api/folder/${folder}/?${_.qs(query)}`;

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function getRootFolder(root, rootId, context, extraContext) {
            const deferred = $q.defer();
            const path = _.get(ROOT_DEFINITION, root);

            const query = {context: _.chain(context).cloneDeep().assign(extraContext).keys().value()};
            const url = `/api/folder/?root=${path}&root-id=${rootId}&${_.qs(query)}`;

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function removeFile(id) {
            const deferred = $q.defer();
            const url = `/api/base-file/${id}/`;

            $http.delete(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function updateFile(file, fields = 'name') {
            const deferred = $q.defer();
            const url = `/api/base-file/${file.id}/`;

            $http.put(url, _.pick(file, fields))
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function addFolder(folder, folderName, context, extraContext) {
            const deferred = $q.defer();
            const url = '/api/folder/';

            $http.post(url, {name: folderName, files: [], context: context})
                .then(success, deferred.reject);

            function success(res) {
                folder.files.push(
                    _.assign({}, res.data, {isNew: true})
                );

                self.updateFolder(folder, context, extraContext)
                    .then(data => deferred.resolve(data));
            }

            return deferred.promise;
        }

        function addFileToFolder(folder, file, parentFolder, context, extraContext) {
            const deferred = $q.defer();

            folder['file_ids'].push(file.id);

            const files = handleFileIds(folder['file_ids']);
            const url = `/api/folder/${folder.id}/`;

            $http.put(url, {files})
                .then(success, deferred.reject);

            function success() {
                _.remove(parentFolder.files, {id: file.id});

                self.updateFolder(parentFolder, context, extraContext)
                    .then(data => deferred.resolve(data));
            }

            return deferred.promise;
        }

        // files context
        function rootContext(root, rootId, namespace = "default") {
            return new Observable(observer => {
                getRootContexts(root, rootId, observer);
                mnWebSocket.sub('shared.upload.FileManager.folder_notify', namespace, (data) => {
                    if (data.patient_id === rootId) getRootContexts(root, rootId, observer);
                });

                return {
                    unsubscribe: () => {
                        mnWebSocket.unsub('shared.upload.FileManager.folder_notify', namespace);
                        observer.complete();
                    }
                }
            });
        }

        function getRootContexts(root, rootId, observer) {
            mnWebSocket.call("shared.upload.FileManager.root_folder_contexts", {
                root_id: rootId,
                root: _.get(ROOT_DEFINITION, root),
            }).then(data => handleContexts(data, observer));
        }

        function handleContexts(contexts, observer) {
            const handledContexts = _.chain(contexts)
                .reduce((sum, item) => {
                    _.forOwn(item, (value, key) => sum[key] = _.concat(sum[key] || [], value));
                    return sum
                }, {})
                .mapValues(i => _.countBy(i))
                .value();

            observer.next(handledContexts);
        }

        function rootContextFiles(root, rootId, context) {
            return mnWebSocket.call("shared.upload.FileManager.context_files", {
                root_id: rootId,
                context: context,
                root: _.get(ROOT_DEFINITION, root),
            });
        }

        function downloadFile(file) {
            const deferred = $q.defer();
            const url = file.url ? file.url : `/api/upload/${file.id}/download/`;

            $http.get(url, {responseType: 'arraybuffer'})
                .then(success, deferred.reject);

            function success(res) {
                let fileName;
                const ext = extension(res.headers('content-type'));

                if (ext && _.endsWith(file.name, ext)) fileName = file.name;
                else fileName = `${file.name}.${ext}`

                downloadJs(res.data, fileName);
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function visualizeFiles(files, index, ev, editImage, allowEdit = true) {
            const dialog = _.assign({}, VISUALIZE_FILES_DIALOG, {
                targetEvent: ev,
                locals: {
                    files: files,
                    fileIndex: index,
                    editImage, allowEdit,
                    filePath: "/api/upload/",
                    showPreview: !editImage,
                }
            });

            return $mdDialog.show(dialog);
        }


        function handleFileIds(files) {
            return _.chain(files)
                .map(item => {
                    return {id: _.isObject(item) ? item.id : item}
                })
                .uniqBy('id').value();
        }

        function getFilesByContext(root, root_id, context, extraContext = {}, strict = true) {
            const deferred = $q.defer();
            const rootObject = {root: _.get(ROOT_DEFINITION, root), root_id: root_id};
            const fullContext = _.assign(rootObject, context, extraContext);
            let query = _.reduce(fullContext, (result, value, key) => {
                return _.concat(result, `${key}=${value}`);
            }, []).join("&");

            $http.get(`/api/contextual-file/?${query}&strict=${strict ? 1 : 0}`)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function getRootFolderId(root, rootId, context, extraContext) {
            const deferred = $q.defer();
            const path = _.get(ROOT_DEFINITION, root);

            const query = {context: _.chain(context).cloneDeep().assign(extraContext).keys().value()};
            const url = `/api/contextual-file/root-directory/?root=${path}&root-id=${rootId}&${_.qs(query)}`;

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function downloadFileAsBlob(url) {
            const deferred = $q.defer();

            $http.get(url, {responseType: "blob"})
                .then(res => deferred.resolve(URL.createObjectURL(res.data)), deferred.reject);

            return deferred.promise;
        }
    }

})();
