(function () {
    'use strict';

    const MIMES = require('../json/mimes.json');
    const WEB_CAM_DIALOG = require('shared/dialogs/web-cam-dialog');
    const AUDIO_RECORD_DIALOG = require('../dialogs/audio-record-dialog');
    const generateId = require('shared/utils/unique-id.js');

    class ContextualFilesCtrl {
        constructor(uploadService, mnWebSocket, $q, $scope, $mdDialog, $element, $attrs, dcmService, interfacingService) {
            this.uploadService = uploadService;
            this.mnWebSocket = mnWebSocket;
            this.$q = $q;
            this.scope = $scope;
            this.$mdDialog = $mdDialog;
            this.$element = $element;
            this.dcmService = dcmService;
            this.interfacingService = interfacingService;

            this.auto = null;
            this.isAuto = null;

            this.root = null;
            this.rootId = null;
            this.context = null;
            this.preSave = null;
            this.filters = null;
            this.extraContext = null;
            this.filter = null;
            this.uploadTrigger = null;

            this.files = [];
            this.promise = null;
            this.directoryId = null;
            this.reloadingId = null;

            this.firePacsIsActive = dcmService.integrationConfig.is_active;
        }

        static get $inject() {
            return ["uploadService", "mnWebSocket", "$q", "$scope", "$mdDialog", "$element", "$attrs", "dcmService", "interfacingService"];
        }

        $onInit() {

        }

        $onChanges(changes) {
            if (_.has(changes, "context")) {
                if (!_.isNil(this.reloadingId) || _.difference(changes.context.previousValue, changes.context.currentValue).length > 0) {
                    clearTimeout(this.reloadingId);
                }

                this.reloadingId = setTimeout(() => {
                    if (!_.isNil(this.context)) {
                        this.reloadFiles();
                        this.reloadingId = null;
                    }
                }, 300)
            }

            if (_.has(changes, "rootId") && changes.rootId.currentValue) {
                this.promise = this.uploadService.getRootFolderId(this.root, this.rootId, this.context, this.extraContext)
                    .then(data => {
                        this.directoryId = data.id;

                        this.$element
                            .addClass("layout-column");

                        if (!this.isAuto) {
                            this.uploadTrigger({
                                $event: forceContext => this.handleFiles(forceContext)
                            });
                        }
                    });
            }

            if (_.has(changes, "auto")) {
                if (!_.isNil(this.reloadingId) || _.difference(changes.auto.previousValue, changes.auto.currentValue).length > 0) {
                    clearTimeout(this.reloadingId);
                }

                this.reloadingId = setTimeout(() => {
                    if (!_.isNil(this.context)) {
                        this.reloadFiles();
                        this.reloadingId = null;

                    }
                }, 300)
            }

            this.isAuto = _.isNil(this.auto) ? true : this.auto;
        }

        $onDestroy() {

        }

        handleFiles(forceContext = null) {
            if (_.isEmpty(this.files) || this.isAuto) return this.$q.when(false);

            const deferred = this.$q.defer();

            let promises = [];
            let files = _.reduce(this.files, (result, file) => {
                if (file.is_tmp && !file.is_folder) result.to_upload.push(file.rawData);
                if (file.is_tmp && file.is_folder) result.to_upload.push(file);
                else if (file.to_delete) result.to_delete.push(file);
                else result.to_edit.push(file);

                return result;
            }, {to_upload: [], to_edit: [], to_delete: []});

            if (!_.isEmpty(files.to_upload)) promises.push(this.uploadFiles(files.to_upload, forceContext, false));
            if (!_.isEmpty(files.to_delete)) _.concat(promises, this.deleteFiles(files.to_delete));

            this.promise = this.$q.all(promises).then(data => {
                deferred.resolve(data);
            }, _.noop);

            return deferred.promise;
        }

        uploadFiles(files, forceContext = null, preSave = true) {
            if (_.isEmpty(files)) return this.$q.when(null);

            const deferred = this.$q.defer();
            const context = _.isNil(forceContext) ? this.context : forceContext;

            const _upload = () => {
                const dcmPromises = _.chain(files)
                    .filter('is_dcm')
                    .map(i => () => this.dcmService.handleDcmInstance(this.rootId, i, context))
                    .value();

                const firePacsPromises = _.chain(files)
                    .filter('from_firepacs')
                    .map(i => () => this.dcmService.handleFirePacsStudy(this.rootId, context, i.study))
                    .value();

                const interfacingPromises = _.chain(files)
                    .filter('is_interfacing')
                    .map(i => () => this.interfacingService.handleInterfacingInstance(this.rootId, i, context))
                    .value();


                const promises = _.concat(
                    dcmPromises,
                    interfacingPromises,
                    firePacsPromises,
                    () => this.uploadService.upload(
                        _.filter(files, i => !i.is_folder),
                        this.directoryId,
                        null,
                        context,
                        this.extraContext
                    )
                );

                this.promise = promises
                    .reduce((prev, next) => prev.then(() => next()), this.$q.resolve(0))
                    .then(data => {
                        this.clearFilter();
                        this.publishReload(data);

                        deferred.resolve(data);
                    });
            };

            if (_.isFunction(this.preSave)) {
                this.preSave().then(data => {
                    this.scope.$applyAsync(() => {
                        context.consultation = data;
                        _upload();
                    });
                }, _.noop);
            } else {
                _upload();
            }

            return deferred.promise;
        }

        reloadFiles() {
            return this.uploadService.getFilesByContext(this.root, this.rootId, this.context, this.extraContext)
                .then(data => {
                    this.files = _.map(data, item => _.assign(item, {
                        icon: this.chooseImage(item),
                        is_folder: item['mime'] === 'folder',
                        auto: this.isAuto,
                        readonly: this.readonly
                    }));
                    //const parents = this.$element.parents("mn-file-block");
                    //this.$element.toggleClass("no-files", _.isEmpty(this.files));
                    //if (parents.length > 0) parents.toggleClass("no-files", _.isEmpty(this.files));
                });
        }

        attachFiles(files) {
            if (this.isAuto) {
                this.uploadFiles(files).then(() => this.reloadFiles())
            } else {
                this.files = _.concat(this.files, _.map(files, item => {
                    return {
                        tmp_id: this.tempIdGenerator(),
                        is_tmp: true,
                        icon: this.chooseImage(item, "type"),
                        mime: item.type,
                        name: item.name,
                        rawData: item
                    }
                }));
            }

            const parents = this.$element.parents("mn-file-block");
            this.$element.removeClass("no-files");
            if (parents.length > 0) parents.removeClass("no-files");
        }

        openFile(file, editImage, event) {
            if (file.is_tmp || file.is_folder) return false;
            else if (file['is_visualized'] && file['is_converted']) {
                let visualizedFiles = editImage ? _.castArray(file) : _.filter(this.files, {
                    is_visualized: true,
                    is_converted: true
                });
                let fileIndex = editImage ? 0 : _.findIndex(visualizedFiles, file);

                this.uploadService.visualizeFiles(visualizedFiles, fileIndex, event, editImage)
                    .then(data => this.promise = this.uploadFiles(data));
            } else if (file['is_visualized'] && !file['is_converted']) {
                this.$mdDialog.show(this.notConvertedDialog(event));
            } else {
                this.downloadFile(file);
            }
        }

        removeFile(file, event) {
            if (!_.isNil(event)) event.stopPropagation();

            if (this.isAuto) this.simpleDeleting(file);
            else this.manualDeleting(file);
        }

        simpleDeleting(file) {
            this.uploadService.removeFile(file.id)
                .then(() => _.remove(this.files, ['id', file.id]))
        }

        manualDeleting(file) {
            if (file.is_tmp) {
                _.remove(this.files, ['tmp_id', file.tmp_id])
            } else if (file.to_delete) {
                file.to_delete = false;
            } else {
                file.to_delete = true;
            }
        }

        deleteFiles(files) {
            if (_.isEmpty(files)) return [];
            if (!_.isArray(files)) files = _.castArray(files);

            return _.map(files, file => this.uploadService.removeFile(file.id))
        }

        downloadFile(file) {
            this.promise = this.uploadService.downloadFile(file);
        }

        handleExternalFiles(files) {
            if (this.isAuto) {
                this.uploadFiles(files)
                    .then(() => this.reloadFiles(), _.noop)
            } else {
                this.files = _.concat(this.files, _.map(files, item => {
                    return {
                        tmp_id: this.tempIdGenerator(),
                        is_tmp: true,
                        icon: this.chooseImage(item, "type"),
                        mime: item.type,
                        name: item.name,
                        rawData: item
                    }
                }));
            }
        }

        handleExternalDirectory(dirName, id, args = {}) {
            this.files = _.concat(this.files, _.assign({}, {
                id: id,
                tmp_id: this.tempIdGenerator(),
                name: dirName,
                is_folder: true,
                is_tmp: true,
                icon: MIMES.ICONS['folder'],
            }, args));
        }

        recordAudio(event) {
            this.$mdDialog.show(_.assign({}, AUDIO_RECORD_DIALOG, {
                targetEvent: event,
                multiple: true,
            })).then(files => {
                this.handleExternalFiles(files)
            }, _.noop);
        }

        takePhoto(event) {
            this.$mdDialog.show(_.assign({}, WEB_CAM_DIALOG, {
                targetEvent: event,
                locals: {
                    multi: true
                }
            })).then(files => this.handleExternalFiles(files), _.noop);
        }

        afterEditFile(file) {
            return _.assign({}, file, {
                icon: this.chooseImage(file),
                auto: this.isAuto,
                readonly: this.readonly
            });
        }

        chooseImage(file, field = 'mime') {
            if (!_.has(file, field) || _.isNil(file[field])) return MIMES.ICONS['other'];

            if (file[field] === 'folder') {
                return MIMES.ICONS['folder'];
            } else if (file[field].includes("image")) {
                return MIMES.ICONS['image'];
            } else if (file[field].includes("video")) {
                return MIMES.ICONS['video'];
            } else if (file[field].includes("audio")) {
                return MIMES.ICONS['audio'];
            } else if (file[field] === MIMES.PDF_MIME) {
                return MIMES.ICONS['pdf'];
            } else if (_.includes(MIMES.WORD_MIMES, file[field])) {
                return MIMES.ICONS['word'];
            } else if (_.includes(MIMES.EXCEL_MIMES, file[field])) {
                return MIMES.ICONS['excel'];
            } else if (_.includes(MIMES.POWER_POINT_MIMES, file[field])) {
                return MIMES.ICONS['power-point'];
            } else if (!_.every(MIMES.ARCHIVE_MIMES, item => !_.includes(file[field], item))) {
                return MIMES.ICONS['archive'];
            } else return MIMES.ICONS['other'];
        }

        publishReload() {
            this.mnWebSocket.pub("shared.upload.FileManager.folder_notify", {patient_id: this.rootId}, true)
            //this.reloadFiles()
        }

        clearFilter() {
            this.filter = {};
            //this.files = [];
        }

        notConvertedDialog(event) {
            return this.$mdDialog.alert()
                .ok('OK')
                .multiple(true)
                .targetEvent(event)
                .parent(document.body)
                .clickOutsideToClose(true)
                .ariaLabel('file not yet converted')
                .title($translate['instant']('file_manager_not_yet_converted'))
        }

        tempIdGenerator() {
            return +`${generateId()}.${Math.ceil(Math.random() * Math.pow(10, 15)).toString(16)}`;
        }

        importLastDcm() {
            const preSave = this.auto ? this.preSave : null;
            this.promise = this.dcmService.importLastDcmInstance(this.rootId, preSave, this.context || {}, !this.auto)
                .then(instance => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${instance['modality_short_description']} ${instance['study_datetime']}`;
                        this.handleExternalDirectory(name, instance.id, {is_dcm: true});
                    }
                }, _.noop);
        }

        importDcm(ev) {
            const preSave = this.auto ? this.preSave : null;
            this.dcmService.importDcmInstance(this.rootId, preSave, this.context || {}, ev, !this.auto)
                .then(instance => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${instance['modality_short_description']} ${instance['study_datetime']}`;
                        this.handleExternalDirectory(name, instance.id, {is_dcm: true});
                    }
                }, _.noop);
        }

        importLastInterfacing() {
            const preSave = this.auto ? this.preSave : null;
            this.interfacingService.importLastInterfacingInstance(this.rootId, preSave, this.context || {}, !this.auto)
                .then(exam => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${exam['default_config_title']} ${exam['acquisition_date_time']}`;
                        this.handleExternalDirectory(name, exam.id, {is_interfacing: true});
                    }
                }, _.noop);
        }

        importInterfacing(ev) {
            const preSave = this.auto ? this.preSave : null;
            this.interfacingService.importInterfacingInstance(this.rootId, preSave, this.context || {}, ev, !this.auto)
                .then(exam => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${exam['default_config_title']} ${exam['acquisition_date_time']}`;
                        this.handleExternalDirectory(name, exam.id, {is_interfacing: true});
                    }
                }, _.noop);
        }

        importLastFirePacsStudy() {
            const preSave = this.auto ? this.preSave : null;
            this.dcmService.importLastFirePacsStudy(this.rootId, preSave, this.context || {}, !this.auto)
                .then(study => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${_.get(study, 'MainDicomTags.StudyDescription')} - ${_.get(study, 'MainDicomTags.StudyDate')}`;
                        this.handleExternalDirectory(name, study.id, {from_firepacs: true, study});
                    }
                }, _.noop)
        }

        importFirePacsStudy(ev) {
            const preSave = this.auto ? this.preSave : null;
            this.dcmService.importFirePacsStudy(this.rootId, preSave, this.context || {}, ev, !this.auto)
                .then(study => {
                    if (this.auto) {
                        this.reloadFiles();
                    } else {
                        const name = `${_.get(study, 'MainDicomTags.StudyDescription')} - ${_.get(study, 'MainDicomTags.StudyDate')}`;
                        this.handleExternalDirectory(name, study.id, {from_firepacs: true, study});
                    }
                }, _.noop)
        }

    }

    module.exports = {
        bindings: {
            root: "@",
            rootId: "<",
            context: "<",
            preSave: "&?",
            filters: "<?",
            extraContext: "<?",
            readonly: "<?",
            auto: "<?",
            filter: "=?contextFilter",
            uploadTrigger: "&?"
        },
        controllerAs: "vm",
        controller: ContextualFilesCtrl,
        template: require('../views/contextual-files.tpl.html')
    };

})();
