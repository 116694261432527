(function () {

    'use strict';

    const {
        Vector3,
        Color,
        Object3D,
        PerspectiveCamera,
        PointLight,
        MeshPhongMaterial,
        DoubleSide,
        WebGLRenderer,
        FogExp2,
        Scene,
        Mesh,
        Matrix4
    } = require('three');
    const {STLLoader} = require('three/examples/jsm/loaders/STLLoader');
    const {OrbitControls} = require('three/examples/jsm/controls/OrbitControls');

    const DEFAULT_MESH_OPTIONS = {
        castShadow: true,
        receiveShadow: true,
        position: new Vector3(0, 0, 0),
        scale: new Vector3(0.03, 0.03, 0.03)
    }

    const COLORS = {
        primary: new Color('#0a85ff'),
        warning: new Color('#47002A'),
        support: new Color('#CACACA'),
        white: new Color('#ffffff'),
    };

    class StlViewerCtrl {
        constructor($element) {
            this.element = $element;

            this.src = null;

            // three related stuff
            this.centered = true;
            this.loader = new STLLoader();
            this.middle = new Vector3();
            this.meshGroup = new Object3D();
            this.camera = new PerspectiveCamera(
                35, 500 / 500, 1, 15
            );
            this.cameraTarget = new Vector3(0, 0, 0);
            this.light = new PointLight(COLORS.support);
            this.material = new MeshPhongMaterial({
                color: COLORS.primary,
                shininess: 2000,
                // specular: 0x111111,
                depthWrite: true,
                side: DoubleSide
            });
            this.renderer = new WebGLRenderer({antialias: true});

            // default light position
            this.light.position.set(1, 1, 2);

            // default camera position
            this.camera.position.set(3, 3, 3);
            this.camera.add(this.light);

            // default scene background
            const fog = new FogExp2(COLORS.support, 0.0128);
            // const grid = new THREE.GridHelper();

            this.scene = new Scene();
            // this.scene.add(grid);
            this.scene.add(this.camera);
            this.scene.fog = fog;
            this.scene.background = COLORS.white;

            // default renderer options
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.shadowMap.enabled = true;

            // controls
            this.controls = new OrbitControls(
                this.camera, this.renderer.domElement
            )
            this.controls.enableZoom = true
            this.controls.minDistance = -5
            this.controls.maxDistance = 10

            this.controls.addEventListener('change', () => this.render());

            // hacks
            this.onWindowResize = () => {
                this.setSizes();
                this.render();
            }
        }

        static get $inject() {
            return ["$element"];
        }

        $onInit() {
            this.promise = this.createMesh()
                .then(mesh => {
                    this.meshGroup.add(mesh)
                    this.scene.add(this.meshGroup);
                    this.element.find('.stl-container').append(this.renderer.domElement);

                    this.setSizes();
                    this.render();

                    window.addEventListener('resize', this.onWindowResize, false);
                });
        }

        $onDestroy() {
            window.removeEventListener('resize', this.onWindowResize, false);

            this.meshGroup.children.forEach((child) => this.meshGroup.remove(child));
            this.scene.children.forEach((child) => this.scene.remove(child));
            this.camera.remove(this.light);

            if (this.material) {
                this.material.dispose();
            }

            if (this.controls) {
                this.controls.removeEventListener('change', this.render);
                this.controls.dispose();
            }

            this.renderer.renderLists.dispose();
            this.renderer.domElement.remove();

            this.renderer.dispose();
        }

        // three related methods
        render() {
            this.renderer.render(this.scene, this.camera);
        }

        setSizes() {
            const width = this.element.find('.stl-container').width();
            const height = this.element.find('.stl-container').height();

            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(width, height);
        }

        createMesh() {
            return new Promise(resolve => {
                this.loader.loadAsync(this.src)
                    .then(geometry => {
                        const mesh = new Mesh(geometry, this.material)

                        if (this.centered) {
                            geometry.computeBoundingBox()
                            geometry.boundingBox.getCenter(this.middle)
                            mesh.geometry.applyMatrix4(
                                new Matrix4().makeTranslation(
                                    -this.middle.x,
                                    -this.middle.y,
                                    -this.middle.z
                                )
                            )
                        }

                        const vectorOptions = ['position', 'scale', 'up']

                        Object.getOwnPropertyNames(DEFAULT_MESH_OPTIONS).forEach((option) => {
                            if (vectorOptions.indexOf(option) > -1) {
                                const vector = DEFAULT_MESH_OPTIONS[option];
                                const meshVectorOption = mesh[option];
                                meshVectorOption.set(vector.x, vector.y, vector.z)
                            } else {
                                mesh[option] = DEFAULT_MESH_OPTIONS[option]
                            }
                        })

                        resolve(mesh);
                    });
            });
        }
    }

    module.exports = {
        bindings: {
            src: '@'
        },
        template: `
            <div cg-busy="vm.promise" class="stl-container"></div>
        `,
        controllerAs: "vm",
        controller: StlViewerCtrl,
    };

})();
