/**
 * Created by BETALOS on 18/03/2016.
 */
(function () {

    'use strict';

    module.exports = locationService;

    locationService.$inject = ["mnWebSocket", "$mdDialog", "$translate", "$q"];

    function locationService(mnWebSocket, $mdDialog, $translate, $q) {
        var self = this;

        self.list = list;
        self.reorderList = reorderList;
        self.handleLocation = handleLocation;

        self.removeItem = removeItem;

        function list(model, hidden) {
            return mnWebSocket.call('shared.Location.list', {model_name: model, is_hidden: hidden})
        }

        function handleLocation(item) {
            return mnWebSocket.call('shared.Location.handle_list', item);
        }

        function reorderList(items, model) {
            return mnWebSocket.call('shared.Location.reorder', {items: items, model_name: model});
        }

        function removeItem(item, model, ev) {
            var deferred = $q.defer();

            var confirmLabel = _.format('list_setup_{0}_confirm', model.toLowerCase());

            var confirm = $mdDialog.confirm()
                .title($translate['instant'](confirmLabel, item))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                mnWebSocket.call('shared.Location.remove', {id: item.id, model_name: model})
                    .then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }
    }

})();