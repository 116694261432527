(function () {

    "use strict";

    const fabric = require("fabric-browseronly").fabric;

    module.exports = rotate;

    let $canvas = $('.canvas');

    function rotate(degree) {
        let vm = this;

        let resetCanvas = _.bind(require('./reset-canvas'), vm);
        resetCanvas();
        let dimCalculate = _.bind(require('./dim-calculate'), vm);
        vm.currentCanvas.setDimensions({width: $canvas.width(), height: $canvas.height()});
        let canvasCenter = new fabric.Point(vm.currentCanvas.getCenter().left, vm.currentCanvas.getCenter().top);
        vm.currentCanvas.forEachObject(function (obj) {

            if (obj.type !== 'i-text') {
                let objectOrigin = new fabric.Point(obj.left, obj.top);
                let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, (degree * Math.PI) / 180);

                obj.top = new_loc.y;
                obj.left = new_loc.x;

                if (obj.type === 'image') {

                    obj.setHeight(vm.oldImgSize.height);
                    obj.setWidth(vm.oldImgSize.width);

                    obj.animate('angle', obj.angle + degree, {
                        onChange: vm.currentCanvas.renderAll.bind(vm.currentCanvas),
                        easing: fabric.util.ease.easeInOutQuad
                    });


                    let resizeImg = dimCalculate({width: obj.getHeight(), height: obj.getWidth()});

                    if (vm.help) {
                        obj.setWidth(resizeImg['height']);
                        obj.setHeight(resizeImg['width']);
                        vm.currentCanvas.setDimensions({width: obj.height, height: obj.width});
                        vm.help = false;
                    } else {
                        obj.set(resizeImg);
                        vm.currentCanvas.setDimensions({width: obj.width, height: obj.height});
                        vm.help = true;
                    }

                    vm.currentCanvas.centerObject(vm.currentCanvas.getObjects()[0]);
                    vm.currentCanvas.renderAll();

                } else {
                    obj.angle += degree;
                }
            }
        });


        vm.currentCanvas.renderAll();

    }
})();