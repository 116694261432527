(function () {

    'use strict';

    class FileUpdateDialogCtrl {
        constructor($mdDialog, uploadService, $mdToast, $translate) {
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.uploadService = uploadService;

            this.simpleToast = this.toast.simple()
                .textContent($translate['instant']('file_manager_visualize_impossible'))
                .position("bottom left")
                .hideDelay(1500);
        }

        static get $inject() {
            return ["$mdDialog", "uploadService", "$mdToast", "$translate"];
        }

        $onInit() {
            this.file = this.file ? this.file : {};
            this.filters = this.filters ? this.filters : [];
        }

        cancel() {
            this.dialog.cancel();
        }

        openFile(ev) {
            if (this.file['is_visualized'] && this.file['is_converted']) {
                this.uploadService.visualizeFiles([this.file], 0, ev, false, false);
            }
            else this.toast.show(this.simpleToast);
        }

        isFull(filter) {
            const path = `context.${filter.contextType}`;
            return !_.chain(this.file).get(path).isEmpty().value();
        }

        unsetContext(filter) {
            _.unset(this.file, `context.${filter.contextType}`);
        }

        submit() {
            this.promise = this.uploadService.updateFile(this.file, ['name', 'context'])
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: FileUpdateDialogCtrl,
        template: require("../views/file-update-dialog.tpl.html"),
    };


})();
