(function () {

    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');
    const {UPDATE, APPOINTMENT, WAITING_LIST, DELETE} = require('stand-alone/calendar/utils/consts');

    const RDV_MENU_ELEMENT = `
        <md-button class="md-icon-button" aria-label="rdv menu" ng-click="vm.openMenu($event)" type="button">
            <md-icon md-font-icon="mdi-dots-vertical" md-font-set="mdi"></md-icon>
        </md-button>
    `;

    const RDV_MENU_CONTAINER = `
        <md-menu md-position-mode="target-right target">
            <md-button aria-label="rdv menu" class="md-icon-button" ng-click="null"></md-button>
            <md-menu-content class="mn-calendar-menu-v2" role="menu">
                <md-menu-item ng-repeat="room in vm.rooms track by room.id" ng-if="vm.isToday" mn-acl='{"action": "create", "resource": "entry"}'>
                    <md-button ng-click="vm.createEntry('wr', room)" aria-label="edit"
                               class="mn-room-menu-button">
                        <md-icon md-font-icon="mdi-seat-recline-extra" md-font-set="mdi"></md-icon>
                        <span ng-bind="::room.name" flex></span>
                        <span class="mn-room-badge" ng-bind="room['occupy'] + '/' + room.capacity"></span>
                    </md-button>
                </md-menu-item>
                
                <md-menu-divider ng-if="vm.rooms.length > 0 && vm.isToday" mn-acl='{"action": "create", "resource": "entry"}'></md-menu-divider>
                
                 <md-menu-item ng-if="vm.isToday && !vm.event.patient_is_draft" mn-acl='{"action": "create", "resource": "entry"}'>         
                    <md-button ng-click="vm.createEntry('sv')" aria-label="start_cons">
                        <md-icon md-font-icon="{{ vm.visitLinkConfig.icon }}" md-font-set="mdi"></md-icon>
                        <span translate-once="consultation_access"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item ng-if="vm.event.patient_last_visit_id" mn-acl="{resource: 'visit', action: 'get'}"> 
                    <md-button ng-click="vm.patientLastVisit()" aria-label="edit">
                        <md-icon md-font-icon="{{ vm.visitLinkConfig.icon }}" md-font-set="mdi"></md-icon>
                        <span translate-once="patient_last_visit"></span>
                    </md-button>
                </md-menu-item> 
                
                <md-menu-divider ng-if="(vm.isToday && !vm.event.patient_is_draft) || vm.event.patient_last_visit_id"></md-menu-divider>
                
                <md-menu-item ng-if="!vm.event.is_entered" mn-acl='{"action": "update", "resource": "appointment"}'> 
                    <md-button ng-click="vm.editEvent($event)" aria-label="edit">
                        <md-icon md-font-icon="mdi-pencil-box-outline" md-font-set="mdi"></md-icon>
                        <span translate-once="edit_rdv"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item ng-if="!vm.event.is_entered && !vm.event.is_waiting_list" mn-acl='{"action": "update", "update": "appointment"}'> 
                    <md-button ng-click="vm.toWaitingList($event)" aria-label="edit">
                        <md-icon md-font-icon="mdi-calendar-clock" md-font-set="mdi"></md-icon>
                        <span translate-once="waiting_list"></span>
                    </md-button>
                </md-menu-item>
                <md-menu-item mn-acl='{"action": "get", "resource": "appointment"}'> 
                    <md-button ng-click="vm.getNextAppointment($event)" aria-label="edit">
                        <md-icon md-font-icon="mdi-calendar-search" md-font-set="mdi"></md-icon>
                        <span translate-once="next_rdv"></span>
                    </md-button>
                </md-menu-item>
                   
                <md-menu-divider></md-menu-divider>
                 
                <md-menu-item> 
                    <md-button ng-click="vm.patientFile()" aria-label="edit">
                        <md-icon md-font-icon="mdi-account" md-font-set="mdi"></md-icon>
                        <span translate-once="patient_file"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item> 
                    <md-button ng-click="vm.patientInfo($event)" aria-label="edit">
                        <md-icon md-font-icon="mdi-card-account-details" md-font-set="mdi"></md-icon>
                        <span translate-once="patient_details"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item mn-acl="{resource: 'dental', action: 'get'}" mn-active-module="has_dental"> 
                    <md-button ng-click="vm.patientDental()" aria-label="edit">
                        <md-icon md-font-icon="mdi-tooth" md-font-set="mdi"></md-icon>
                        <span translate-once="patient_dental"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item mn-acl='{"action": "edit-scheduling", "resource": "medical-care"}'>
                    <md-button ng-disabled="vm.item.patient['is_draft']"
                               ng-click="vm.medicalCareSchedulingDialog($event)"
                               aria-label="edit scheduling">
                        <md-icon md-font-icon="mdi-clipboard-pulse-outline" md-font-set="mdi"></md-icon>
                        <span translate-once="medical_care.scheduling_long_2"></span>
                    </md-button>
                </md-menu-item>
                
                <md-menu-divider mn-acl='{"action": "create", "resource": "notification"}'></md-menu-divider>     
                
                <md-menu-item mn-acl='{"action": "create", "resource": "notification"}'> 
                    <md-button ng-click="vm.addNewAlert($event)" aria-label="new alert">
                        <md-icon md-font-icon="mdi-alert-plus" md-font-set="mdi"></md-icon>
                        <span translate-once="alert_new_entry"></span>
                    </md-button>
                </md-menu-item> 
                <md-menu-item mn-acl='{"action": "create", "resource": "notification"}'> 
                    <md-button ng-click="vm.addNewNotification($event)" aria-label="new alert" >
                        <md-icon md-font-icon="mdi-comment-plus-outline" md-font-set="mdi"></md-icon>
                        <span translate-once="notification_add_new"></span>
                    </md-button>
                </md-menu-item>
                
                <md-menu-divider ng-if="!vm.event.is_entered" mn-acl='{"action": "delete", "resource": "appointment"}'></md-menu-divider>  
                
                <md-menu-item ng-if="!vm.event.is_entered" mn-acl='{"action": "delete", "resource": "appointment"}'>  
                    <md-button ng-click="vm.deleteEvent()" aria-label="delete">
                        <md-icon md-font-icon="mdi-cancel" md-font-set="mdi" class="mn-danger"></md-icon>
                        <span translate-once="appointment_cancel"></span>
                    </md-button>
                </md-menu-item>                 
            </md-menu-content>  
        </md-menu>
    `;

    class RdvCalendarElementCtrl {
        constructor(
            $element, visitService, frontDeskService, practiceService, notificationService, mnWebSocket, $state, $auth,
            $mdDialog, system, $scope, $mdMenu, $compile, medicalCareService
        ) {
            this.scope = $scope;
            this.mdMenu = $mdMenu;
            this.compile = $compile;

            this.state = $state;
            this.element = $element;
            this.dialog = $mdDialog;
            this.mnWebSocket = mnWebSocket;
            this.visitService = visitService;
            this.frontDeskService = frontDeskService;
            this.practiceService = practiceService;
            this.notificationService = notificationService;
            this.medicalCareService = medicalCareService;

            this.type = this.type || "";
            this.event = this.event || {};
            this.token = $auth.getToken();
            this.rooms = _.filter(frontDeskService.roomSubject.getValue(), {type: 'WR'});

            this.timeFormat = system['time_format'].js;
            this.dateFormat = system['date_format'].js;
            this.dateTimeFormat = system['datetime_format'].js;
        }

        static get $inject() {
            return [
                "$element", "visitService", "frontDeskService", "practiceService", "notificationService", "mnWebSocket",
                "$state", "$auth", "$mdDialog", "system", "$scope", "$mdMenu", '$compile', "medicalCareService"
            ];
        }

        $onInit() {
            this.imageSrc = `/api/patient/${this.event.patient_id}/data/?auth=${this.token}`;
            this.hasVisitClass = this.event.patient_has_visit ? 'has-visit' : 'has-not-visit';
            this.blockClass = this.event.patient_has_visit ? 'patient-has-visit' : 'patient-has-not-visit';

            this.createdAt = moment(this.event.created_at).format(this.dateFormat);

            if (this.event.startMoment) {
                this.eventTime = `${this.event.startMoment.format(this.timeFormat)} - ${this.event.endMoment.format(this.timeFormat)}`;
                this.isToday = this.event.startMoment.format(this.dateFormat) === moment().format(this.dateFormat) && !this.event.is_entered;
            }

            this.visitSubLinksSubscription = this.visitService.visitSubLinks.subscribe((data) => {
                this.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);
            });
        }

        $onChanges() {
            const handledComment = _.escape(`${this.event.other_comment || ''}${this.event.other_comment ? ' - ' : ''}${moment(this.event.created_at).format(this.dateTimeFormat)}`);
            this.element.attr('title', handledComment);

            if (this.type === 'waiting-list') {
                this.element.removeClass((index, className) => (className.match(/\b(physician|reason)-\S+/g) || []).join(' '));

                this.element.addClass(this.event['reason_class']);
                this.element.addClass(this.event['physician_class']);

                this.element.attr("data-event", this.event.id);
                this.element.data('event', this.event);
            }
        }

        $onDestroy() {
            if (this.visitSubLinksSubscription) this.visitSubLinksSubscription.unsubscribe();
        }

        // menu related
        openMenu(ev) {
            ev.stopPropagation();

            if (this.element.find('.menu-container md-menu.md-menu').length === 0) {
                const compiledDirective = this.compile(RDV_MENU_CONTAINER)
                const menuContainer = compiledDirective(this.scope);
                this.element.find('.menu-container').prepend(menuContainer);

                setTimeout(() => {
                    const scope = menuContainer.scope();
                    scope.$mdMenu.open(ev);
                }, 25);
            } else {
                const menu = this.element.find('.menu-container md-menu.md-menu');
                const scope = angular.element(menu).scope();

                scope.$mdMenu.open(ev);
            }
        }

        patientInfo(ev) {
            ev.stopPropagation();
            const locals = {
                onlyResume: true, 'patient-id': _.get(this.event, 'patient_id')
            };

            this.frontDeskService.entryResume(locals, ev);
        }

        openFullImage(ev) {
            ev.stopPropagation();

            const file = {name: '', mime: 'image/png', url: this.imageSrc};

            const dialog = _.assign({}, VISUALIZE_FILE, {
                targetEvent: ev,
                locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
            });

            this.dialog.show(dialog);
        }

        // menu actions
        createEntry(stat, room) {
            this.frontDeskService.createEntryFromCalendar(this.event, stat, room)
                .then(data => {
                    this.practiceService.entryAdded(data.entry);
                    this.mnWebSocket.pub("frontdesk.Practice.entry_added", data.entry);
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                        cmd: UPDATE,
                        type: APPOINTMENT,
                        event: data.appointment.id,
                        event_type: data.appointment.event_type
                    }, false);
                });
        }

        editEvent(ev) {
            this.frontDeskService.editAppointment(this.event, ev);
        }

        toWaitingList() {
            const data = {date: null, start_time: null, end_time: null, ignore: true, is_waiting_list: true};

            this.frontDeskService.partialUpdateAppointment(this.event.id, data)
                .then(() => {
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                        cmd: WAITING_LIST,
                        type: APPOINTMENT,
                        event: this.event.id,
                        event_type: this.event.event_type
                    }, false);
                });
        }

        getNextAppointment(ev) {
            this.frontDeskService.getNextAppointment(this.event.patient_id, ev);
        }

        patientFile() {
            this.state.go("app.patient-form", {'patient_id': _.get(this.event, 'patient_id')});
        }

        patientDental() {
            this.state.go('app.patient-dental', {pId: _.get(this.event, 'patient_id')});
        }

        patientLastVisit() {
            this.state.go(this.visitLinkConfig.link, {
                visitId: this.event.patient_last_visit_id, pId: this.event.patient_id
            });
        }

        medicalCareSchedulingDialog(ev) {
            this.medicalCareService.openSchedulingDialog(_.get(this.event, 'patient_id'), null, false, ev)
        }

        addNewAlert(ev) {
            this.notificationService.alertDialog(
                {id: _.get(this.event, 'patient_id'), full_name: _.get(this.event, 'title')}, 'SV', null, ev
            );
        }

        addNewNotification(ev) {
            let patient = {
                id: _.get(this.event, 'patient_id'), full_name: _.get(this.event, 'title')
            };

            this.notificationService.notificationDialog(null, patient, ev);
        }

        deleteEvent() {
            const event = _.toNumber(this.event.id);
            const event_type = _.get(this.event, "event_type");

            this.frontDeskService.removeAppointment(event)
                .then(() => {
                    this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                        cmd: DELETE,
                        type: APPOINTMENT,
                        event,
                        event_type
                    }, false);
                });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const agendaType = $attrs.type;

        if (agendaType === 'listWeek' || agendaType === 'listDay') return `
            <td class="mn-list-item-picture">
                <img src-blob="vm.imageSrc" alt="" ng-click="vm.openFullImage($event)"/>
            </td>
            <td class="fc-list-item-time fc-widget-content" ng-bind="vm.eventTime"></td>
            <td class="fc-list-item-marker fc-widget-content">
                <div class="mn-event-dot-container">
                    <span class="fc-event-dot" ng-style="{'background-color': vm.event.backgroundColor}"></span>
                </div>
            </td>
            <td class="fc-list-item-title fc-widget-content">
                <div class="mn-list-title-container">
                    <p class="mn-list-comment">
                        <a class="mn-list-title" ng-bind="vm.event.title" ng-click="vm.patientInfo($event)"></a>
                        <span ng-bind="vm.event.other_comment"></span>
                    </p>
                    
                    <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                    <mn-patient-alert patient="vm.event.patient_id" class="calendar-patient-alert"></mn-patient-alert>
                    
                    <div class="menu-container">
                        ${RDV_MENU_ELEMENT}
                    </div>
                </div>
            </td>
        `;
        else if (agendaType === 'waiting-list') {
            $element.addClass('fc-event mn-event mn-waiting-event');

            return `
                <div class="layout-row">
                    <div class="waiting-item-data flex layout-column">
                        <span class="patient-block" ng-class="vm.blockClass" ng-bind="vm.event.title" ng-click="vm.patientInfo($event)"></span>
                        <span class="physician-block" ng-bind="vm.event.physician_name"></span>
                        <span class="reason-block" ng-bind="vm.event.reason_value"></span>
                        <span class="date-block" ng-bind="vm.createdAt"></span>
                        <sup class="comment-block middle-v" ng-bind="vm.event.waiting_list_comment"></sup>
                
                        <span ng-class="vm.hasVisitClass"></span>
                    </div>
                    
                    <div class="layout-column menu-container" style="max-height: 40px;">          
                        ${RDV_MENU_ELEMENT}
                        <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                        <md-icon md-font-icon="mdi-clipboard-pulse" md-font-set="mdi" ng-if="vm.event['from_medical_care']"></md-icon>
                    </div>
                </div>
                <div class="agenda-corner" ng-style="{'background-color': vm.event.agenda_color}"></div>
            `;
        } else return `
            <div class="layout-row">
                <div class="rdv-data flex">
                    <span class="patient-block" ng-class="vm.blockClass" 
                        ng-bind="vm.event.title" title="{{vm.event.title}}" 
                        ng-click="vm.patientInfo($event)">
                    </span>
                    <span class="date-block" ng-bind="vm.event.date"></span>
                    <span class="time-block" ng-bind="vm.eventTime"></span>
                    <span class="physician-block" ng-bind="vm.event.physician_name"></span>
                    <span class="reason-block" ng-bind="vm.event.reason_value"></span>
                    
                    <span ng-class="vm.hasVisitClass"></span>
                </div>
                
                <div class="layout-column menu-container" style="max-height: 40px;">          
                    ${RDV_MENU_ELEMENT}
                    <md-icon md-font-icon="mdi-video" md-font-set="mdi" ng-if="vm.event['has_video_call']"></md-icon>
                    <md-icon md-font-icon="mdi-clipboard-pulse" md-font-set="mdi" ng-if="vm.event['from_medical_care']"></md-icon>
                </div>
            </div>
            
            <div class="agenda-corner" ng-style="{'background-color': vm.event.agenda_color}"></div>
            <div class="fc-event-resizer fc-event-resizer-end" ng-if="!vm.event.is_entered"></div>
        `;
    }

    module.exports = {
        bindings: {
            event: '<',
            type: '@'
        },
        controllerAs: "vm",
        template: tpl, // or template
        controller: RdvCalendarElementCtrl,
    };

})();
