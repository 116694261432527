/**
 * Created by amine on 22/03/2017. // edited 28/01/2020
 */

(function () {
    'use strict';

    const APPOINTMENT_DIALOG = require('frontdesk/dialogs/appointment-dialog');

    class AppointmentFormButtonCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;

            this.open = this.open ? this.open : () => Promise.resolve();
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.ngModelController = this.ngModelController || null;
            this.value = this.value || null;
            this.disableClick = this.disableClick || false;
            this.iconClasses = "mdi-calendar-question";

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue;
            }

            if (_.get(this.value, 'id')) {
                if (this.value.status === 'missed') this.iconClasses = 'mdi-calendar-remove mn-danger';
                else {
                    if (!this.value.is_waiting_list) this.iconClasses = 'mdi-calendar-check mn-success';
                    else this.iconClasses = 'mdi-calendar-clock md-primary';
                }
            } else {
                this.iconClasses = 'mdi-calendar-question';
            }
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(this.value);
            this.ngModelController.$commitViewValue();
        }

        $onChanges(changes) {
            // if (_.get(changes, "pregnancy.currentValue")) {
            //     if (!_.isNil(this.pregnancy)) {
            //         this.element.show();
            //         if (!this.calculated) this.pregnancyService.calculateFields(this.pregnancy, this.blockConfig);
            //     } else {
            //         this.element.hide();
            //     }
            // }
        }

        editAppointment($event) {
            if (this.disableClick) return false;

            this.open().then(event => {
                let dialog = _.assign({}, APPOINTMENT_DIALOG, {
                    multiple: true,
                    targetEvent: $event,
                    locals: {
                        timeRange: null,
                        patient: null,
                        event
                    }
                });

                this.dialog.show(dialog)
                    .then(appointment => {
                        if (appointment.deleted) this.value = null;
                        else this.value = appointment;

                        this.internalValueChanged();
                    }, _.noop);
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        // ng-disabled="vm.readOnly || vm.pregnancy.is_validated"
        return `
            <md-button class="md-icon-button flex-nogrow" aria-label="Edit Appointment"
                            ng-click="vm.editAppointment($event)" ng-disabled="vm.ngModelController.$$attr.disabled">
                    <md-icon ng-class="{
                        'mdi-calendar-question': !vm.value.id,
                        'mdi-calendar-clock md-primary':vm.value.id && vm.value.date == null && vm.value.status!== 'missed',
                        'mdi-calendar-check mn-success':vm.value.id && vm.value.date != null && vm.value.status!== 'missed',
                        'mdi-calendar-remove mn-danger':vm.value.status=== 'missed'
                    }" md-font-set="mdi"></md-icon>
            </md-button>
        `;
    }

    module.exports = {
        bindings: {
            open: '&onOpening',
            disableClick: '<disableClick',
        },
        template: tpl,
        controllerAs: "vm",
        controller: AppointmentFormButtonCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
