(function () {

    'use strict';

    module.exports = TraumatoContainerCtrl;

    const {Subject} = require("rxjs");

    TraumatoContainerCtrl.$inject = ["visitService", "$scope"];

    function TraumatoContainerCtrl(visitService, $scope) {
        let vm = this;

        vm.procedureSubject = new Subject();

        vm.$onInit = init;
        vm.selectProcedure = selectProcedure;

        function init() {
            vm.procedures = require('../json/traumato-example-actes.json');

            let currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);


            $scope.$on('$destroy', onDestroy);

            function onDestroy() {
                currentPatientSubscription.unsubscribe();
            }


            function handlePatient(data) {
                vm.patient = data;
            }
        }

        function selectProcedure(procedure) {
            vm.procedureSubject.next(procedure);
        }

    }

})();
