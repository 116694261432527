(function () {

    'use strict';

    module.exports = {
        controller: AudioRecordDialogCtrl,
        controllerAs: "vm",
        template: require("../views/audio-record-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    AudioRecordDialogCtrl.$inject = ["$mdDialog", "$window", "$element", "$scope", "system", "$translate", "moment"];

    function AudioRecordDialogCtrl($mdDialog, $window, $element, $scope, system, $translate, moment) {
        let vm = this;

        const dateTimeFormat = system['datetime_format'].js;

        let record = null;
        let stream = null;
        let audioFile = null;
        let audioChunks = [];

        vm.isStop = true;
        vm.hasNoFile = true;
        vm.showPreview = false;

        vm.saveFile = saveFile;
        vm.abort = $mdDialog.cancel;

        vm.startRecording = startRecording;
        vm.pauseRecording = pauseRecording;
        vm.stopRecording = stopRecording;


        vm.$onInit = init;

        function init() {
            const getUserMedia = _.get($window, 'navigator.mediaDevices.getUserMedia', null);

            if (!_.isNil(getUserMedia)) {
                $window.navigator['mediaDevices']['getUserMedia']({audio: true})
                    .then(handleStream, error);
            }

            $scope.$on('$destroy', onDestroy);

            function onDestroy() {
                _.isNil(stream) ? _.noop() : stream.getAudioTracks().forEach(track => track.stop());
            }
        }

        function handleStream(stm) {
            stream = stm;
            record = new $window.MediaRecorder(stream);
            record.ondataavailable = onDataAvailable;

            if (!vm.isPlay) startRecording();
        }

        function onDataAvailable(e) {
            audioChunks.push(e.data);

            if (record.state == "inactive") {
                vm.hasNoFile = false;
                $scope.$applyAsync();

                audioFile = new Blob(audioChunks, {type: 'audio/x-mpeg-3'});

                let recordedAudio = $('audio', $element).get(0);
                recordedAudio.src = $window.URL.createObjectURL(audioFile);
            }
        }

        function startRecording() {
            if (record.state == "inactive") record.start();

            vm.isPlay = true;
            vm.isStop = false;
            vm.showPreview = false;
        }

        function pauseRecording() {
            if (record.state != "inactive") record.stop();
            vm.isPlay = vm.isStop = false;
        }

        function stopRecording() {
            if (record.state != "inactive") record.stop();

            vm.isPlay = false;
            vm.isStop = true;
            vm.showPreview = true;

            audioChunks = [];
        }

        function error() {
            vm.error = true;
        }

        function saveFile() {
            const title = $translate.instant('file_manager_record_audio_title', {date: moment().format(dateTimeFormat)})
                .replace(new RegExp('/', 'g'), '-');

            const file = new File([audioFile], title, {type: 'audio/x-mpeg-3'});
            $mdDialog.hide(file);
        }

    }

})();