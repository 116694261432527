/**
 * Created by amine on 27/06/2018.
 */
(function () {
    'use strict';

    module.exports = {
        controller: VaccinationLineDialogController,
        controllerAs: "vm",
        template: require("../views/vaccination-line-form.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true
    };

    VaccinationLineDialogController.$inject = ["$mdDialog", "vaccinationService"];

    function VaccinationLineDialogController($mdDialog, vaccinationService) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.removeVaccine = removeVaccine;
        vm.addVaccine = addVaccine;
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.promise = vaccinationService.getVaccines()
                .then((data) => vm.vaccines = data);
        }

        function addVaccine() {
            vm.line.vaccines = _.chain(vm.line.vaccines)
                .concat(vm.selectedVaccines)
                .uniqBy('id')
                .filter(function (item) {
                    return !_.isNil(item)
                })
                .value();

            vm.selectedVaccines = null;
        }

        function removeVaccine(item) {
            _.remove(vm.line.vaccines, _.pick(item, "id"));
        }

        function submit() {
            addVaccine();
            vaccinationService.editCalendarLine({line: vm.line, vaccination: vm.vaccination})
                .then(success, _.noop);

            function success(data) {
                $mdDialog.hide(data);
            }

        }
    }
})();
