/**
 * Created by BETALOS on 27/01/2017.
 */
(function () {

    'use strict';

    module.exports = notificationService;

    notificationService.$inject = ["$q", "$http", "mnWebSocket", "$mdDialog", "$translate"];

    const {BehaviorSubject} = require("rxjs");

    const ALERT_FORM_DIALOG = require('frontdesk/dialogs/alert-form-dialog');
    const NOTIFICATION_DIALOG = require('../dialogs/notification-dialog');

    function notificationService($q, $http, mnWebSocket, $mdDialog, $translate) {
        let self = this;

        let currentActiveAlert = null;
        let alertSubject = new BehaviorSubject([]);

        let currentActiveNotification = null;
        let notificationsSubject = new BehaviorSubject([]);

        // initialize notifications
        self.initialize = initialize;

        self.alertDialog = alertDialog;
        self.removeAlert = removeAlert;

        self.getAlerts = getAlerts;
        self.handleAlert = handleAlert;
        self.getPatientAlerts = getPatientAlerts;

        self.getActiveAlerts = getActiveAlerts;
        self.markAlertAsSeen = markAlertAsSeen;

        // notification methods
        self.getActiveNotifications = getActiveNotifications;
        self.getNotifications = getNotifications;
        self.notificationDialog = notificationDialog;
        self.handleNotification = handleNotification;
        self.removeNotification = removeNotification;
        self.markNotificationsAsSeen = markNotificationsAsSeen;

        function initialize() {
            return $q.all([
                mnWebSocket.call("shared.notification.Alerts.alerts").then(handleInitialAlertCall),
                mnWebSocket.sub("shared.notification.Alerts.alerts_updates", handleAlertsChanges),

                mnWebSocket.call("shared.notification.Notifications.notifications").then(handleInitialNotificationsCall),
                mnWebSocket.sub("shared.notification.Notifications.notifications_updates", handleNotificationsChanges),
            ]);
        }

        function alertDialog(patient, trigger, alert, ev) {
            return $mdDialog.show(_.assign({}, ALERT_FORM_DIALOG, {
                targetEvent: ev,
                locals: {alert, patient, trigger}
            }));
        }

        function removeAlert(alert, ev) {
            let deferred = $q.defer();

            let confirm = $mdDialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('alert_remove_confirm')
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'))
                .title($translate['instant']('alert_remove_confirm', alert))

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                const url = `/api/alert-form/${alert.id}/`
                $http.delete(url).then(deferred.resolve);
            }

            return deferred.promise;
        }

        function handleAlert(alert, trigger) {
            const deferred = $q.defer();
            const url = `/api/alert-form/${alert.id ? alert.id + '/' : ''}?trigger=${trigger}`;

            $http.post(url, alert)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getPatientAlerts(patient) {
            const deferred = $q.defer();
            const url = `/api/alert-form/?patient=${patient.id}`;

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getAlerts() {
            return alertSubject;
        }

        function handleInitialAlertCall(data) {
            currentActiveAlert = data.id;
            alertSubject.next(data.alerts);
        }

        function handleAlertsChanges(data) {
            if (!currentActiveAlert) return;

            if ((_.isBoolean(data['updated_items']) && data['updated_items']) || _.includes(data['updated_items'], currentActiveAlert)) {
                mnWebSocket.call("shared.notification.Alerts.alerts").then(handleInitialAlertCall);
            }
        }

        function getActiveAlerts() {
            return alertSubject.value;
        }

        function markAlertAsSeen(alert) {
            return mnWebSocket.call("shared.notification.Alerts.mark_alert_as_seen", {
                alertItem: alert, currentActiveAlerts: currentActiveAlert
            });
        }

        // notification methods
        function handleInitialNotificationsCall(data) {
            currentActiveNotification = data.id;
            notificationsSubject.next(data.notifications);
        }

        function handleNotificationsChanges(data) {
            if (!currentActiveNotification) return;

            if (_.includes(data['updated_items'], currentActiveNotification)) {
                mnWebSocket.call("shared.notification.Notifications.notifications").then(handleInitialNotificationsCall)
            }
        }

        function getActiveNotifications() {
            return notificationsSubject;
        }

        function getNotifications() {
            const deferred = $q.defer();
            const url = `/api/notification/`;

            $http.get(url)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function notificationDialog(notification, patient, ev) {
            $mdDialog.show(_.assign({}, NOTIFICATION_DIALOG, {
                targetEvent: ev,
                locals: _.assign({}, notification ? {notification} : {}, patient ? {patient} : {})
            }));
        }

        function handleNotification(notification) {
            const deferred = $q.defer();
            const url = `/api/notification/${notification.id ? notification.id + '/' : ''}`;

            $http.post(url, notification)
                .then(success, deferred.reject)

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeNotification(item, ev) {
            let deferred = $q.defer();

            let confirm = $mdDialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('notification_remove_confirm')
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'))
                .title($translate['instant']('notification_remove_confirm', item))

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                const url = `/api/notification/${item.id}/`
                $http.delete(url).then(deferred.resolve);
            }

            return deferred.promise;
        }

        function markNotificationsAsSeen(items) {
            return mnWebSocket.call("shared.notification.Notifications.mark_as_seen", {
                items: _.map(items, 'id'), currentActiveItem: currentActiveNotification
            });
        }

    }

})();
