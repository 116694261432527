/**
 * Created by amine on 03/09/2018.
 */

(function () {
    'use strict';

    const EMPTY_ENTRY = {
        entry_date: null,
        current_dose: null,
        tp_value: 0,
        inr_value: 0,
        prescribed_dose: 0,
        next_appointment: null
    }

    class ProthrombinTimeCtrl {
        constructor($translate, moment, $element, $q, ptService, configService, system, $stateParams, $mdDialog) {
            this.$translate = $translate;
            this.$q = $q;
            this.moment = moment;
            this.$element = $element;
            this.ptService = ptService;
            this.configService = configService;
            this.dateFormat = system['date_format'].js;
            this.$stateParams = $stateParams;
            this.$mdDialog = $mdDialog;

            this.promise = null;
            this.postSave = _.noop;
            this.postSaveSubject = null;
            this.patient = null;

            this.patient = null;
            this.record_sheet = null;
        }

        static get $inject() {
            return ['$translate', 'moment', "$element", "$q", "ptService", "configService", "system", "$stateParams", "$mdDialog"];
        }

        $onInit() {
            this.consultationId = this.$stateParams['consultation'];

            this.promise = this.$q.all([
                this.configService.get("pt_config"),
                this.ptService.getRecordsSheet(this.patient)
            ]).then(data => {
                this.config = data[0];
                this.record_sheet = data[1];

                if (_.isEmpty(this.record_sheet.entries)) this.newLine()
            });
        }

        $onDestroy() {

        }

        submit() { // line = null
            return this.promise = this.ptService.saveRecordsSheet(this.record_sheet)
                .then(data => {
                    this.record_sheet = data;
                }, _.noop)
        }

        newLine() {
            const lastLine = _.last(this.record_sheet.entries) || {};
            let newLine = {
                entry_date: this.moment(),
                current_dose: _.get(lastLine, "prescribed_dose")
            }

            if (_.get(this.config, "copy_full_line", false)) {
                newLine.tp_value = _.get(lastLine, "tp_value", EMPTY_ENTRY.tp_value)
                newLine.inr_value = _.get(lastLine, "inr_value", EMPTY_ENTRY.inr_value)
            }

            this.record_sheet.entries.push(_.assign({}, EMPTY_ENTRY, newLine))
        }

        changeStyle(type, value) {
            if (type == "tp") {
                const level = _.findKey(this.config['pt_intervals'], seg => {
                    return seg[0] < value && value <= seg[1]
                })
                const colors = {
                    "danger_1": "#FFCDD2",
                    "danger_2": "#FFE0B2",
                    "warn": "#FFECB3",
                    "normal": "#C8E6C9"
                }

                return {'background-color': colors[level]};
            } else if (type == "inr") {
                const level = _.findKey(this.config['inr_intervals'], seg => {
                    return seg[0] < value && value <= seg[1]
                })
                const colors = {
                    "danger": "#FFCDD2",
                    "no_meds": "#FFE0B2",
                    "warn": "#FFECB3",
                    "normal": "#C8E6C9"
                }

                return {'background-color': colors[level]};
            }
        }

        deleteLine($index, $event) {
            const remove = () => {
                this.record_sheet.entries.splice($index, 1);
            };

            const confirm = this.$mdDialog.confirm()
                .title(this.$translate['instant']("pt_delete_record_entry_title"))
                .textContent(this.$translate['instant']("pt_delete_record_entry_text"))
                .ariaLabel('confirm')
                .targetEvent($event)
                .ok(this.$translate['instant']('confirm_ok'))
                .cancel(this.$translate['instant']('confirm_cancel'));

            this.$mdDialog.show(confirm).then(() => remove());
        }
    }

    module.exports = {
        bindings: {
            patient: '<?mnPatient',
            postSave: '&?mnPostSave',
            postSaveSubject: '<?mnPostSaveSubject',
            readOnly: '<?mnReadOnly',
            currentDate: '<?',
        },
        controllerAs: "vm",
        controller: ProthrombinTimeCtrl,
        template: require('specifics/views/pt-block.tpl.html'),
    };

})();