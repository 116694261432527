/**
 * Created by BETALOS on 22/08/2016.
 */
(function () {

    "use strict";

    const fabric = require("fabric-browseronly").fabric;

    module.exports = zoom;

    let Direction = {
        LEFT: 0,
        UP: 1,
        RIGHT: 2,
        DOWN: 3
    };


    function keepPosFixed(obj,vm) {

        obj.setCoords();

        // top-left  corner
        if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
            obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
            obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
        }
        // bot-right corner
        if (obj.getBoundingRect().top + obj.getBoundingRect().height > vm.currentCanvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > vm.currentCanvas.width) {
            obj.top = Math.min(obj.top, vm.currentCanvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
            obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
        }
    }

    function zoom(){
        let vm = this;
        let canvas = vm.currentCanvas;

        let shiftKeyDown = false;
        vm.resetCanvas();

        vm.currentCanvas.on("mouse:wheel", function(event) {

            let delta = event.e.wheelDelta;


            if (delta != 0) {
                let pointer = vm.currentCanvas.getPointer(event.e, true);
                let point = new fabric.Point(pointer.x, pointer.y);

                (delta > 0) ? zoomIn(point,vm) : zoomOut(point,vm);

            }
        });


        vm.currentCanvas.renderAll();


        fabric.util.addListener(document.body, 'keydown', function(options) {
            if (options.repeat) {
                return;
            }
            let key = options.which || options.keyCode; // key detection
            if (key == 16) { // handle Shift key
                vm.currentCanvas.defaultCursor = 'move';
                vm.currentCanvas.selection = false;
                shiftKeyDown = true;
            } else if (key === 37) { // handle Left key
                move(Direction.LEFT, vm.currentCanvas);
            } else if (key === 38) { // handle Up key
                move(Direction.UP, vm.currentCanvas);
            } else if (key === 39) { // handle Right key
                move(Direction.RIGHT, vm.currentCanvas);
            } else if (key === 40) { // handle Down key
                move(Direction.DOWN, vm.currentCanvas);
            }
        });

        fabric.util.addListener(document.body, 'keyup', function(options) {
            let key = options.which || options.keyCode; // key detection
            if (key == 16) { // handle Shift key
                vm.currentCanvas.defaultCursor = 'default';
                vm.currentCanvas.selection = true;
                shiftKeyDown = false;
            }
        });
        /*
             let panning = false;
                vm.currentCanvas.on('mouse:up', function (params) {
                    panning = false;
                   /* console.log(params.e.target);console.log(params.target);
                    console.log(vm.currentCanvas.item(0).getBoundingRect());

        });
        vm.currentCanvas.on('mouse:out', function (e) {
            panning = false;
        });
        vm.currentCanvas.on('mouse:down', function (e) {
            panning = true;
            canvas.on('object:moving', function (e) {
                let obj = e.target;
                // if object is too big ignore
                vm.currentCanvas.on('mouse:move', function(ev) {

                    if (panning && ev && ev.e) {

                        let point = new fabric.Point(ev.e.movementX, ev.e.movementY);
                        vm.currentCanvas.relativePan(point);

                        vm.currentCanvas.renderAll();
                    }
                });
                keepPosFixed(obj,vm);
            });
        });




        canvas.on('object:moving', function (e) {
            let obj = e.target;
            // if object is too big ignore

            keepPosFixed(obj,vm);
        });*/





    }

    function zoomIn(point, vm) {
        let canvas = vm.currentCanvas ;
        let SCALE_FACTOR = 0.5;
        let obj = vm.currentCanvas.item(0);
        if (vm.zoomLevel < vm.zoomLevelMax) {
            vm.zoomLevel += 0.1 ;
            vm.currentCanvas.zoomToPoint(point, Math.pow(2, vm.zoomLevel));

            keepPositionInBounds(vm.currentCanvas);
        }


    }

    function zoomOut(point, vm) {
        if (vm.zoomLevel > 0) {
            vm.zoomLevel -= 0.15;
            vm.currentCanvas.zoomToPoint(point, Math.pow(2, vm.zoomLevel));
            keepPositionInBounds(vm.currentCanvas);
        }
    }

    function keepPositionInBounds(canvas) {
        let zoom = canvas.getZoom();
        console.log("zoom level"+zoom)

        let xMin = (2 - zoom) * canvas.getWidth() / 2;
        let xMax = zoom * canvas.getWidth() / 2;
        let yMin = (2 - zoom) * canvas.getHeight() / 2;
        let yMax = zoom * canvas.getHeight() / 2;

        let point = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
        let center = fabric.util.transformPoint(point, canvas.viewportTransform);

        let clampedCenterX = clamp(center.x, xMin, xMax);
        let clampedCenterY = clamp(center.y, yMin, yMax);

        let diffX = clampedCenterX - center.x;
        let diffY = clampedCenterY - center.y;

        if (diffX != 0 || diffY != 0) {
            canvas.relativePan(new fabric.Point(diffX, diffY));
        }
    }

    function clamp(value, min, max) {
        return Math.max(min, Math.min(value, max));
    }


    // clavier
  function move(direction, canvas) {
        let image = canvas.item(0);

        switch (direction) {
            case Direction.LEFT:{
                console.log(canvas.item(0).top +"  "+canvas.item(0).left);
                canvas.relativePan(new fabric.Point(-10 * canvas.getZoom(), 0));
                break;}
            case Direction.UP:{
                console.log(canvas.item(0).top +"  "+canvas.item(0).left);
                canvas.relativePan(new fabric.Point(0, -10 * canvas.getZoom()));
                break;}
            case Direction.RIGHT:{
                console.log(canvas.item(0).top +"  "+canvas.item(0).left);
                canvas.relativePan(new fabric.Point(10 * canvas.getZoom(), 0));
                break;}
            case Direction.DOWN: {
                console.log(canvas.item(0).top +"  "+canvas.item(0).left);
                canvas.relativePan(new fabric.Point(0, 10 * canvas.getZoom()));
                break;
            }
        }

    }








})();