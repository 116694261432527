(function () {
    'use strict'

    const fabric = require("fabric-browseronly").fabric;

    module.exports = drawAngle;

    function drawAngle(drawColor){
        let vm = this;


        vm.resetCanvas();

        vm.currentCanvas.defaultCursor = 'crosshair';
        vm.currentCanvas.hoverCursor = 'crosshair';

        vm.currentCanvas.off('mouse:down');
        vm.currentCanvas.on('mouse:down', function (options) {

            let pointer = vm.currentCanvas.getPointer(options.e);

            let rect = new fabric.Rect({
                width: 3,
                height: 3,
                left: pointer.x,
                top: pointer.y,
                hasControls: false,
                hasBorders : false,
                stroke: drawColor.squareColor,
                strokeWidth: 2,
                fill: undefined,
                selectable: true,
                originX: 'center',
                originY: 'center',
            });

            let line = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                fill: drawColor.lineColor,
                stroke: drawColor.lineColor,
                strokeWidth: 2,
                selectable: false,
                originX: 'center',
                originY: 'center',
            });

            rect.line1 = line;
            vm.currentCanvas.add(line);
            vm.currentCanvas.add(rect);

            vm.currentCanvas.off('mouse:down');
            vm.currentCanvas.on('mouse:down', function (options) {

                let pointer = vm.currentCanvas.getPointer(options.e);

                let rect2 = new fabric.Rect({
                    width: 3,
                    height: 3,
                    left: pointer.x,
                    top: pointer.y,
                    hasControls: false,
                    hasBorders : false,
                    stroke: drawColor.squareColor,
                    strokeWidth: 2,
                    fill: undefined,
                    selectable: true,
                    originX: 'center',
                    originY: 'center',
                });

                let line2 = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                    fill: drawColor.lineColor,
                    stroke: drawColor.lineColor,
                    strokeWidth: 2,
                    selectable: false,
                    originX: 'center',
                    originY: 'center',
                });

                rect2.line2 = line;
                rect2.line3 = line2;
                rect2.line2.set({ 'x2': pointer.x, 'y2': pointer.y });
                vm.currentCanvas.add(rect2);
                vm.currentCanvas.add(line2);
                vm.currentCanvas.renderAll();

                vm.currentCanvas.off('mouse:down');
                vm.currentCanvas.on('mouse:down', function (options) {

                    let pointer = vm.currentCanvas.getPointer(options.e);

                    let rect3 = new fabric.Rect({
                        width: 3,
                        height: 3,
                        left: pointer.x,
                        top: pointer.y,
                        hasControls: false,
                        hasBorders : false,
                        stroke: drawColor.squareColor,
                        strokeWidth: 2,
                        fill: undefined,
                        selectable: true,
                        originX: 'center',
                        originY: 'center',
                    });

                    rect3.line4 = line2;
                    rect3.line4.set({ 'x2': pointer.x, 'y2': pointer.y });
                    vm.currentCanvas.add(rect3);
                    vm.currentCanvas.renderAll();
                    vm.active = 'none';
                    vm.resetCanvas();
                });
            });
        });

        vm.currentCanvas.on('object:moving', function(e) {
            let p = e.target;
            if(p.type === "rect"){
                p.line1 && p.line1.set({ 'x1': p.left, 'y1': p.top });
                p.line2 && p.line2.set({ 'x2': p.left, 'y2': p.top });
                p.line3 && p.line3.set({ 'x1': p.left, 'y1': p.top });
                p.line4 && p.line4.set({ 'x2': p.left, 'y2': p.top });

                vm.currentCanvas.renderAll();
            }
        });
    }

})();