(function () {

    'use strict';

    class RequestedProcedureDialogCtrl {
        constructor($mdDialog, dcmService) {
            this.dialog = $mdDialog;
            this.service = dcmService;
        }

        static get $inject() {
            return ["$mdDialog", "dcmService"];
        }

        $onInit() {
            this.procedure = this.procedure ? this.procedure : {procedure_id: '*'};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.service.handleRequestedProcedures(this.procedure)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: RequestedProcedureDialogCtrl,
        template: require("./requested-procedure-dialog.tpl.html"),
    };

})();
