/**
 * Created by BETALOS on 28/06/2016.
 */
(function () {

    'use strict';

    module.exports = autoCompleteService;

    autoCompleteService.$inject = ["mnWebSocket"];

    function autoCompleteService(mnWebSocket) {
        let self = this;

        self.search = search;

        function search(event, query, field) {
            return mnWebSocket.call(event, {query, field});
        }
    }

})();