/**
 * Created by BETALOS on 18/07/2017.
 */
(function () {

    "use strict";

    class FileFilter {
        constructor() {
            this.units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
            this.filterCall = (bytes, precision) => this.filter(bytes, precision);
        }

        static create() {
            return new FileFilter(...arguments).filterCall;
        }

        filter(bytes, precision) {
            if (_.isNaN(parseFloat(bytes)) || !_.isFinite(bytes)) return '-';
            if (_.isUndefined(precision)) precision = 1;

            let number = Math.floor(Math.log(bytes) / Math.log(1024));

            return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + this.units[number];
        }
    }

    FileFilter.create.$inject = [];

    module.exports = FileFilter.create;

})()