/**
 * Created by BETALOS on 05/07/2017.
 */
(function () {
    'use strict';

    // TODO need more fixes to be done
    // this is just an early release

    module.exports = {
        controller: ImageEdition,
        controllerAs: "vm",
        bindings: {
            url: '<',
            title: '@imageTitle'
        },
        template: require('../views/image-edition.tpl.html')
    };

    ImageEdition.$inject = ['$translate', '$element', 'Upload', '$mdDialog'];

    function ImageEdition($translate, $element, Upload, $mdDialog) {
        let vm = this;

        let colorPickerParams = require('../json/color-picker-config.json');

        vm.$onInit = init;

        // globals
        vm.currentCanvas = null;
        // end globals

        // letiable
        vm.active = "none";
        vm.saving = vm.police = vm.draw = vm.light = vm.forms = false;
        vm.help = true; // used in rotate.js


        vm.drawColor = {
            squareColor: '#fbfd08',
            lineColor: '#ff0000'
        };
        vm.circleColor = {
            circleColor: '#fbfd08'
        };
        // end letiable

        // directive initialisation
        vm.init = true;
        // end directive initialisation

        // reset
        // end reset


        // save image
        vm.save = save;
        // end save

        // functions
        function init() {
            vm.police = require('../json/police.json');

            vm.policeOptions = _.assign(_.clone(colorPickerParams), {label: $translate['instant']('image_editor_font_color')});
            vm.drawingOptions = _.assign(_.clone(colorPickerParams), {label: $translate['instant']('image_editor_drawing_color')});

            vm.lineOptions = _.assign(_.clone(colorPickerParams), {label: $translate['instant']('image_editor_line_color')});
            vm.squareOptions = _.assign(_.clone(colorPickerParams), {label: $translate['instant']('image_editor_square_color')});
            vm.circleOptions = _.assign(_.clone(colorPickerParams), {label: $translate['instant']('image_editor_circle_color')});

            vm.currentCanvas = vm.loadDirective($element);

            // init zoom
            vm.zoom();
            // finish init zoom


            let state = new (require('../utils/state'))(
                function () {
                    // get state
                    return JSON.stringify(vm.currentCanvas.toObject(['selectable', 'hasControls']));
                },
                function (newState) {
                    // set state
                    vm.currentCanvas.clear();
                    vm.currentCanvas.loadFromJSON(newState);
                    vm.currentCanvas.renderAll();
                }
            );

            vm.currentCanvas.on("object:" +
                "", function () {
                state.save();
            });


            vm.currentCanvas.on("object:modified", function (object) {
                if (object.target.type !== "image") {
                    console.log("object:modified");
                    state.save();
                }

            });

            vm.currentCanvas.on("object:removed", function (object) {
                if (object.target.type !== "image") {
                    console.log("object:removed");
                    state.save();
                }
            });


            vm.currentCanvas.on("object:added", function (object) {
                if (object.target.type !== "image" && !vm.redoing) {
                    console.log(vm.redoing);
                    console.log("object:added");
                    state.save();
                }

            });

            window.addEventListener('resize', resizeCanvas, false);
        }


        function save() {
            const title = `${vm.title} - ${$translate['instant']('image_editor_image_edited')}`;
            const blob = Upload.dataUrltoBlob(vm.currentCanvas.toDataURL(), title);
            const file = new File([blob], title, {type: 'image/png'});

            $mdDialog.hide(_.castArray(file));
        }


        // resize canvas
        function resizeCanvas() {
            let results = null;

            if (vm.help) {
                results = vm.dimCalculate(vm.currentCanvas.getObjects()[0]);
                vm.currentCanvas.setDimensions(results);
                vm.currentCanvas.getObjects()[0].set(results);
            }
            else {
                results = vm.dimCalculate({
                    width: vm.currentCanvas.getObjects()[0].getHeight(),
                    height: vm.currentCanvas.getObjects()[0].getWidth()
                });
                vm.currentCanvas.setDimensions(results);
                vm.currentCanvas.getObjects()[0].set({width: results.height, height: results.width});
            }

            vm.currentCanvas.centerObject(vm.currentCanvas.getObjects()[0]);
            vm.currentCanvas.renderAll();
        }

        // end functions
    }


    ImageEdition.prototype.loadDirective = require('../utils/load-directive');
    ImageEdition.prototype.dimCalculate = require('../utils/dim-calculate');

    ImageEdition.prototype.reset = require('../utils/reset');
    ImageEdition.prototype.resetCanvas = require('../utils/reset-canvas');

    // brightness
    ImageEdition.prototype.luminosite = 5;
    ImageEdition.prototype.brightness = require('../utils/brightness');
    // end brightness

    // Contrast
    ImageEdition.prototype.contraste = 50;
    ImageEdition.prototype.contrast = require('../utils/contrast');
    //end contrast

    // invert
    ImageEdition.prototype.invert = require('../utils/invert');
    //end invert

    // zoom
    ImageEdition.prototype.zoom = require('../utils/zoom');

    ImageEdition.prototype.zoomLevel = 1;
    ImageEdition.prototype.zoomLevelMin = 1;
    ImageEdition.prototype.zoomLevelMax = 20;
    //end zoom

    // rotate.js
    ImageEdition.prototype.rotate = require('../utils/rotate');
    ImageEdition.prototype.oldImgSize = {};
    // end rotate.js

    // flip
    ImageEdition.prototype.flip = require('../utils/flip');
    // end filp

    // text
    ImageEdition.prototype.textParams = {
        font: 'arial',
        textAlign: 'left',
        textColor: "#fbfd08",
        fontSize: 40
    };

    ImageEdition.prototype.text = require('../utils/text');
    // end texts

    //free drawing
    ImageEdition.prototype.drawingParams = {
        col: "#fbfd08",
        size: 5
    };

    ImageEdition.prototype.changeColor = require('../utils/change-color');
    ImageEdition.prototype.changeWidth = require('../utils/change-width');
    ImageEdition.prototype.drawing = require('../utils/drawing');
    //free drawing

    //  draw measurement

    // line
    ImageEdition.prototype.drawLine = require('../utils/draw-line');
    // end line

    // angle
    ImageEdition.prototype.drawAngle = require('../utils/draw-angle');
    // end angle

    // circle
    ImageEdition.prototype.drawCircle = require('../utils/draw-circle');
    // end circle

    // end draw measurement

    // remove Label
    ImageEdition.prototype.removeLabel = require('../utils/remove-label');
    // remove Label

    // Crop image
    ImageEdition.prototype.crop = require('../utils/crop');

    ImageEdition.prototype.cropImage = require('../utils/cropImage');
    ImageEdition.prototype.cropDone = require('../utils/cropDone');


    ImageEdition.prototype.reset = require('../utils/reset');

    ImageEdition.prototype.undo = require('../utils/undo');

    //redo
    ImageEdition.prototype.redo = require('../utils/redo');


})();