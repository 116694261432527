/**
 * Created by amine on 17/08/2021.
 */

(function () {
    'use strict';

    const CLASSIFICATION_DIALOG = require('../../dialogs/osiics-classification-dialog/osiics-classification-dialog');

    class OSIICSFieldCtrl {
        constructor(classificationsService, $translate, $element, $q, $mdDialog) {
            this.classificationsService = classificationsService;
            this.$translate = $translate;
            this.$q = $q;
            this.$element = $element;
            this.dialog = $mdDialog;

            this.requireMatch = true;
        }

        static get $inject() {
            return ['classificationsService', '$translate', "$element", "$q", "$mdDialog"];
        }

        $onInit() {
            this.search = "";

            this.ngModelController = this.ngModelController ? this.ngModelController : null;
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.internalValue) {
                this.internalValue = this.ngModelController.$viewValue;
            }

            this.internalValue = this.internalValue || [];
        }

        emptyField() {
            this.internalValue = [];
            this.change();
        }

        change() {
            let viewValue = _.cloneDeep(this.internalValue);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        getData(value) {
            return this.classificationsService.osiicsSearch(value);
        }

        transformChip(chip) {
            if (_.isObject(chip)) {
                return _.pick(chip, ['id', 'code', 'value', 'usage']);
            } else {
                return false
            }
        }

        classificationDialog(ev) {
            this.dialog.show(_.assign({}, CLASSIFICATION_DIALOG, {
                targetEvent: ev,
                locals: {},
            })).then(data => {
                data.forEach(item => {
                    _.pushOrUpdate(this.internalValue, this.transformChip(item));
                });
                this.change();
            }, _.noop);
        }

    }

    module.exports = {
        bindings: {
            readonly: '<?readonly'
        },
        controllerAs: "vm",
        controller: OSIICSFieldCtrl,
        require: {ngModelController: "ngModel"},
        template: require('./osiics-field.tpl.html'),
    };

})();