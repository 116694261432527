/**
 * Created by Amine on 12/11/2021.
 */
(function () {

    'use strict';

    class CustomFieldsBlockCtrl {
        constructor(genericStatesService) {
            this.genericStatesService = genericStatesService;

            this.model = {};
            this.ngModelController = this.ngModelController || null;

            this.dateFormat = {
                'month': genericStatesService.buildLocaleProvider("MM/YYYY"),
                'year': genericStatesService.buildLocaleProvider("YYYY")
            }
        }

        static get $inject() {
            return ["genericStatesService"];
        }

        $onInit() {
            this.ngModelController.$render = () => this.renderCurrentValue();
            this.fields = this.fields || [];
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(_.cloneDeep(this.model));
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model) {
                this.model = this.ngModelController.$viewValue;
            }
        }
    }

    module.exports = {
        bindings: {
            fields: "<"
        },
        require: {ngModelController: "ngModel"},
        template: require("./custom-fields-block.tpl.html"),
        controllerAs: "vm",
        controller: CustomFieldsBlockCtrl,
    };

})();
